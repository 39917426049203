import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { CSVLink } from "react-csv";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Grid, Snackbar } from "@mui/material";
import { BiEditAlt, BiPhoneCall, BiTransfer, BiUser } from "react-icons/bi";
import DeleteIcon from "@mui/icons-material/Delete";
import theme from "../theme/theme";
import { visuallyHidden } from "@mui/utils";
import useGlass from "../theme/glassEffect";
import { useDispatch, useSelector } from "react-redux";
import { deleteLeadAction } from "../redux/action/admin/leads/deleteLead";
import { getLeadAction } from "../redux/action/admin/leads/getLead";
import { Button } from "@mui/base";
import { Link, useNavigate } from "react-router-dom";
import { editSelectData, updateLeadAction } from "../redux/action/admin/leads/editLead";
import LeadsAdd from "../components/navbar/SaveData/Leads";
import { leadSchema } from "../components/navbar/schemaInput/leadSchema";
import { getEmployee } from "../redux/action/admin/employee/get";
import axios from "axios";
import { url } from "../utils/server";
import EmployeeAdd from "../components/navbar/SaveData/Employee";
import { useAuth } from "../redux/authContext";
import { getAuthHeaders } from "../utils/token";
import { MdEdit, MdEditSquare, MdOutlinePayments, MdPaid, MdPayments } from "react-icons/md";
import { BsClipboardCheck, BsPaypal } from "react-icons/bs";
import { GiPayMoney, GiRamProfile } from "react-icons/gi";
import { GrDocumentCloud, GrDocumentOutlook, GrDocumentPpt } from "react-icons/gr";
import { ImProfile } from "react-icons/im";
import SalaryDetails from "./SetSalary.jsx";
import Payslip from "./Payslip.jsx";



export function createData(id, name, email, phone, owner, company, workAddress, leadType) {
  return { id, name, email, phone, owner, company, workAddress, leadType };
}
const ConfirmationDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the selected item(s)?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{  backgroundColor: theme.palette.button.secondary,
                padding: "10px 15px", border:'none',borderRadius:'10px',cursor:'pointer'}}>
          Cancel
        </Button>
        <Button onClick={onConfirm} color="error" style={{  backgroundColor: theme.palette.button.secondary,
                padding: "10px 15px",border:'none',borderRadius:'10px',cursor:'pointer'}}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {

  let { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  headCells: PropTypes.array.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const {employeeData} = useAuth()
  const [openDialog, setOpenDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [dataSelected , setDataSelected] = React.useState()
  let {  refrence, data, selected ,fulldata } = props;
  console.log(fulldata)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formatDataForCSV = (data) => {
    const formattedData = [...data];
    if (formattedData.length > 0) {
      formattedData[0] = formattedData[0].map(cell => ({ ...cell, style: { bold: true } }));
    }
  
    return formattedData;
  };
  const handleDeleteClick = () => {
    setOpenDialog(true);
    console.log(selected)
  };

  const handleConfirmDelete = async () => {
    try {
      const axiosConfig = getAuthHeaders();
      const response = await axios.post(`${url}/admin/employee/delete`, { employee:selected },axiosConfig);
      await dispatch(getEmployee());
      navigate('/');
      setOpenDialog(false);
    } catch (error) {
      console.error('Error deleting departments:', error);
    }
  };
  
  
  const handleCancelDelete = () => {
    setOpenDialog(false);
  };
  const handleEdit = ()=>{
    console.log("dfds", fulldata)
    const selectedData = fulldata.find(item => item._id === selected[0]);
    
    setDataSelected(selected)
    if (selectedData) {
      dispatch(editSelectData(selectedData)); // Assuming editSelectData is defined correctly
    }
    setOpen(!open)
  }
  const handelUpdate = async(shouldClose, data)=>{
    try {
     
      const updateData = await dispatch(updateLeadAction(data,selected[0]));
      await dispatch(getEmployee());
      if(data){
        if (shouldClose) {
          setOpen(false); 
        }
      }
     
    } catch (error) {
      console.error("Error while saving:", error);
      
    }
  }
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const handleCancle = ()=>{
    setOpen(!open)
    setDataSelected(null)
  }

  return (
    <>
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(selected.length > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {selected.length > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {selected.length} selected
        </Typography>
      ) : (
        <Grid container justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
          <Grid item>
            <Typography variant="h6" id="tableTitle" component="div">
              Employee Salary
            </Typography>
          </Grid>
          <Grid item>
            <CSVLink
              filename="Leads"
              
              data={data}
              style={{
                color: "white",
                backgroundColor: theme.palette.button.secondary,
                padding: "10px 15px",
                marginRight: "20px",
                fontWeight: "100",
                borderRadius: 5,
                textDecoration: "none",
              }}
            >
              Download
            </CSVLink>
          </Grid>
        </Grid>
      )}

      {selected.length > 0 && (
        <>
          {employeeData.role==='Admin' && <>
          <Tooltip title="Edit Lead">
          <IconButton disabled={selected.length > 1} onClick={handleEdit}>
        <BiEditAlt />
      </IconButton>
          </Tooltip>
          
          <Tooltip title="Delete Lead" >
            <IconButton onClick={handleDeleteClick} disabled={selected.length > 1}>
              <DeleteIcon  />
            </IconButton>
          </Tooltip>
          </>}
        </>
      )}
       <ConfirmationDialog
        open={openDialog}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </Toolbar>
    <Drawer
                open={open}
                onClose={toggleDrawer(false)}
                anchor="top"
                sx={{
                  "& .css-1160xiw-MuiPaper-root-MuiDrawer-paper": {
                     }, padding:'50px',
                  maxWidth:'80%',  
                }}
              >
                <Grid
                  
                >
                  <EmployeeAdd  cancel={handleCancle}/>
                </Grid>
              </Drawer></>
  );
}


export default function EmployeeDataLists({ rows, headCells, refrence, data,saved , selectedDate}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [openPaySlip, setOpenPaySlip]  = React.useState(false)
  const [employee,setEmployee] = React.useState({})
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
 
  const { totalLeads } = useSelector((state) => state.getLeadData);
  const [loading, setLoading] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [editOpen, setEditOpen] = React.useState({
    open:false,
    id:null
   })

  React.useEffect(() => {
    if (!loading) {
      setSelected([]);
    }

  }, [loading]);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
 
  const handlePaymentStatus = async (id) => {
    const axiosConfig = getAuthHeaders();
    const data = {
      selectedDate
    }
    setLoading(true);
    try {
        const response = await axios.post(`${url}/employee/update-salary/${id}`,data, axiosConfig);
        setSnackbarMessage('Salary status updated successfully!');
        saved(true)
        setSnackbarOpen(true);
    } catch (err) {
        console.log(err);
        setSnackbarMessage('Error updating salary status.');
        setSnackbarOpen(true);
    } finally {
        setLoading(false);
    }
};
const handleCloseSnackbar = () => {
  setSnackbarOpen(false);
};

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Slice the visible rows based on pagination
  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [rows, order, orderBy, page, rowsPerPage]
  );

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleedit = (id)=>{
        setEditOpen({
           open:true,
           id : id
        })
    }
    const handleClose = () => {
      setEditOpen({
        open:false,
        id : null
     })
  };
  const handleUpdate = ()=>{
       saved(true)
       setEditOpen({
        open:false,
        id:null
       })
  }
  const handleClosePay = ()=>{
    setOpenPaySlip(false)
    setEmployee({})
  }
  const handlePaySlip = (id)=>{
    
    const emp = data.filter(emp=>emp._id ===id)
    setEmployee(emp[0])
    setOpenPaySlip(true)

  }
  
  return (
    <>    {loading ? <CircularProgress />  : (
    <Box sx={{ maxWidth: "100%", minWidth: "100%", overflow: "scroll",marginTop:'100px'  }}>
      
           
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
      <SalaryDetails open={editOpen} onClose={handleClose} data={data} updated={handleUpdate}/>
      {<Payslip employee={employee} open={openPaySlip} closePay={handleClosePay}/>}
      
      <Paper
        sx={{
          maxWidth: "100%",
          mb: 2,
          
          backgroundColor: theme.palette.background.primary,
        }}
      >
        <EnhancedTableToolbar
          fulldata={data}
          refrence={refrence}
          data={rows}
          selected={selected}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
             
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                   
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer", color: theme.palette.text.primary }}
                  >
                    <TableCell padding="checkbox">
                    
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      color="blue"
                      padding="none"

                      align="left"
                    >
                      <Link to={`/employee/${row.id}`} style={{  textDecoration: 'none' }}><BiUser/>{row.name}</Link>
                    </TableCell>
                    <TableCell align="right"><BiPhoneCall/>{row.phone}</TableCell>
                    <TableCell align="right">{row.baseSalary}</TableCell>
                    <TableCell align="right">{row.netSalary}</TableCell>
                    <TableCell align="right" ><Typography sx={{
                      background : row.status ==='paid' ? 'green' : 'red',
                      fontSize: 10,
                    borderRadius: 2,
                    color: 'white',
                    padding: '2px 5px',
                    textAlign: 'center'
                    }}>{row.status}</Typography></TableCell>
                    <TableCell align="right">
                        <Tooltip title='set or edit salary'>
                           <IconButton onClick={()=>handleedit(row.id)}>
                              <MdEditSquare color="orange"/>
                           </IconButton>
                        </Tooltip>
                        <Tooltip title='Click to pay'>
                           <IconButton onClick={()=>handlePaymentStatus(row.id)}>
                          <MdPaid color="green"/>
                           </IconButton>
                        </Tooltip>
                        <Tooltip title='Payslip'>
                           <IconButton onClick={()=>handlePaySlip(row.id)}>
                              <MdOutlinePayments color="blue"/>
                           </IconButton>
                        </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          
        />
      </Paper>

     
    </Box>)}
    </>

  );
}
