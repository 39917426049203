import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "../../constents/notification/notification";

const initialState = {
  Countdata: [],
};

export const notificationState = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      const notificationData = {
        data: action.payload.message,
        status: false
      };

      // Retrieve existing notifications from localStorage
      let notifications = JSON.parse(localStorage.getItem('notifications')) || [];

      // Add new notification data
      notifications.push(notificationData);

      // Filter notifications to get only those with status === false
      const filterNotification = notifications.filter((item) => {
        console.log(item)
        return item.status === false;
      });

      // Save updated notifications array back to localStorage
      localStorage.setItem('notifications', JSON.stringify(notifications));
      
      return {
        ...state,
        Countdata: filterNotification, // Update state with notifications array
      };

    case REMOVE_NOTIFICATION:
      // Clear notifications from localStorage and state
      localStorage.removeItem('notifications');
      return {
        ...state,
        Countdata: null, // Empty notifications array
      };

    default:
      return state;
  }
};
