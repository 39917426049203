export const ADD_EMPLOYEE_REQUEST = 'ADD_EMPLOYEE_REQUEST'
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS'
export const ADD_EMPLOYEE_FAIL= 'ADD_EMPLOYEE_FAIL'

export const GET_EMPLOYEE_REQUEST = 'GET_EMPLOYEE_REQUEST'
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS'
export const GET_EMPLOYEE_FAIL= 'GET_EMPLOYEE_FAIL'

export const DELETE_EMPLOYEE_REQUEST = 'DELETE_EMPLOYEE_REQUEST'
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS'
export const DELETE_EMPLOYEE_FAIL= 'DELETE_EMPLOYEE_FAIL'

export const CATCH_ERROR = 'CATCH_ERROR';