import { z, ZodError } from 'zod'; 

export const employeeSchema = z.object({
  name: z.string().min(1),
  email: z.string().min(1).email({ message: "Invalid email format" }),
  phoneNumber: z.string().min(10, { message: "Phone number must be at least 10 digits" }),
  password: z.string().min(6), 
  dob:z.string(),
  department: z.string().optional(),
  role: z.enum(["Admin", "Manager", "Employee","HR"]).optional(), // Example: Enum for role
  subRole: z.string().optional(), // Example: Optional string for subRole
  city: z.string().min(1), // Example: City is required and must have at least 1 character
  pinCode: z.string().min(1), // Example: PinCode is required and must have at least 1 character
  address: z.string().min(1), // Example: Address is required and must have at least 1 character
  position: z.string().optional(), // Example: Optional string for position
  senior: z.string().optional(), // Example: Optional string for senior
  junior: z.string().optional(), // Example: Optional string for junior
});
