import {
    ADD_LEAD_REQUEST,
    ADD_LEAD_SUCCESS,
    ADD_LEAD_FAIL,
    CATCH_ERROR
 } from '../../../constents/admin/lead.js'
   
   const initialState = {
       loading : false,
       error:null,
       data:false
       
   };
   
   export const addLeadReducer = (state = initialState, action)=>{
        switch(action.type){
           case ADD_LEAD_REQUEST:
               return{
                   ...state,
                   loding:true
               };
           case ADD_LEAD_SUCCESS:
             console.log(action.payload)
               return{
                   ...state,
                   loding:false,
                   data:action.payload
                   
               };
           case ADD_LEAD_FAIL:
               return{
                   ...state,
                   loding:false,
                   error: action.payload
               }
           case CATCH_ERROR:
               return{
                   ...state,
                   error:null
               }
             default : return state  
        }
   };