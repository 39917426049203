import { Box, Grid, IconButton, TextField, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { BiEdit, BiSave, BiPlus, BiTrash } from 'react-icons/bi';
import React, { useState } from 'react';
import useGlass from '../theme/glassEffect';

function Commission({ id, data,saved }) {
    const filterData = data.find(item => item._id === id);
    const commissions = filterData?.salery?.commission || [];
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState(commissions);

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedCommissions = formData.map((commission, i) => (
            i === index ? { ...commission, [name]: value } : commission
        ));
        setFormData(updatedCommissions);
    };

    const handleSave = () => {
        localStorage.setItem('commission', JSON.stringify(formData));
        saved(true)
        setIsEditing(false);
    };

    const handleAddRow = () => {
        setFormData([...formData, { commissionName: '', commissionType: '', commissionAmount: '' }]);
    };

    const handleDeleteRow = (index) => {
        const updatedCommissions = formData.filter((_, i) => i !== index);
        setFormData(updatedCommissions);
    };

    return (
        <Box>
            <Grid sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                width: '100%',
                gap: '10px',
                backgroundColor: useGlass.glass.background,
                boxShadow: useGlass.glass.boxShadow,
                backdropFilter: useGlass.glass.backdropFilter,
                WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
                borderRadius: useGlass.glass.borderRadius,
                border: useGlass.glass.border,
            }}>
                <h2 style={{ marginLeft: '20px' }}>Commission</h2>
                <Tooltip title={isEditing ? 'Save' : 'Edit'}>
                        <IconButton onClick={isEditing ? handleSave : () => setIsEditing(true)} sx={{
                            position: 'absolute',
                            right: '50px'
                        }}>
                            {isEditing ? <BiSave color='blue' /> : <BiEdit color='green' />}
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Add Row">
                        <IconButton onClick={handleAddRow} sx={{
                            position: 'absolute',
                            right: '0'
                        }}>
                            <BiPlus color='blue' />
                        </IconButton>
                    </Tooltip>
                <Grid padding={'30px'} position={'relative'}>
                   

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Commission Name</TableCell>
                                    <TableCell>Commission Type</TableCell>
                                    <TableCell>Commission Amount</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formData.map((commission, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {isEditing ? (
                                                <TextField
                                                    name="commissionName"
                                                    value={commission.commissionName || ''}
                                                    onChange={(e) => handleChange(e, index)}
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: { padding: '0', fontSize: 'inherit', color: 'inherit' }
                                                    }}
                                                />
                                            ) : (
                                                commission.commissionName
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {isEditing ? (
                                                <TextField
                                                    name="commissionType"
                                                    value={commission.commissionType || ''}
                                                    onChange={(e) => handleChange(e, index)}
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: { padding: '0', fontSize: 'inherit', color: 'inherit' }
                                                    }}
                                                />
                                            ) : (
                                                commission.commissionType
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {isEditing ? (
                                                <TextField
                                                    name="commissionAmount"
                                                    value={commission.commissionAmount || ''}
                                                    onChange={(e) => handleChange(e, index)}
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: { padding: '0', fontSize: 'inherit', color: 'inherit' }
                                                    }}
                                                />
                                            ) : (
                                                commission.commissionAmount
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {isEditing && (
                                                <IconButton onClick={() => handleDeleteRow(index)}>
                                                    <BiTrash color='red' />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Commission;
