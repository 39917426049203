import { z, ZodError } from 'zod'; 

export  const leadSchema = z.object({
    name: z.string().min(1),
    email: z.string().min(1).email({ message: "Invalid email format" }),
    phoneNumber: z.string().min(10, { message: "Phone number must be at least 10 digits" }),
    source: z.string(),
    companyName: z.string(),
    city: z.string(),
    pinCode:z.string(),

    companyAddress: z.string(),
    companyWebLink: z.string(),
    projectName: z.string(),
  
    remark: z.string(),
    
    projectLanguage: z.string(),
    
  });