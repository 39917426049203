import React, { useEffect, useState } from 'react';
import { AppBar, Tabs, Tab, Typography, Box, Dialog, Grid, Snackbar, Alert } from '@mui/material';
import PaymentDetails from './PaymentDetails';
import Allowance from './Allownes';
import Commission from './Commision';
import Deduction from './Deduction';
import { Button } from '@mui/base';
import { getAuthHeaders } from '../utils/token';
import axios from 'axios';
import { url } from '../utils/server';
import { ThreeDots } from 'react-loader-spinner'; // Import the loader

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

// Modal Component with Tabs
const SalaryDetails = ({ open, onClose, data , updated }) => {
    const [value, setValue] = useState(0);
    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar open state
    const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message
    const axiosConfig = getAuthHeaders();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSave = () => {
        setUpdate(true);
    };

    useEffect(() => {
        const paymentDetails = localStorage.getItem('paymentDetails');
        const commission = localStorage.getItem('commission');
        const allowance = localStorage.getItem('allowance');
        const deduction = localStorage.getItem('deduction');
        if (paymentDetails || commission || allowance || deduction) {
            setUpdate(true);
        }
    }, []);

    const handleSubmit = async () => {
        setLoading(true); // Start loading
        const paymentDetails = JSON.parse(localStorage.getItem('paymentDetails'));
        const commission = JSON.parse(localStorage.getItem('commission'));
        const allowance = JSON.parse(localStorage.getItem('allowance'));
        const deduction = JSON.parse(localStorage.getItem('deduction'));
        const data = {
            paymentDetails,
            commission,
            allowance,
            deduction
        };
        const id = open.id;

        try {
           const result =  await axios.post(`${url}/employee/set-salary/${id}`, data, axiosConfig);
            setSnackbarMessage('Salary updated successfully!');
            setSnackbarOpen(true);
            localStorage.removeItem('paymentDetails')
            localStorage.removeItem('commission')
            localStorage.removeItem('allowance')
            localStorage.removeItem('deduction')
            console.log(result)
            updated(true)
        } catch (err) {
            setSnackbarMessage('Error updating salary. Please try again.');
            setSnackbarOpen(true);
            console.log(err)
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <Dialog open={open.open} onClose={onClose} fullWidth sx={{ height: '500px', overflow: 'scroll' }}>
            <AppBar position="static" sx={{ background: 'white' }}>
                <Tabs value={value} onChange={handleChange} textColor="inherit" indicatorColor="transparent">
                    <Tab 
                        label="Details" 
                        sx={{
                            bgcolor: 'transparent', 
                            borderBottom: value === 0 ? '2px solid green' : 'none',
                            color: 'black',
                            '&:hover': { bgcolor: 'transparent' }
                        }} 
                    />
                    <Tab 
                        label="Allowance" 
                        sx={{
                            bgcolor: 'transparent', 
                            borderBottom: value === 1 ? '2px solid green' : 'none',
                            color: 'black',
                            '&:hover': { bgcolor: 'transparent' }
                        }} 
                    />
                    <Tab 
                        label="Commission" 
                        sx={{
                            bgcolor: 'transparent', 
                            borderBottom: value === 2 ? '2px solid green' : 'none',
                            color: 'black',
                            '&:hover': { bgcolor: 'transparent' }
                        }} 
                    />
                    <Tab 
                        label="Deduction" 
                        sx={{
                            bgcolor: 'transparent', 
                            borderBottom: value === 3 ? '2px solid green' : 'none',
                            color: 'black',
                            '&:hover': { bgcolor: 'transparent' }
                        }} 
                    />
                    {update && (
                        <Grid>
                            <Button style={{
                                background: '#00A550',
                                border: 'none',
                                color: 'white',
                                borderRadius: '5px',
                                padding: '7px 25px',
                                marginTop: '10px',
                                marginLeft: '20px',
                                cursor: 'pointer'
                            }} onClick={handleSubmit}>
                                {loading ? <ThreeDots color="#ffffff" height={20} width={20} /> : 'Update'}
                            </Button>
                        </Grid>
                    )}
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <PaymentDetails id={open.id} data={data} saved={handleSave} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Allowance id={open.id} data={data} saved={handleSave} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Commission id={open.id} data={data} saved={handleSave} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Deduction id={open.id} data={data} saved={handleSave} />
            </TabPanel>

            {/* Snackbar for notifications */}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default SalaryDetails;
