import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployee } from '../../redux/action/admin/employee/get';
import {
  Typography,
  Paper,
  Grid,
  Button,
  Box,
  IconButton,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { useAuth } from '../../redux/authContext';
import UserProfileNav from '../leads/UserProfileNav';
import {
  BsEmojiExpressionless,
  BsPersonFillGear
} from 'react-icons/bs';
import {
  MdCall,
  MdEmail,
  MdWorkHistory
} from 'react-icons/md';
import {
  GrDescending,
  GrLocation,
  GrUserManager
} from 'react-icons/gr';
import {
  GiCaptainHatProfile,
  GiClassicalKnowledge,
  GiOfficeChair,
  GiPositionMarker,
  GiPostOffice
} from 'react-icons/gi';
import { ImOffice } from 'react-icons/im';
import useGlass from '../../theme/glassEffect';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import axios from 'axios';
import { getAuthHeaders } from '../../utils/token';
import { url } from '../../utils/server';
import Leave from '../leave/Leave';
import UploadImage from './UploadImage';

function Profile() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.getEmployee);
  const [workingTime, setWorkingTime] = useState([]);
  const { employeeData } = useAuth();
  const [employee, setEmployee] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [manager, setManager] = useState();
  const [selectedDateTime, setSelectedDateTime] = useState(dayjs());
  const axiosConfig = getAuthHeaders();
  
  // State for modal
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getEmployee());
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const current = data.find((emp) => emp?._id === id);
    setEmployee(current);
    const manager = data.find((man) => man._id === current.senior);
    setManager(manager);

    if (employeeData.role === 'manager' && employeeData._id !== id) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
    fetchPerformance();
  }, [data, id, employeeData]);

  const fetchPerformance = async (selectedDate = selectedDateTime) => {
    try {
      const response = await axios.post(`${url}/employee/get-punch-data/${id}`, { month: selectedDate }, axiosConfig);
      setWorkingTime(response.data.reverse());
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${employee?.email}`;
  };

  const handleCallClick = () => {
    window.location.href = `callto:${employee?.phoneNumber}`;
  };

  const convertDecimalHours = (decimalHours) => {
    const hours = Math.floor(decimalHours);
    const remainingDecimal = decimalHours - hours;
    const minutes = Math.floor(remainingDecimal * 60);
    const seconds = Math.floor((remainingDecimal * 60 - minutes) * 60);
    return `${hours} hours : ${minutes} minutes`;
  };

  const getProgress = (decimalHours) => {
    const regex = /(\d+)\s+hours?\s*:\s*(\d+)\s+minutes?/;
    const match = decimalHours.match(regex);
    
    if (!match) {
      return 0;
    }

    const hours = parseInt(match[1], 10) || 0;
    const minutes = parseInt(match[2], 10) || 0;

    const totalMinutes = hours * 60 + minutes;

    let progress = (totalMinutes / (8 * 60)) * 100;

    // Ensure progress does not exceed 100
    if (progress > 100) {
        progress = 100;
    }

    return progress;
  };

  const calculateExperience = (startDate) => {
    const currentDate = new Date();
    const start = new Date(startDate);
    let years = currentDate.getFullYear() - start.getFullYear();
    let months = currentDate.getMonth() - start.getMonth();

    if (months < 0) {
      years--;
      months += 12;
    }

    return `${years} years ${months} months`;
  };

  const handleDateTimeChange = (newValue) => {
    setSelectedDateTime(newValue);
    fetchPerformance(newValue);
  };

  const handleUploadImage = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  if (!employee) return null;

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Grid width={'100%'}>
          <UserProfileNav workingTime={workingTime}/>
        </Grid>
        <Grid container>
          <Grid
            sx={{
              width: '40%',
              margin: '40px',
              backgroundColor: useGlass.glass.background,
              boxShadow: useGlass.glass.boxShadow,
              backdropFilter: useGlass.glass.backdropFilter,
              WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
              borderRadius: useGlass.glass.borderRadius,
              border: useGlass.glass.border,
            }}
          >
            <Grid sx={{ margin: '20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Grid container alignItems={'center'}>
                <IconButton>
                  <BsPersonFillGear color="white" />
                </IconButton>{' '}
                <Typography color={'white'}>{employee?.name}</Typography>
              </Grid>
              <Grid container alignItems={'center'}>
                <IconButton onClick={handleEmailClick}>
                  <MdEmail color="white" />
                </IconButton>
                <Typography color={'white'}>{employee?.email}</Typography> .
              </Grid>
              <Grid container alignItems={'center'}>
                <IconButton onClick={handleCallClick}>
                  <MdCall color="white" />
                </IconButton>
                <Typography color={'white'}>{employee?.phoneNumber}</Typography> .
              </Grid>
              <Grid container alignItems={'center'}>
                <IconButton>
                  <GrLocation color="white" />
                </IconButton>
                <Typography color={'white'}>
                  {`${employee?.city} ${employee?.address} ${employee?.pinCode}`}
                </Typography>
              </Grid>
              <Grid container alignItems={'center'}>
                <IconButton>
                  <ImOffice color="white" />
                </IconButton>
                <Typography color={'white'}>{`${employee?.department}`}</Typography>
              </Grid>
              <Grid container alignItems={'center'}>
                <IconButton>
                  <GiCaptainHatProfile color="white" />
                </IconButton>
                <Typography color={'white'}>{`${employee?.subRole}`}</Typography>
              </Grid>
              <Grid container alignItems={'center'}>
                <IconButton>
                  <GrUserManager color="white" />
                </IconButton>
                <Link to={`/${manager?.id || `${id}`}`}>
                  <Typography color={'white'}>{`${manager?.name || 'None'}`}</Typography>
                </Link>
              </Grid>
              <Grid container alignItems={'center'}>
                <IconButton>
                  <MdWorkHistory color="white" />
                </IconButton>
                <Typography color={'white'}>{calculateExperience(employee.createdAt)}</Typography>
              </Grid>
              <Grid container alignItems={'center'}>
                <Button onClick={handleUploadImage}>Upload Image</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            sx={{
              width: '40%',
              height: '450px',
              margin: '40px',
              backgroundColor: useGlass.glass.background,
              boxShadow: useGlass.glass.boxShadow,
              backdropFilter: useGlass.glass.backdropFilter,
              WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
              borderRadius: useGlass.glass.borderRadius,
              border: useGlass.glass.border,
            }}
          >
            <Grid
              sx={{
                width: '60%',
                display: 'flex',
                justifyContent: 'space-around',
                height: '100px',
                alignItems: 'center',
                height: 'min-content',
              }}
            >
              <Grid height={'50px'}>
                <Typography sx={{ color: 'white', marginTop: '20px', fontSize: '20px' }}>
                  Performance
                </Typography>
              </Grid>
              <Grid height={'50px'}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DemoItem>
                      <div style={{ width: '190px', position: 'absolute' }}>
                        <DatePicker
                          defaultValue={selectedDateTime}
                          onChange={handleDateTimeChange}
                          views={['year', 'month']}
                          sx={{
                            '& .MuiInputBase-input': {
                              color: 'white',
                            },
                            '& .MuiInputLabel-root': {
                              color: 'white',
                            },
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'white',
                            },
                            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'white',
                            },
                            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'white',
                            },
                          }}
                        />
                      </div>
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                height: '380px',
                overflow: 'scroll',
              }}
            >
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow sx={{color:'white'}}>
                        <TableCell sx={{color:'white'}}>Date</TableCell>
                        <TableCell sx={{color:'white'}}>Total Working</TableCell>
                        <TableCell sx={{color:'white'}}>Progress</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {workingTime.reverse().map((entry, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{color:'white'}}>{entry.date}</TableCell>
                          <TableCell sx={{color:'white'}}>{convertDecimalHours(entry.workingTime)}</TableCell>
                          <TableCell sx={{color:'white'}}>
                            <LinearProgress
                              variant="determinate"
                              value={getProgress(convertDecimalHours(entry.workingTime))}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
         {id===employeeData._id ?  (<Leave/>) : 'No data '}
        </Grid>
      </Box>

      {/* Modal for Upload Image */}
      <Dialog open={open} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Upload Image</DialogTitle>
        <DialogContent>
           <UploadImage/>
        </DialogContent>
        
      </Dialog>
    </>
  );
}

export default Profile;
