import { Box, Grid, Typography, Select, MenuItem, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useGlass from '../../theme/glassEffect';
import { useAuth } from '../../redux/authContext';
import axios from 'axios'; // Don't forget to import axios if not already imported
import { url } from '../../utils/server';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getLeadAction } from '../../redux/action/admin/leads/getLead';
import theme from '../../theme/theme';
import { socket } from '../../AppContent';
import { getAuthHeaders } from '../../utils/token';

function ModuleAssiment({ data ,lead}) {
  const [employees, setEmployees] = useState([]);
  const { employeeData } = useAuth();
  const [selectedValue1, setSelectedValue1] = React.useState('');
  const [selectedValue2, setSelectedValue2] = React.useState('');
  const [assignedLead, setAssignedLead] = useState([]);
  const axiosConfig = getAuthHeaders()
  const {id} = useParams()

  
  const assign = async()=>{
   
    const assign = await axios.get(
        `${url}/admin/lead/getmodule/${id}`,
        axiosConfig
      );
      setAssignedLead(assign.data.lead.assignTeam)
  }
  useEffect(() => {
    const fetchDepartments = async () => {
      
      try {
        const response = await axios.get(`${url}/admin/employee/departments`, axiosConfig);
       
        const { employee } = response.data;
        console.log(employee,'got it')
        setEmployees(employee);
        
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

  
    fetchDepartments();
    assign()
    
  }, []);


  const currentEmployee = employees.find(emp => emp._id === employeeData._id);
  const filteredEmployees = employees.filter(emp =>
    currentEmployee.junior && currentEmployee.junior.includes(emp._id)
  );
  

  const handleAssignLead = async () => {
    
     const leadId = id
    if (!selectedValue1 || !selectedValue2) {
      alert('Please select both a team member and a value.');
      return;
    }

    const payload = {
        assignee: selectedValue1,
      assignTask: selectedValue2,
      leadId:leadId
    };
    

    try {
      const response = await axios.post(`${url}/admin/lead/assign-lead`,payload,axiosConfig);
      console.log('Lead assigned successfully:', response.data);
      assign()
    
    } catch (error) {
      console.error('Error assigning lead:', error);
    }
  };

  return (
    <Box width={'100%'} sx={{
        background: 'white',
        
            width: '100%',
            minHeight: '370px',
            marginTop: '10px',
            backgroundColor: 'rgba( 255, 255, 255, 0.98 )',
            boxShadow: useGlass.glass.boxShadow,
            backdropFilter: useGlass.glass.backdropFilter,
            WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
            borderRadius: useGlass.glass.borderRadius,
            border: useGlass.glass.border,
            position: 'relative',
            
            overflow:'hidden'
    }}>
      <Grid container minHeight={'100%'} width={'100%'} borderRight={'1px solid grey'}>
        <Box
          sx={{
            borderBottom:'1px solid grey',
            marginBottom:'5px',
            height:'100%'
          }}
        >
          <Grid
            container
            alignItems="center"
            sx={{
              height: '50px',
              borderRight:'1px solid grey',
              borderBottom: '1px solid grey',
              backgroundColor: '#ffff'
            }}
          >
            <Typography variant="h6" sx={{ padding: '10px' }}>
              Assign Module
            </Typography>
          </Grid>
          <Grid
            container
            flexDirection={'column'}
            gap={'1px'}
            alignItems={'center'}
            marginTop={'20px'}
            minHeight="calc(100% - 50px)"
            width={'300px'}
            maxHeight="calc(100% - 50px)"
            overflow={'scroll'} // Adjusted height to fit remaining space after header
            sx={{ padding: '20px', overflowY: 'auto' }}
          >
            {/* First Select field */}
            <Select
              value={selectedValue1}
              onChange={(event) => setSelectedValue1(event.target.value)}
              displayEmpty
              fullWidth
              sx={{ marginBottom: '10px', width: '270px' }}
            >
              <MenuItem value="">Select Team Member</MenuItem>
              {filteredEmployees.map(emp => (
                <MenuItem key={emp._id} value={emp._id}>
                  {emp.name} {/* Adjust based on your employee object */}
                </MenuItem>
              ))}
            </Select>

            {/* Second Select field */}
            <Select
              value={selectedValue2}
              onChange={(event) => setSelectedValue2(event.target.value)}
              displayEmpty
              fullWidth
              sx={{ marginBottom: '10px', width: '270px' }}
            >
              <MenuItem value="">Select Value 2</MenuItem>
              {data && data.map(module => (
                <MenuItem key={module._id} value={module.moduleName}>
                  {module.moduleName} {/* Adjust based on your employee object */}
                </MenuItem>
              ))}
            </Select>

            {/* Button to Assign Lead */}
            <Button variant="contained" onClick={handleAssignLead} sx={{ width: '270px' , background:theme.palette.button.secondary ,marginBottom:'10px'}}>
              Assign Lead
            </Button>
          </Grid>
        </Box>
      </Grid>
      <Grid item width={'100%'} overflow={'scroll'} paddingTop={'0px'}>
  
      
        <Box minWidth={'300px'} overflow={'scroll'}>
        <Grid
            container
            alignItems="center"
            sx={{
              height: '50px',
              borderBottom: '1px solid grey',
              borderRight:'1px solid grey',
              overflow:'hidden',
              backgroundColor: '#ffff'
            }}
          >
          <Typography variant="h6" sx={{ padding: '10px' }}>Assigned Module Information</Typography>
          </Grid>
          <Grid height={'300px'} overflow={'scroll'} borderRight={'1px solid grey'}>
          {assignedLead.length > 0 ? (
           <Box>
          
           {assignedLead.length > 0 ? (
             assignedLead.map((assign, index) => {
               const assigneeEmployee = employees.find(emp => emp._id === assign.assignee);
               return (
                 <Box key={index} sx={{ marginBottom: '10px', width: '100%' }}>
                   <Typography sx={{ padding: '10px', minHeight: '80%', width: '100%', background: 'white', overflowWrap: 'break-word' }}>
                     Assignee: {assigneeEmployee ? assigneeEmployee.name : 'Unknown'}
                   </Typography>
                   <Typography sx={{ padding: '10px', minHeight: '80%', width: '100%', background: 'white', overflowWrap: 'break-word' }}>
                     Assigned Task: {assign.assignTask}
                   </Typography>
                 </Box>
               );
             })
           ) : (
             <Typography>No assigned leads yet.</Typography>
           )}
         </Box>
          ) : (
            <Typography>No assigned leads yet.</Typography>
          )}</Grid>
        </Box>
      
  
</Grid>
   
    </Box>
  );
}

export default ModuleAssiment;