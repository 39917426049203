import React, { useState, useEffect } from "react";
import FullCalendarComponent from "../../../components/navbar/calender/FullCalender";
import { Box, Grid, Typography, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import theme from "../../../theme/theme";
import { Button } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { url } from "../../../utils/server";
import { getAuthHeaders } from "../../../utils/token";
import { useAuth } from "../../../redux/authContext";
import UserProfileNav from "../../../components/leads/UserProfileNav";
import { zoomUrl } from "../../../utils/zoomUrl";

function Meeting() {
  const [events, setEvents] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [connected, setConnected] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const {employeeData} = useAuth()
  const navigate = useNavigate()
  const handleMeetings = (data) => {
    setEvents(data);
  };
console.log(connected)
  const connect = async () => {
    const axiosConfig = getAuthHeaders();
    const data = {
      code: code
    };
    try {
      const response = await axios.post(`${url}/connect-to-zoom`, data, axiosConfig);
      if (response.status === 200) {
        
        const { access_token, expires_in } = response.data; 
        if(access_token !==undefined){
          localStorage.setItem("access_token", access_token);
          const expiry_time = new Date().getTime() + (expires_in * 1000)
          localStorage.setItem("expiry_time", expiry_time)
        }
        
         console.log(access_token)

        
        setSnackbarMessage("Successfully connected to Zoom!");
        setSnackbarOpen(true);
        setTimeout(() => {
             navigate('/')
        }, 1000);
        
      } else {
        console.error("Error connecting to Zoom:", response.data);
        setSnackbarMessage(`Error: ${response.data.error}`);
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error connecting to Zoom:", error);
      setSnackbarMessage("Error connecting to Zoom. Please try again later.");
      setSnackbarOpen(true);
    }
  };
  useEffect(()=>{
    const stored_access_token = localStorage.getItem("access_token");
const stored_expiry_time = localStorage.getItem("expiry_time");

if (stored_access_token && stored_expiry_time) {
  const expiryTime = new Date(parseInt(stored_expiry_time));
    

    if (new Date() < expiryTime) {
        setConnected(true)
    } else {
        setConnected(false)
    }
} else {
    console.log("Access token or expiry time not found in localStorage");
    // Handle missing token or expiry time from localStorage
}

  },[])

  useEffect(() => {
    if (code) {
      connect();
    }
  }, [code]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box   sx={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
    }}>
      <Grid sx={{ width: "100%", padding: "50px" }}>
          <UserProfileNav />
        </Grid>
      <Box
        sx={{
          width: "100%",
          marginLeft: "50px",
        }}
      >
        <Typography
          sx={{
            marginTop: "50px",
            fontSize: "25px",
            color: theme.palette.text.primary,
            fontWeight: "100",
          }}
        >
          Meetings
        </Typography>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: 2,
            margin: 0,
            marginTop: "20px",
          }}
        >
          <Grid
            item
            xs={3}
            sx={{
              background: "#fff1",
              borderRadius: 1,
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: "2px",
              width:'20%',
              overflow: "scroll",
            }}
          >
            <Typography
              sx={{
                background: theme.palette.background.secondary,
                height: "35px",
                textAlign: "center",
                paddingTop: "15px",
                color: theme.palette.text.primary,
                position: "sticky",
                top: "0",
              }}
            >
              Events
            </Typography>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2px",
                height: "400px",
              }}
            >
              {events.length === 0 ? (
                <Typography>No events</Typography>
              ) : (
                events.map((meeting, index) => (
                  <Grid key={index} marginBottom={1} sx={{ background: "white" }}>
                    <Typography
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                        height: "35px",
                        textAlign: "center",
                        paddingTop: "0px",
                      }}
                    >
                      {meeting.title}
                    </Typography>
                    <Typography fontSize={10} textAlign={"center"}>{`Start:${new Date(
                      meeting.start
                    ).toLocaleString()}`}</Typography>
                    <Typography fontSize={10} textAlign={"center"}>{
                     <Link to={`${meeting.link}`}>{meeting.link}</Link> 
                    }</Typography>
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>

          <Grid item width={''}>
            <Link
              to={`${zoomUrl}`}
            >
              <Button
              disabled={connected}
                style={{
                  padding: "6px 20px",
                  borderRadius: "5px",
                  border: "none",
                  background: theme.palette.button.secondary,
                  color: "white",
                  position: "absolute",
                  marginTop: "-40px",
                  cursor: "pointer",
                }}
              >
               {connected === true ?  "Connect to Zoom " : "Connected"}
              </Button>
            </Link>
            

          
          </Grid>
          <Grid sx={{width:'100%'}}>
            <FullCalendarComponent meetings={handleMeetings} />
            </Grid>
        </Grid>

      </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={snackbarMessage.includes("Error") ? "error" : "success"}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default Meeting;
