import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [employeeData, setEmployeeData] = useState(
    JSON.parse(localStorage.getItem("employeeData")) || null
  );
  const [token, setToken] = useState(localStorage.getItem("jwtToken") || null);

  useEffect(() => {
    const storedEmployeeData = JSON.parse(localStorage.getItem("employeeData"));
    const storedToken = localStorage.getItem("jwtToken");

    if (storedEmployeeData) {
      const { data, expiry } = storedEmployeeData;
      if (new Date().getTime() > expiry) {
        // Remove expired employeeData
        localStorage.removeItem("employeeData");
        setEmployeeData(null);
      } else {
        // Set employeeData if not expired
        setEmployeeData(data);
      }
    }

    if (storedToken) {
      // Check token expiry if needed
      setToken(storedToken);
    }
  }, []);

  const login = (employee, token) => {
    const expiry = new Date().getTime() + 12 * 3600000; // 12 hours expiration
    localStorage.setItem("employeeData", JSON.stringify({ data: employee, expiry }));
    localStorage.setItem("jwtToken", token);
    setEmployeeData(employee);
    setToken(token);
  };

  const logout = () => {
    localStorage.removeItem("employeeData");
    localStorage.removeItem("jwtToken");
    setEmployeeData(null);
    setToken(null);
  };

  return (
    <AuthContext.Provider value={{ employeeData, token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
