import { BrowserRouter } from "react-router-dom";
import AppContent from "./AppContent";
import { AuthProvider } from "./redux/authContext";
import SocketProvider from "./socketSevice";

function App() {

 
  return (
    <SocketProvider>
      
    <AuthProvider>
      <BrowserRouter>
      
        <AppContent />
      </BrowserRouter>
    </AuthProvider>
    </SocketProvider>
  );
}

export default App;
