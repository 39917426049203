import React, { useState } from 'react';
import { Box, FormControl, TextField, Button, Checkbox, FormControlLabel, Snackbar, Alert } from '@mui/material';
import { useAuth } from '../../redux/authContext';
import axios from 'axios';
import { url } from '../../utils/server';
import { useNavigate, useParams } from 'react-router-dom';
import { getAuthHeaders } from '../../utils/token';
import { ThreeDots } from 'react-loader-spinner'; // Import the 3D spinner

const CallForm = ({saved}) => {
  const [time, setTime] = useState('');
  const [date, setDate] = useState('');
  const [callDetails, setCallDetails] = useState('');
  const [isReview, setIsReview] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const { employeeData } = useAuth();
  const { id } = useParams();

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === 'callTime') {
      setTime(value);
    } else if (name === 'callDate') {
      setDate(value);
    } else if (name === 'callDetails') {
      setCallDetails(value);
    } else if (name === 'review') {
      setIsReview(checked);
    } else if (name === 'done') {
      setIsDone(checked);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (time.trim() === '' || date.trim() === '' || callDetails.trim() === '') {
      alert('Please fill in all fields');
      return;
    }

    const data = {
      callTime: time,
      callDate: date,
      calledBy: employeeData._id,
      callDetails: callDetails,
      review: isReview,
      done: isDone,
    };

    setLoading(true); // Start loading
    try {
      const axiosConfig = getAuthHeaders();
      await axios.post(`${url}/admin/lead/call/${id}`, data, axiosConfig);
      setSnackbarMessage('Call details submitted successfully!');
      setSnackbarOpen(true);
      // Clear form fields
      setTime('');
      setDate('');
      setCallDetails('');
      setIsReview(false);
      setIsDone(false);
      saved(true)
      navigate(`/leads/${id}`)
    } catch (err) {
      setSnackbarMessage('Error submitting call details.');
      setSnackbarOpen(true);
      console.error(err);
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ paddingLeft: 0, height: '100%', marginTop: '-90px', position: 'relative' }}>
      {loading && (
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        }}>
          <ThreeDots 
            height="80" 
            width="80" 
            radius="9"
            color="green" 
            ariaLabel="three-dots-loading" 
            wrapperStyle={{}} 
            visible={true} 
          />
        </Box>
      )}
      <FormControl sx={{ padding: '20px', width: '90%' }}>
        <TextField
          autoFocus
          key="callTime"
          label="Call Time"
          type="time"
          name="callTime"
          value={time}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
          fullWidth
          sx={{
            width: '100%',
            '& .MuiInputBase-input': {
                color: 'white',
            },
            '& .MuiInputLabel-root': {
                color: 'white',
            },
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
          }}
        />

        <TextField
          key="callDate"
          label=""
          type="date"
          name="callDate"
          value={date}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
          fullWidth
          sx={{
            width: '100%',
            '& .MuiInputBase-input': {
                color: 'white',
            },
            '& .MuiInputLabel-root': {
                color: 'white',
            },
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
          }}
        />

        <TextField
          key="callDetails"
          label="Call Details"
          name="callDetails"
          value={callDetails}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
          multiline
          rows={3}
          fullWidth
          sx={{
            width: '100%',
            '& .MuiInputBase-input': {
                color: 'white',
            },
            '& .MuiInputLabel-root': {
                color: 'white',
            },
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
          }}
        />
        <FormControlLabel
          control={<Checkbox name="review" checked={isReview} onChange={handleChange} />}
          label="Mark as Reminder"
        />
        <FormControlLabel
          control={<Checkbox name="done" checked={isDone} onChange={handleChange} />}
          label="Forward To Booking"
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ mt: 2 }}
        >
          Submit
        </Button>
      </FormControl>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CallForm;
