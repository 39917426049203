import { Box, Grid } from '@mui/material'
import React from 'react'
import UserProfileNav from '../../../components/leads/UserProfileNav'
import EmployeeLogin from '../../../components/employee/EmployeeLogin'

function EmployeeDetails() {
  return (
    <> 
      <Box sx={{width:'100%'}}>
        <Grid>
          <UserProfileNav/>
        </Grid>
        <Grid>
           <EmployeeLogin/>
        </Grid>
      </Box>
    
    </>
  )
}

export default EmployeeDetails