import React, { useRef, useState } from "react";
import { Box, Grid, FormControl, TextField, MenuItem, Button, IconButton } from "@mui/material";
import { useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import theme from "../../../theme/theme";
import useGlass from "../../../theme/glassEffect";
import { BiLoaderCircle } from "react-icons/bi";
import { useSelector } from "react-redux";


function LeadsAdd({schema,saved,editData, updated}) {
  const [loading,setLoading] = useState()
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
     
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    resolver: zodResolver(schema)
  });
  console.log(errors)
  const formRef = useRef(null);
  let {selectedData} = useSelector((state)=>state.editLeadData)
   console.log(selectedData,'kr le')
  const fields = [
    { id: "name", name: "name", label: "Name*", placeholder: "Enter name", type: "text", required: true, defaultValue: selectedData?.name || "" },
    { id: "email", name: "email", label: "Email*", placeholder: "Enter email", type: "email", required: true, defaultValue: selectedData?.email || "" },
    { id: "phone", name: "phoneNumber", label: "Phone Number*", placeholder: "Enter phone number", type: "tel", required: true, maxLength: 10, defaultValue: selectedData?.phoneNumber || "" },
    { id: "source", name: "source", label: "Source", placeholder: "Enter source", type: "text", defaultValue: selectedData?.source || "" },
    { id: "companyName", name: "companyName", label: "Company Name", placeholder: "Enter company", type: "text", required: true, defaultValue: selectedData?.companyName || "" },
    { id: "city", name: "city", label: "City", placeholder: "Enter city", type: "text", required: true, defaultValue: selectedData?.city || "" },
    { id: "pinCode", name: "pinCode", label: "Pin Code", placeholder: "Enter pin code", type: "text", required: true, defaultValue: selectedData?.pinCode || "" },
    { id: "companyAddress", name: "companyAddress", label: " Company Address", placeholder: "Enter company address", type: "text", required: true, defaultValue: selectedData?.companyAddress || "" },
    { id: "companyWebLink", name: "companyWebLink", label: " Company Web Link", placeholder: "Enter web link", type: "text", defaultValue: selectedData?.companyWebLink || "" },
    { id: "projectName", name: "projectName", label: "Project Name", placeholder: "Enter project name", type: "text", required: true, defaultValue: selectedData?.projectName || "" },
    
    { id: "remark", name: "remark", label: "Remark", placeholder: "Enter desc...", type: "text", defaultValue: selectedData?.remark || "" },

    { id: "projectLanguage", name: "projectLanguage", label: "Project Language", placeholder: "Enter project language", type: "text", defaultValue: selectedData?.projectLanguage || "" },
  ];
  
  
  const onSubmit = async () => {
    try {
      setLoading(!loading)
      const formData = new FormData(formRef.current);
      const data = Object.fromEntries(formData.entries());
      
      
      const inputData = await schema.parseAsync(data)
      if(selectedData){
        const update = await updated(true,inputData)
        console.log('update', update)
        reset();
      }
      else{
        const save = await saved(true,inputData)
        console.log('save',save)
        reset();
      }} catch (error) {
      console.error("Validation error:", error.errors);
      alert("Form validation failed. Please check the errors and try again.");
    }
    finally{
        setLoading(!loading)
    }
  };
  

  return (
    
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        backgroundColor: useGlass.glass.background,
        boxShadow: useGlass.glass.boxShadow,
        backdropFilter: useGlass.glass.backdropFilter,
        WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
        borderRadius: useGlass.glass.borderRadius,
        border: useGlass.glass.border,
       
      }}
    >
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)} style={{ width: '100%',background:'transparent' }} >
        <FormControl sx={{ width: "80%", padding: "20px" ,background:'transparent' }}>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
              padding: "20px",
            }}
          >
            {fields.map((field) => (
              <Grid item key={field.id} xs={field.type === "select" ? 6 : 3}>
                {field.type === "select" ? (
                  <TextField
                    id={field.id}
                    select
                    name={field.name}
                    label={field.label}
                    placeholder={field.placeholder}
                    variant="outlined"
                    fullWidth
                    {...register(field.name, {
                      required: `${field.label} is required`,
                    })}
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                  >
                    {field.options.map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <TextField
                    id={field.id}
                    name={field.name}
                    label={field.label}
                    placeholder={field.placeholder}
                    type={field.type}
                    defaultValue={field.defaultValue !== undefined ? field.defaultValue : ''}

                    variant="outlined"
                    
                    inputProps={field.maxLength ? {maxLength: 10 }: ''}
                    fullWidth
                    {...register(field.name, {
                      required: `${field.label} is required`,
                    })}
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                  />
                )}
              </Grid>
            ))}
           {loading ? (
              <IconButton>
                <BiLoaderCircle />
              </IconButton>
            ) : (
              <Button type="submit" variant="contained" sx={{
                background: theme.palette.button.secondary,
                marginTop: 1
              }}>
                {selectedData ? 'Update' : 'Submit'}
              </Button>
            )}
          
          </Grid>
          <Grid item xs={6}>
              
            </Grid>
        </FormControl>
      </form>
    </Box>
  );
}

export default LeadsAdd;
