import { Box, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BiLogIn } from 'react-icons/bi';
import { MdEventAvailable, MdLogout } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployee } from '../../../redux/action/admin/employee/get';
import axios from 'axios'; // Import axios
 // Import getAuthHeaders and url from your authentication module
import { useAuth } from '../../../redux/authContext';
import { url } from '../../../utils/server';
import { getAuthHeaders } from '../../../utils/token';

function Leave() {
    const { employeeData } = useAuth(); // Assuming useAuth is correctly implemented elsewhere
    const axiosConfig = getAuthHeaders();
    const [leaveList, setLeaveList] = useState([]);
    const { data } = useSelector((state) => state.getEmployee);
    const dispatch = useDispatch();
    const [punchIn, setPunchIn] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(getEmployee());
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [dispatch]);

    useEffect(() => {
        fetchLeaveData();
    }, []);

    const fetchLeaveData = async () => {
        try {
            const response = await axios.get(`${url}/admin/employee/leave`, axiosConfig);
            const today = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
            const filteredLeave = response.data[0]?.leaveDetails.filter(item =>
                item.leaveStatus === 'accepted' && item.leaveDate?.slice(0, 10) === today
            );
            setLeaveList(filteredLeave);
        } catch (error) {
            console.error('Error fetching leave data:', error);
        }
    };

  

    return (
        <>
            <Box sx={{
                background:'white',
                width:'100%',
                height:'150px',
                borderRadius:'20px',
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
            }}>
                <Typography mt={2}>Leave Status</Typography>
                <Grid sx={{
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                }}>
                    <IconButton>
                        <span style={{fontSize:'13px'}}>On Leave</span>  <BiLogIn color='green' size={30}/>
                    </IconButton> 
                    <Typography>{leaveList?.length}</Typography>
                </Grid>
                <Grid sx={{
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                }}>
                    <IconButton>
                        <span style={{fontSize:'13px'}}>In Office</span> <MdEventAvailable color='green' size={27}/>
                    </IconButton> 
                    <Typography>{data.length - leaveList?.length}</Typography>
                </Grid>
            </Box>
        </>
    );
}

export default Leave;
