export const startTimer = (setIntervalId, setElapsedSeconds) => {
    const intervalId = setInterval(() => {
      setElapsedSeconds(prevSeconds => {
        const newSeconds = prevSeconds + 1;
        localStorage.setItem("elapsedSeconds", newSeconds); // Save elapsed seconds to localStorage
        return newSeconds;
      });
    }, 1000); // Update every second
    setIntervalId(intervalId);
  };
  
  // Function to stop the timer
  export const stopTimer = (intervalId) => {
    clearInterval(intervalId);
  };
  
  // Function to format elapsed time to HH:MM:SS
  export const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };