import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TotalleadaCard from "../../../subcomponent/leads/TotalleadaCard";
import BarsDataset from "../../../components/navbar/charts/BarChart";
import useGlass from "../../../theme/glassEffect";
import theme from "../../../theme/theme";
import SelectOptions from "../../../subcomponent/SelectTheme";
import MyLineChart from "../../../components/navbar/charts/LineChart";
import PaiChart from "../../../components/navbar/charts/PaiChart";
import ProgressChart from "../../../components/navbar/charts/ProgressChart";
import { useDispatch, useSelector } from "react-redux";
import { url } from "../../../utils/server";
import axios from "axios";
import { getAuthHeaders } from "../../../utils/token";
import { getLeadAction } from "../../../redux/action/admin/leads/getLead";
import { useAuth } from "../../../redux/authContext";
import UserProfileNav from "../../../components/leads/UserProfileNav";

function Dashboard() {
  const { leadsData } = useSelector((state) => state.getLeadData);
  const [leadDetails, setLeadDetails] = useState([]);
  const axiosConfig = getAuthHeaders();
  const dispatch = useDispatch();
  const [customer, setCustomer] = useState()
  const { employeeData } = useAuth();
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const data = leadsData.map((data) => {
    const createdAtDate = new Date(data.createdAt);
    const month = monthNames[createdAtDate.getMonth()];
    const matchingLeadDetail = leadDetails.find(lead => lead.lead === data._id);
    return {
      id: data._id,
      leadName: data.leads[0][0].projectName,
      paymentAmount: matchingLeadDetail?.paymentHistory.totalPayment > 0? matchingLeadDetail.paymentHistory.totalPayment : 0,
      month: month
    };
  });

  // Generate barData dynamically based on the count of leads by month
  const barData = data.reduce((acc, curr) => {
    const monthIndex = monthNames.indexOf(curr.month);
    if (monthIndex >= 0) {
      acc[monthIndex] = (acc[monthIndex] || 0) + 1;
    }
    return acc;
  }, new Array(12).fill(0)).map((count, index) => ({
    id: index + 1,
    Leads: count,
    month: monthNames[index],
  })).filter(item => item.Leads > 0);

  useEffect(() => {
    const fetchLeadDetails = async () => {
      try {
        const response = await axios.get(`${url}/admin/lead-details`, axiosConfig);
        setLeadDetails(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchLeadDetails();
  }, []);

  let totalAmount = leadDetails.reduce((sum, data) => {
    const totalPayment = data?.paymentHistory?.totalPayment;
    return sum + (totalPayment ? Number(totalPayment) : 0);
  }, 0);

  useEffect(() => {
    const fetchData = async () => {
      const employeeIds = [employeeData._id, ...(employeeData.junior || [])];
      try {
        await dispatch(getLeadAction(employeeIds));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [employeeData]);

  // Aggregate line chart data
  const currentMonthIndex = new Date().getMonth(); // Get current month index (0-11)

  const lineData = [];
  for (let i = 0; i < 12; i++) {
    const monthIndex = (currentMonthIndex + i) % 12; // Wrap around the month
    const month = monthNames[monthIndex];
    
    const monthLeads = data.filter(lead => lead.month === month).length;
    const monthConversions = customer?.filter(lead => {
      const createdAtDate = new Date(lead.createdAt);
      return createdAtDate.getMonth() === monthIndex; // Match the month index directly
    }).length || 0;
  
    lineData.push({
      month: month,
      Leads: monthLeads,
      Conversion: monthConversions,
    });
  }
  console.log(lineData,'lline')

  const ProgressData = {
    target: 200,
    Leads: leadsData.length,
  };
  const getCoustomer = async()=>{
    try{
      const coustomer = await axios.get(`${url}/admin/coustomer/get`,axiosConfig)
      const coustomerData = coustomer.data; // Assuming the response contains the relevant data
       
       setCustomer(coustomerData.leads)
      
    }
    catch(err){
      console.log(err)
    }
    
  } 
  useEffect(()=>{
     getCoustomer()
  },[])

  const PaiData = [
    { id: 0, value: leadsData.length, label: "Leads" },
    { id: 1, value: customer?.length, label: "Conversion" },
  ];

  return (
    <Box   sx={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
    }}>
      <Grid sx={{ width: "100%", padding: "50px" }}>
          <UserProfileNav />
        </Grid>
    <Box sx={{ padding: "20px", paddingTop: '50px', height: '750',width:'100%' }}>
      
      <Grid container gap={7.5}>
        <Grid>
          <TotalleadaCard data={data} reference={'Leads'} amount={totalAmount} />
        </Grid>
        <Grid
          sx={{
            minWidth: "570px",
            maxWidth: "570px",
            backgroundColor: useGlass.glass.background,
            borderRadius: "20px",
            border: useGlass.glass.border,
            overflow: "hidden",
          }}
        >
          <Grid
            container
            sx={{
              width: "100%",
              background: theme.palette.background.secondary,
              padding: "7px",
              display: "flex",
              alignItems: "center",
              gap: 30,
            }}
          >
            <Grid>
              <Typography sx={{ color: theme.palette.text.primary, paddingLeft: "20px" }}>
                Leads by Month
              </Typography>
            </Grid>
            <Grid sx={{ maxWidth: "20%" }}>
              <SelectOptions />
            </Grid>
          </Grid>
          <Grid sx={{ marginLeft: "60px", marginTop: "-25px", overflow: "scroll" }}>
            <BarsDataset data={barData} />
          </Grid>
        </Grid>
        {/* Other charts */}
        <Grid sx={{
          minWidth: "180px",
          maxWidth: "180px",
          maxHeight: "250px",
          minHeight: "250px",
          backgroundColor: useGlass.glass.background,
          borderRadius: "20px",
          border: useGlass.glass.border,
          overflow: "hidden",
        }}>
          <Grid container sx={{ width: "100%", background: theme.palette.background.secondary, padding: "7px", display: "flex", alignItems: "center", gap: 4 }}>
            <Grid>
              <Typography sx={{ color: theme.palette.text.primary, paddingLeft: "20px" }}>
                Leads
              </Typography>
            </Grid>
            <Grid sx={{ maxWidth: "20%" }}>
              <SelectOptions />
            </Grid>
          </Grid>
          <Grid sx={{ overflow: "hidden", display: "flex", justifyContent: "center", alignContent: "center" }}>
            <ProgressChart value={ProgressData} />
          </Grid>
        </Grid>
        <Grid sx={{
          minWidth: "180px",
          maxWidth: "180px",
          maxHeight: "250px",
          minHeight: "250px",
          backgroundColor: useGlass.glass.background,
          borderRadius: "20px",
          border: useGlass.glass.border,
          overflow: "hidden",
        }}>
          <Grid container sx={{ width: "100%", background: theme.palette.background.secondary, padding: "7px", display: "flex", alignItems: "center", gap: 4 }}>
            <Grid>
              <Typography sx={{ color: theme.palette.text.primary, paddingLeft: "20px" }}>
                Leads
              </Typography>
            </Grid>
            <Grid sx={{ maxWidth: "20%" }}>
              <SelectOptions />
            </Grid>
          </Grid>
          <Grid sx={{ overflow: "hidden", display: "flex", justifyContent: "center", alignContent: "center" }}>
            <PaiChart data={PaiData} />
          </Grid>
        </Grid>
        <Grid sx={{
          minWidth: "390px",
          maxWidth: "390px",
          maxHeight: "250px",
          minHeight: "250px",
          backgroundColor: useGlass.glass.background,
          borderRadius: "20px",
          border: useGlass.glass.border,
          overflow: "hidden",
        }}>
          <Grid container sx={{ width: "100%", background: theme.palette.background.secondary, padding: "7px", display: "flex", alignItems: "center", gap: 30 }}>
            <Grid>
              <Typography sx={{ color: theme.palette.text.primary, paddingLeft: "20px" }}>
                Leads
              </Typography>
            </Grid>
            <Grid sx={{ maxWidth: "20%" }}>
              <SelectOptions />
            </Grid>
          </Grid>
          <Grid sx={{ marginLeft: "20px", marginTop: "-25px", overflow: "scroll" }}>
            <MyLineChart data={lineData} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
    </Box>
  );
}

export default Dashboard;
