// SocketProvider.js
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import io from "socket.io-client";
import { ADD_NOTIFICATION } from "./redux/constents/notification/notification";


const socket = io("https://backend-en4r.onrender.com", {
  withCredentials: true 
});

const SocketProvider = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const updatenotification = (response) => {
      setTimeout(() => {
        dispatch({
          type: ADD_NOTIFICATION,
          payload: response
        });
      }, 2000); // Adjust the delay time in milliseconds (e.g., 2000 for 2 seconds)
    };

    // Retrieve employee data from localStorage
    let data = localStorage.getItem('employeeData');
    let employeeData = data ? JSON.parse(data).data : null;
    
    if (employeeData) {
      socket.connect();

      // Join a room based on user ID
      socket.emit('join', `user_${employeeData._id}`);
      console.log('Joined room:', `user_${employeeData._id}`);
    }

    socket.on('connect', () => {
      console.log("WebSocket connected successfully!");
    });

    const handleNotification = (response) => {
      console.log('Received notification:', response);

      // Check if the browser supports notifications
      if ("Notification" in window) {
        // Request permission from the user to show notifications
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            // Create a notification
            const notification = new Notification('New Notification', {
              body: response.message
            });
            notification.onclick = () => {
              // Check if the browser window is open
              if (!window.open) {
                // If window is not open, navigate to fallback URL
                window.location.href = `/leads/${response.navigate}`;
              } else {
                window.location.href = `${response.navigate}`;
              }
            };
          } else {
            // Fallback if permission is not granted
            alert(`New notification: ${response.message}`);
          }
        });
      } else {
        // Fallback if the browser doesn't support notifications
        alert(`Browser does not support notifications`);
      }
      
      // Call updatenotification function
      updatenotification(response);
    };

    socket.on('notification', handleNotification);
    
    socket.on('disconnect', () => {
      console.log("Disconnected from WebSocket server");
    });

    return () => {
      // Clean up event listeners
      socket.off('notification', handleNotification);
      socket.disconnect();
    };
  }, [dispatch]); // Add dispatch as a dependency to useEffect

  return <>{children}</>;
};

export default SocketProvider;
