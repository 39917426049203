import { EmojiPeopleRounded, GroupAddRounded } from '@mui/icons-material'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { GiRamProfile } from 'react-icons/gi'
import { MdGroupWork, MdGroups } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { getEmployee } from '../../../redux/action/admin/employee/get'

function TotalEMp() {
    const dispatch = useDispatch()
    const { data } = useSelector((state) => state.getEmployee);
    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(getEmployee());
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [dispatch]);
  return (
    <>
      <Box sx={{
        background:'white',
        width:'100%',
        height:'150px',
        borderRadius:'20px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        
      }}>
          <Typography mt={2}>Total Employees</Typography>
          <Grid sx={{
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
          }}>
          <IconButton>
             <MdGroups color='green' size={50}/>
        </IconButton> 
          <Typography>{data?.length}</Typography>
          </Grid>
          
           
      </Box>
    </>
  )
}

export default TotalEMp