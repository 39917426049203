import {
    GET_LEAD_REQUEST,
    GET_LEAD_SUCCESS,
    GET_LEAD_FAIL,
    CATCH_ERROR
 } from '../../../constents/admin/lead.js'
   
   const initialState = {
       loading : false,
       error:null,
       leadsData:[],
       totalLeads:null,
       totalPoint:null
   };
   
   export const getLeadReducer = (state = initialState, action)=>{
        switch(action.type){
           case GET_LEAD_REQUEST:
               return{
                   ...state,
                   loding:true
               };
           case GET_LEAD_SUCCESS:
              
               return{
                   ...state,
                   loding:false,
                   leadsData:action.payload,
                   totalLeads:action.payloadCount,
                   totalPoint:action.totalPoint
               };
           case GET_LEAD_FAIL:
               return{
                   ...state,
                   loding:false,
                   error: action.payload
               }
           case CATCH_ERROR:
               return{
                   ...state,
                   error:null
               }
             default : return state  
        }
   };