// tokenUtils.js

const TOKEN_KEY = 'jwtToken';

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const getAuthHeaders = () => {
  const token = getToken();

  if (!token) {
    throw new Error('No token found');
  }

  return {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    withCredentials: true
  };
};
