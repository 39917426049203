import {
    ADD_EMPLOYEE_REQUEST,
    ADD_EMPLOYEE_SUCCESS,
    ADD_EMPLOYEE_FAIL,
    CATCH_ERROR
 } from '../../../constents/admin/employee.js'
   
   const initialState = {
       loading : false,
       error:null,
       data:false
       
   };
   
   export const createEmployeeReducer = (state = initialState, action)=>{
        switch(action.type){
           case ADD_EMPLOYEE_REQUEST:
               return{
                   ...state,
                   loding:true
               };
           case ADD_EMPLOYEE_SUCCESS:
             console.log(action.payload)
               return{
                   ...state,
                   loding:false,
                   data:action.payload
                   
               };
           case ADD_EMPLOYEE_FAIL:
               return{
                   ...state,
                   loding:false,
                   error: action.payload
               }
           case CATCH_ERROR:
               return{
                   ...state,
                   error:null
               }
             default : return state  
        }
   };