import React from 'react'
import CoustomerTable from '../../../components/navbar/Customer'
import { Box } from '@mui/material'

function Coustmer() {
  return (
    <>
     <Box sx={{
      width:'100%'
     }}>
      <CoustomerTable/>

     </Box>
     
    </>
  )
}

export default Coustmer