import React, { useState, useEffect } from "react";
import UserProfileNav from "../../../components/leads/UserProfileNav";
import {
  Box,
  Grid,
  LinearProgress,
  Typography,
  Modal,
  TextField,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import theme from "../../../theme/theme";
import { BiAddToQueue } from "react-icons/bi";
import useGlass from "../../../theme/glassEffect";
import axios from "axios";
import { url } from "../../../utils/server";
import { useParams } from "react-router-dom";
import { GrAdd } from "react-icons/gr";
import LeadWorker from "../../../components/leads/LeadWorker";
import LeadAssiment from "../../../components/leads/LeadAssiment";
import ModuleAssiment from "../../../components/customer/ModuleAssiment";
import { getAuthHeaders } from "../../../utils/token";
import ModuleWorker from "../../../components/customer/ModuleWorker";
import ProjectWorkHistory from "../../../components/customer/ProjectWorkHistory";


function CoustmerProfile() {
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [isAddDataOpen, setIsAddDataOpen] = useState(false);
  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [leadDetails, setLeadDetails] = useState()
  const [assignteams, setAssignTeams] = useState()
  const [clientchange, setclientChange] = useState(false)
  const axiosConfig = getAuthHeaders()
  const fetchModule = async () => {
    try {

      const response = await axios.get(
        `${url}/admin/lead/getmodule/${id}`,
        axiosConfig
      );

      setData(response.data.modules);
      setAssignTeams(response.data)
      const totalModules = response.data.modules;
      let completedModuleCount = 0;
      const selectedSet = totalModules.filter((module) => {
        if (module.moduleStage === "complete") {
          completedModuleCount++;
          return false;
        }
        return true;
      });

      setValue((completedModuleCount / totalModules.length) * 100);
      setSelectedItem(selectedSet.length > 0 ? selectedSet[0] : null);
      setLeadDetails(response.data.lead.leads)
      setAssignTeams(response.data)

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchModule();
  }, [clientchange]); // Fetch modules once on component mount

  const handleOpenAddData = () => {
    setIsAddDataOpen(true);
  };

  const handleCloseAddData = () => {
    setIsAddDataOpen(false);
  };

  const handleAddData = async () => {

    try {
      const newData = {
        leadId: id,
        moduleName: newName,
        moduledesc: newDescription,
        stage: "ongoing",
      };
      await axios.post(`${url}/admin/lead/addModule`, newData, axiosConfig);
      fetchModule(); // Refresh modules after adding new module
    } catch (err) {
      console.log(err);
    }
    setNewName("");
    setNewDescription("");
    setIsAddDataOpen(false);
  };

  const handleSave = () => {
    setclientChange(true)
  }

  const handleItemClick = (index) => {
    setSelectedItem(index === selectedItem ? null : data[index]);
  };
  console.log(newDescription)
  const handleComplete = async () => {
    if (!selectedItem) return;

    try {
      const leadId = id
      await axios.put(
        `${url}/admin/lead/updateModule/${selectedItem._id}`,
        { leadId },
        axiosConfig
      );
      fetchModule();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",


        }}
      >
        <Grid sx={{ width: '100%', padding: '50px' }}>
          <UserProfileNav />
        </Grid>

        <Box p={2} display={"flex"} gap={"20px"} flexDirection={"column"} width={'100%'}>
          <LinearProgress
            variant="determinate"
            value={value}
            sx={{
              height: "25px",
              maxWidth: '1000px',
              minWidth: '1000px',
              borderRadius: 5,
              background: theme.palette.background.default,
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#4CAF50",
                borderRadius: 2,
              },
            }}
          />
          <Typography
            sx={{
              position: "absolute",
              top: "69px",
              left: "50%",
              color: "white",
              fontSize: "12px",
            }}
          >
            Lead Progress {value}%
          </Typography>

          <Grid container>
            <Grid item textAlign={"center"} marginTop={"0px"}>
              <Tooltip title="Add Module" arrow placement="top">
                <IconButton
                  sx={{
                    color: "white",
                    fontSize: 25,
                    cursor: "pointer",
                  }}
                  onClick={handleOpenAddData}
                >
                  <BiAddToQueue />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item marginLeft={"10px"} sx={{ display: "flex", flexWrap: 'wrap', gap: 1 }}>
              {data &&
                data.map((item, index) => (
                  <Tooltip title={item.moduleName} arrow placement="top" >
                    <Typography
                      key={item._id} // Use data._id as key
                      onClick={() => handleItemClick(index)} // Handle click on each item
                      sx={{
                        background:
                          item.moduleStage === "complete" ? "#4CAF50" : "white",
                        color: item.stage === "completed" ? "white" : "black",
                        padding: "10px 30px",
                        height: "20px",
                        width: "60px",
                        borderBottom: `1px solid #0005`,
                        borderRight: `1px solid #0000`,
                        textAlign: "center",
                        clipPath:
                          "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)",
                        boxShadow:
                          selectedItem && item._id === selectedItem._id
                            ? "0 0 10px rgba(0,0,0,0.5)"
                            : "none", // Apply shadow when selected
                        cursor: "pointer", // Change cursor to pointer
                      }}
                    >
                      {`Module${index + 1}`}
                    </Typography>
                  </Tooltip>
                ))}
            </Grid>
          </Grid>


          <Grid width={'100%'} sx={{ display: 'flex', gap: 2 }}>

            {selectedItem && (
              <Box
                sx={{
                  background: "white",
                  // padding: "20px",
                  width: "30%",
                  minHeight: "370px",
                  marginTop: "10px",
                  backgroundColor: 'rgba( 255, 255, 255, 0.65 )',
                  boxShadow: useGlass.glass.boxShadow,
                  backdropFilter: useGlass.glass.backdropFilter,
                  WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
                  borderRadius: useGlass.glass.borderRadius,
                  border: useGlass.glass.border,
                  // boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                  position: 'relative',

                }}
              >
                <Grid
                  sx={{
                    width: "100%",
                    height: "50px",
                    borderBottom: "1px solid grey",
                    backgroundColor: "white",

                    backdropFilter: useGlass.glass.backdropFilter,
                    WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
                    borderRadius: useGlass.glass.borderRadius,
                    border: useGlass.glass.border,
                  }}
                >
                  <Typography variant="h6" sx={{ padding: "10px", background: '$fff1' }}>
                    {selectedItem.moduleName}
                  </Typography>
                </Grid>
                <Grid height={"75%"} sx={
                  {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1px'
                  }
                }>
                  <Typography variant="body1" padding={"20px"} minHeight={"100%"} sx={{ background: 'white' }}>
                    {selectedItem.moduleDesc}
                  </Typography>
                </Grid>

                {selectedItem.moduleStage === "ongoing" && (
                  <Grid marginLeft={'20px'}>
                    <Button variant="contained" onClick={handleComplete} sx={{
                      background: theme.palette.button.secondary,
                      position: 'absolute',
                      bottom: '20px'
                    }}>
                      Complete
                    </Button>
                  </Grid>
                )}
              </Box>
            )}



            <Grid width={'70%'} >
              <ModuleWorker data={leadDetails} saved={handleSave} />

            </Grid>

          </Grid>
          <Box display={'flex'} alignItems={'center'} gap={2}>
          <Grid width={'30%'}>
            <ModuleAssiment data={data} lead={assignteams} />

          </Grid>
          <Grid width={'70%'}>
              <ProjectWorkHistory data={data}/>
          </Grid>
          </Box>
          


          <Modal
            open={isAddDataOpen}
            onClose={handleCloseAddData}
            aria-describedby="modal to add new data"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                backgroundColor: 'rgba( 255, 255, 255, 0.45 )',
                boxShadow: useGlass.glass.boxShadow,
                backdropFilter: useGlass.glass.backdropFilter,
                WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
                borderRadius: useGlass.glass.borderRadius,
                border: useGlass.glass.border,
                p: 4,
              }}
            >
              <Typography variant="h6" component="h2" gutterBottom>
                Add New Module
              </Typography>
              <TextField
                label="Module Name"
                variant="outlined"
                fullWidth
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                type="textarea"
                label="Description"
                variant="outlined"
                fullWidth
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button variant="contained" onClick={handleAddData} sx={{
                background: theme.palette.button.secondary,
                padding: '5px 40px'
              }}>
                Add
              </Button>
            </Box>
          </Modal>
        </Box>
      </Grid>
    </>
  );
}

export default CoustmerProfile;