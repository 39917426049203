import React, { useEffect, useState } from "react";
import Tree from "react-d3-tree";
import { useCenteredTree } from "./helpers";
import { url } from "../../../utils/server";
import axios from "axios";
import { Link } from "react-router-dom";
import { getAuthHeaders } from "../../../utils/token";

const containerStyles = {
  width: "100vw",
  height: "100vh",
};

const Node = ({ nodeDatum, toggleNode, foreignObjectProps }) => {
  const { _id, name, subRole, position, department,avatar, children, __rd3t } =
    nodeDatum;
   console.log(avatar)
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const nodeBaseStyle = {
    border: "1px solid black",
    backgroundColor: "white",
    height: "85px",
    position: "relative",
    width: "270px",
    display: "flex",
    marginLeft: "0px",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "10px",
    overflow: "hidden",
    borderRadius: "20px",
    transition: "height 0.2s ease-in-out",
    
  };

  const nodeHoverStyle = {
    ...nodeBaseStyle,
    height: "250px"
  };

  return (
    <g>
      <circle r={20} fill="green" onClick={toggleNode}></circle>
      <foreignObject {...foreignObjectProps} style={{ height: "300px" , borderRadius:'20px'}}>
        <Link to={`/${_id}`} color="white">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              zIndex: 9999999,
              position: "absolute",
              top: "43px",
              left: "15px",
              borderRadius:'20px'
            }}
          >
            <img
              src={ avatar ? avatar : "https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg"}
              alt=""
              width={"80"}
              height={"80"}
              style={{ borderRadius: "50%", marginLeft: 80 }}
            />
          </div>
          <div
            style={isHovered ? nodeHoverStyle : nodeBaseStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div>
              <p style={{ textAlign: "center",color:'black', marginTop: isHovered ? 15: -48 }}>{`${name}`}</p>
              <p style={{ textAlign: "center",color:'black', marginTop: 0 }}>{`${subRole}`}</p>
            </div>
            <div style={{ position: "absolute", top:isHovered ? '180px' : '110px' }}>
              <div style={{display:'flex', marginLeft:'30px', gap:'20px'}}>
                <span style={{color:'black',}}>Position:</span>
                <p style={{ textAlign: "center",color:'black', marginTop: 0 }}>{`${position}`}</p>
              </div>
              <div style={{display:'flex', marginLeft:'30px', gap:'20px'}}>
                <span style={{color:'black',}}>Department:</span>
                <p style={{ textAlign: "center", color:'black',marginTop: 0 }}>{`${department}`}</p>
              </div>
            </div>
          </div>
        </Link>
      </foreignObject>
    </g>
  );
};

const App = () => {
  const [orgChart, setOrgChart] = useState(null);
  const [translate, containerRef] = useCenteredTree();

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const axiosConfig = getAuthHeaders();
        const response = await axios.get(
          `${url}/admin/employee/departments`,
          axiosConfig
        );
        const { employee } = response.data;

        // Build hierarchical org chart structure
        const buildOrgChart = (employees, seniorId) => {
          const filteredEmployees = employees.filter(
            (emp) => emp.senior === seniorId
          );
          return filteredEmployees.map((emp) => ({
            _id: emp._id,
            name: emp.name,
            subRole: emp.subRole,
            position: emp.position,
            department: emp.department,
            avatar :emp.avatar,
            children: buildOrgChart(employees, emp._id),
          }));
        };

        // Find employees who do not have a senior (potential CEOs or root nodes)
        const ceos = employee.filter((emp) => emp.senior === null);

        // Build org chart for each CEO (or root node)
        const orgCharts = ceos.map((ceo) => ({
          _id: ceo._id,
          name: ceo.name,
          subRole: ceo.subRole,
          position: ceo.position,
          department: ceo.department,
          avatar :ceo.avatar,
          children: buildOrgChart(employee, ceo._id),
        }));

        if (orgCharts.length > 0) {
          setOrgChart(orgCharts);
        } else {
          console.error("No CEO found in the data.");
        }
      } catch (err) {
        console.error("Error fetching employees:", err);
      }
    };

    fetchEmployees();
  }, []);

  if (!orgChart) return null;

  return (
    <div style={containerStyles} ref={containerRef}>
      <Tree
        data={orgChart}
        pathFunc={"diagonal"}
        translate={translate}
        nodeSize={{ x: 500, y: 320 }}
        renderCustomNodeElement={(rd3tProps) =>
          <Node
            nodeDatum={rd3tProps.nodeDatum}
            toggleNode={rd3tProps.toggleNode}
            foreignObjectProps={{ width: 300, height: 150, x: 20 }}
          />
        }
        orientation="vertical"
      />
    </div>
  );
};

export default App;
