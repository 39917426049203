

const useGlass = {
    glass: {
        background: 'rgba( 255, 255, 255, 0.25 )',
        secondarybackground:'rgba( 255, 255, 255, 0.25 )',
        boxShadow: '0 5px 20px 0 rgba( 0, 0, 0, 0.90 )',
        backdropFilter: 'blur( 10px )',
        WebkitBackdropFilter: 'blur( 5px )',
        borderRadius: 1,
        // border: '1px solid rgba( 255, 255, 255, 0.18 )'
    }
};

export default useGlass;

