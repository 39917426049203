import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

export default function PaiChart({data}) {
  return (
    <PieChart
      series={[
        {
          data:data, 
          innerRadius: 30,
          outerRadius: 100,
          paddingAngle: 5,
          cornerRadius: 5,
          startAngle: -90,
          endAngle: 280,
          
        },
        
      ]}
      
      width={400}
      height={250}
    />
  );
}