import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Drawer, Grid, IconButton, Typography } from "@mui/material";
import { IconContext } from "react-icons";
import { FaSearch } from "react-icons/fa";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { createEmployee } from "../../../redux/action/admin/employee/create";
import { getEmployee } from "../../../redux/action/admin/employee/get";
import { editSelectData } from "../../../redux/action/admin/leads/editLead";
import { useAuth } from "../../../redux/authContext";
import axios from "axios";
import { url } from "../../../utils/server";
import { getAuthHeaders } from "../../../utils/token";
import Search from "../../../subcomponent/Search";
import theme from "../../../theme/theme";
import UserProfileNav from "../../../components/leads/UserProfileNav";
import EmployeeDataLists from "../../../subcomponent/PayroleTable";

dayjs.extend(require('dayjs/plugin/isSameOrBefore'));

const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  { id: "phoneNumber", numeric: true, disablePadding: false, label: "Phone Number" },
  { id: "baseSalary", numeric: true, disablePadding: false, label: "Base Salary" },
  { id: "netSalary", numeric: true, disablePadding: false, label: "Net Salary" },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
  { id: "action", numeric: true, disablePadding: false, label: "Actions" },
];

const createShell = (id, name, phone, baseSalary, netSalary, status) => {
  return { id, name, phone, baseSalary, netSalary, status };
};

const Payrole = () => {
  const { employeeData } = useAuth();
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.getEmployee);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [saved, setSaved ] = useState()
  const [search, setSearch] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(dayjs());
  const [selectedSalary, setSelectedSalary] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  useEffect(() => {
    fetchData();
    setSaved(false)
  }, [dispatch,saved]); // Fetch initial data on component mount or dispatch change

  const fetchData = async () => {
    setLoading(true);
    try {
      await dispatch(getEmployee());
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleSaved = ()=>{
    setSaved(true)
}

  const handleSave = async (shouldClose, data) => {
    try {
      await dispatch(createEmployee(data));
      if (shouldClose) {
        setOpen(false);
      }
      fetchData(); // Refresh data after saving
    } catch (error) {
      console.error("Error while saving:", error);
    }
  };

  const handleSearch = () => {
    setSearch(!search);
  };

  const handleClose = () => {
    setSearch(false);
  };

  const handleDateTimeChange = (newValue) => {
    setSelectedDateTime(newValue);
  };

  const handleSelectLatestSalary = (salaryHistory, selectedDate) => {
    if (!salaryHistory || salaryHistory.length === 0) {
      return false; // No salary history means "pending"
    }

    // Find the latest salary entry for the selected date
    const latestSalary = salaryHistory.find(salary => {
      const salaryDate = dayjs(salary.PaidDate);
      const currentdate= dayjs(selectedDate)
      return (
        salaryDate.month() === currentdate.month() &&
        salaryDate.year() === currentdate.year()
      );
    });

    return latestSalary?.status ? true : false
  };

  useEffect(() => {
    // Handle salary status when `data` or `selectedDateTime` changes
    const updatedRows = data.map(docs => {
      const name = docs.name || '';
      const phone = docs.phoneNumber || '';
      const baseSalary = docs?.salery?.baseSalary || null;
      const netSalary = docs?.salery?.netSalary || null;
      const isPaid = handleSelectLatestSalary(docs.salaryHistory, selectedDateTime);
      const status = isPaid ? 'paid' : 'pending';
      return createShell(docs._id, name, phone, baseSalary, netSalary, status);
    });

    setSelectedSalary(updatedRows);
  }, [data, selectedDateTime]);
  const handleSearchData = (value) => {
    console.log(value)
    if (value.trim() === "") {
      setFilteredRows(selectedSalary);
    } else {
      const filtered = selectedSalary.filter(row => {
        // Modify this condition according to your search requirements
        return row.name.toLowerCase().includes(value.toLowerCase());
      });
      console.log(filtered, 'dfgs')
      setFilteredRows(filtered);
    }
  };

  return (
    <>
      {loading && <CircularProgress />}
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" ,height:'800px', overflow:'scroll'}}>
        <Grid sx={{ width: "100%", padding: "50px" }}>
          <UserProfileNav />
        </Grid>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          height: '100%',
          overflow:'scroll',
          maxWidth: '100%',
          minWidth: '100%',
        }}>
          {search && <Drawer
            anchor="top"
            open={search}
            sx={{
              background: 'transparent',
              maxWidth: '200px'
            }}
            onClose={handleClose}
            elevation={4}
            
            transitionDuration={100}
          >
            <IconButton
              onClick={handleClose}
              style={{ position: 'absolute', right: '10px', top: '10px' }}
            >
              <MdClose />
            </IconButton>
            <Search onSearch={handleSearchData}/>
          </Drawer>}
          <Grid sx={{
            width:'100%',
            display:'flex',
            flexDirection:'column',
            
            height:'800px'
          }}>
            <Grid sx={{
              width: '100%',
              height: '0px',
              display: 'flex',
              justifyContent: 'flex-start',
              marginLeft:'50px',
              alignContent: 'center',
              gap: 2,
              padding: 2,
              marginTop: '3%'
            }}>
              <Grid>
                <Button onClick={handleSearch} sx={{
                  background: theme.palette.button.secondary,
                  textAlign: 'center',
                  color: theme.palette.text.primary,
                  minWidth: '50%',
                  marginTop:'30px'
                  
                }}>
                  <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                    <FaSearch />
                    Search
                  </IconContext.Provider>
                </Button>
              </Grid>
            
            <Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DemoItem>
                  <div style={{ width: '190px'}}>
                    <DatePicker
                      defaultValue={selectedDateTime}
                      onChange={handleDateTimeChange}
                      views={['year', 'month']}
                      sx={{
                        '& .MuiInputBase-input': { color: 'white' },
                        '& .MuiInputLabel-root': { color: 'white' },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                        '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                      }}
                    />
                  </div>
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
            </Grid>
            </Grid>
            <Grid sx={{ maxWidth: '90%', Width: '90%', marginTop: '100', marginLeft:'50px' , }}>
              <EmployeeDataLists rows={filteredRows.length > 0 ? filteredRows : selectedSalary} saved = {handleSaved} headCells={headCells} reference={'Employees'} data={data} selectedDate={selectedDateTime} />
            </Grid>
          </Grid>

        </Box>
        
      </Box>
    </>
  );
};

export default Payrole;
