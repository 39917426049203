import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  FormControl,
  TextField,
  Button,
  IconButton,
  Autocomplete,
  Snackbar,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import theme from "../../../theme/theme";
import useGlass from "../../../theme/glassEffect";
import { BiLoaderCircle } from "react-icons/bi";
import { useSelector } from "react-redux";
import { employeeSchema } from "../schemaInput/employeeSchema";
import { url } from "../../../utils/server";
import axios from "axios";
import { getAuthHeaders } from "../../../utils/token";
import MuiAlert from '@mui/material/Alert';

function EmployeeAdd({ saved, editData, updated, cancel }) {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: zodResolver(employeeSchema), // Use zodResolver with your schema
  });
  const formRef = useRef(null);
  const { selectedData } = useSelector((state) => state.editLeadData);
  const [department, setDepartments] = useState(['IT', 'SALES', 'DESIGNING',"MANAGEMENT"]);
  const [subRole, setSubRoles] = useState([]);
  const [position, setPositions] = useState(['Head Of Department', 'Product Manager', 'Team Leader', 'Other']);
  const [employees, setEmployees] = useState([]);
  const [juniors, setjuniors] = useState(selectedData?.junior || []);
  const [selectedSenior, setSelectedSenior] = useState([]);
  const [selectedJunior, setSelectedJunior] = useState([]);
  console.log(employees, "dshkasdjkfhjks")
  useEffect(() => {
    const fetchDepartments = async () => {
      setLoading(true);
      const axiosConfig = getAuthHeaders();
      try {
        const response = await axios.get(`${url}/admin/employee/departments`, axiosConfig);
        const { departments, subRoles, positions, employee } = response.data;

         if(departments,subRoles,positions){
          setDepartments(...[department.concat(departments)]);
          setSubRoles(...[subRole.concat(subRoles)]);
          setPositions(...[position.concat(positions)])
         }
        setEmployees(employee)

      } catch (error) {
        console.error('Error fetching departments:', error);
        // Handle error state or display an error message
      } finally {
        setLoading(false);
      }
    };

    fetchDepartments();
  }, []);


  const onSubmit = async (data) => {
  
    const access = data.role === 'Admin' || data.role === 'Manager' || data.role === 'HR' ? 'HRM' : 'none'
    const validetData = {
      name: data.name,
      email: data.email,
      phoneNumber: data.phoneNumber,
      password: data.password,
      dob: data.dob,
      salery: data.salery,
      department: data.department,
      role: data.role,
      subRole: data.subRole,
      city: data.city,
      pinCode: data.pinCode,
      address: data.address,
      position: data.position,
      senior: selectedSenior,
      junior: data.selectedJunior,
      access: access,
    }
    console.log(validetData)
    try {
      setLoading(true);
      if (selectedData) {
        await updated(true, data);
      } else {
        await saved(true, validetData);
      }
      reset();
    } catch (error) {
      console.error("Validation error:", error.errors);
      alert("Form validation failed. Please check the errors and try again.");
    } finally {
      setLoading(false);
    }
  };


  const handleOnChange = (event, values) => {
    setjuniors(values);
    setSelectedJunior(prevSelected => [...prevSelected, values._id]);
  };


  const handleCancel = () => {
    cancel(); // Call the cancel function passed from props
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        position: "relative",
        background: "transparent",
        boxShadow: useGlass.glass.boxShadow,
        backdropFilter: useGlass.glass.backdropFilter,
        WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
        borderRadius: useGlass.glass.borderRadius,
        border: useGlass.glass.border,
        padding: "50px",
      }}
    >
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <FormControl sx={{ width: "100%", padding: "20px" }}>
          <Grid container spacing={2} justifyContent="center" alignItems="center" gap={1}>
            {/* Name */}
            <Grid item xs={12} md={4} sx={{
              background: 'white',
              borderRadius: 1
            }}>
              <TextField
                autoFocus
                id="name"
                name="name"
                label="Name*"
                
                placeholder="Enter name"
                type="text"
                fullWidth
                {...register("name", { required: "Name is required" })}
                error={!!errors.name}
                helperText={errors.name?.message}
                defaultValue={selectedData?.name || ""}
                InputLabelProps={{
                  style: { color: 'black' } // Change label color to black
                }}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12} md={4} sx={{
              background: 'white',
              borderRadius: 1
            }}>
              <TextField
                id="email"
                name="email"
                label="Email*"
                placeholder="Enter email"
                type="email"
                fullWidth
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
                error={!!errors.email}
                helperText={errors.email?.message}
                defaultValue={selectedData?.email || ""}
                InputLabelProps={{
                  style: { color: 'black' } // Change label color to black
                }}
              />
            </Grid>

            {/* Phone Number */}
            <Grid item xs={12} md={4} sx={{
              background: 'white',
              borderRadius: 1
            }}>
              <TextField
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number*"
                placeholder="Enter phone number"
                type="tel"
                fullWidth
                {...register("phoneNumber", {
                  required: "Phone number is required",
                  minLength: {
                    value: 10,
                    message: "Phone number must be at least 10 digits",
                  },
                })}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message}
                defaultValue={selectedData?.phoneNumber || ""}
                InputLabelProps={{
                  style: { color: 'black' } // Change label color to black
                }}
              />
            </Grid>

            {/* Password */}
            <Grid item xs={12} md={4} sx={{
              background: 'white',
              borderRadius: 1
            }}>
              <TextField
                id="password"
                name="password"
                label="Password"
                placeholder="Enter password"
                type="password"
                fullWidth
                {...register("password")}
                defaultValue={selectedData?.password || ""}
                InputLabelProps={{
                  style: { color: 'black' } // Change label color to black
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} sx={{
              background: 'white',
              borderRadius: 1
            }}>
              <TextField
                id="dob"
                name="dob"
                placeholder="Enter DOB"
                type="date"
                fullWidth
                label='DOB'
                {...register("dob")}
                defaultValue={selectedData?.dob || ""}
                InputLabelProps={{
                  shrink: true, // Ensures the label doesn't overlap when typing
                  style: { color: 'black' } // Change label color to black
                }}
                InputProps={{
                  placeholder: 'DOB', // Clear placeholder text
                }}
                
              />
            </Grid>
            

            {/* Department Name */}
            <Grid item xs={12} md={4} sx={{
              background: 'white',
              borderRadius: 1
            }}>
              <Autocomplete
                freeSolo
                id="department"
                name="department"
                 // Use null for defaultValue
                options={department?.map((dept) => ({ label: dept }))} // Ensure options is an array
                getOptionLabel={(option) => option.label || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Department Name"
                    placeholder="Enter department"
                    {...register("department", { required: "Department is required" })}
                    error={!!errors.department}
                    helperText={errors.department?.message}
                    InputLabelProps={{
                      style: { color: 'black' } // Change label color to black
                    }}
                  />
                )}
              />
            </Grid>

            {/* Role */}
            <Grid item xs={12} md={4} sx={{
              background: 'white',
              borderRadius: 1
            }}>
              <Autocomplete
                id="role"
                name="role"
                options={["Admin", "Manager", "Employee","HR"]} // Replace with your options
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Role"
                    placeholder="Select role"
                    {...register("role", { required: "Role is required" })}
                    error={!!errors.role}
                    helperText={errors.role?.message}
                    defaultValue={selectedData?.role || ""}
                    InputLabelProps={{
                      style: { color: 'black' } // Change label color to black
                    }}
                  />
                )}
              />
            </Grid>

            {/* Sub Role */}
            <Grid item xs={12} md={4} sx={{
              background: 'white',
              borderRadius: 1
            }}>
              <Autocomplete
                freeSolo
                id="subRole"
                name="subRole"
                options={subRole?.map((subRole) => ({ label: subRole }))} // Assuming subRoles is an array of strings
                getOptionLabel={(option) => option.label || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sub Role"
                    placeholder="Enter sub role"
                    {...register("subRole")}
                    defaultValue={selectedData?.subRole || ""}
                    InputLabelProps={{
                      style: { color: 'black' } // Change label color to black
                    }}
                  />
                )}
              />
            </Grid>

            {/* City */}
            <Grid item xs={12} md={4} sx={{
              background: 'white',
              borderRadius: 1
            }}>
              <TextField
                id="city"
                name="city"
                label="City"
                placeholder="Enter city"
                type="text"
                fullWidth
                {...register("city", { required: "City is required" })}
                error={!!errors.city}
                helperText={errors.city?.message}
                defaultValue={selectedData?.city || ""}
                InputLabelProps={{
                  style: { color: 'black' } // Change label color to black
                }}
              />
            </Grid>

            {/* Pin Code */}
            <Grid item xs={12} md={4} sx={{
              background: 'white',
              borderRadius: 1
            }}>
              <TextField
                id="pinCode"
                name="pinCode"
                label="Pin Code"
                placeholder="Enter pin code"
                type="text"
                fullWidth
                {...register("pinCode", { required: "Pin code is required" })}
                error={!!errors.pinCode}
                helperText={errors.pinCode?.message}
                defaultValue={selectedData?.pinCode || ""}
                InputLabelProps={{
                  style: { color: 'black' } // Change label color to black
                }}
              />
            </Grid>

            {/* Address */}
            <Grid item xs={12} md={4} sx={{
              background: 'white',
              borderRadius: 1
            }}>
              <TextField
                id="address"
                name="address"
                label="Address"
                placeholder="Enter address"
                type="text"
                fullWidth
                {...register("address", { required: "Address is required" })}
                error={!!errors.address}
                helperText={errors.address?.message}
                defaultValue={selectedData?.address || ""}
                InputLabelProps={{
                  style: { color: 'black' } // Change label color to black
                }}
              />
            </Grid>

            {/* Position */}
            <Grid item xs={12} md={4} sx={{
              background: 'white',
              borderRadius: 1
            }}>
              <Autocomplete
                freeSolo
                id="position"
                name="position"
                options={position?.map((pos) => ({ label: pos }))} // Assuming positions is an array of strings
                getOptionLabel={(option) => option.label || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Position"
                    placeholder="Enter position"
                    {...register("position")}
                    defaultValue={selectedData?.position || ""}
                    InputLabelProps={{
                      style: { color: 'black' } // Change label color to black
                    }}
                  />
                )}
              />
            </Grid>

            {/* Senior */}
            <Grid item xs={12} md={8} sx={{
              background: 'white',
              borderRadius: 1
            }}>
              <Autocomplete
                id="senior"
                name="senior"
                options={employees?.map((employee) => ({
                  label: `${employee.name} (${employee.subRole})`,
                  _id: employee._id
                }))}
                getOptionLabel={(option) => option.label}
                onChange={(event, value) => {
                  setSelectedSenior(value ? value._id : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Senior"
                    placeholder="Enter senior"
                    {...register("senior")}
                    defaultValue={selectedData?.senior || null}
                    InputLabelProps={{
                      style: { color: 'black' } // Change label color to black
                    }}
                  />
                )}
              />
            </Grid>

            {/* Junior */}
            <Grid item xs={12} md={8} sx={{
              background: 'white',
              borderRadius: 1
            }}>
              <Autocomplete
                id="junior"
                name="junior"
                options={employees.map((employee) => ({
                  label: `${employee.name} (${employee.subRole})`,
                  _id: employee._id
                }))}
                getOptionLabel={(option) => option.label || ''}

                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Junior"
                    InputLabelProps={{
                      style: { color: 'black' } // Change label color to black
                    }}
                    placeholder="Enter junior"
                  />
                )}
                multiple
                value={juniors}
                onChange={handleOnChange}

              />
            </Grid>
          </Grid>

          {/* Submit and Cancel Buttons */}
          <Grid item xs={12} sx={{ textAlign: "center", marginTop: 2 }}>
            {loading ? (
              <IconButton>
                <BiLoaderCircle />
              </IconButton>
            ) : (
              <>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ background: theme.palette.button.secondary, marginRight: 2 }}
                >
                  {selectedData ? "Update" : "Submit"}
                </Button>
                <Button
                  variant="contained"
                  sx={{ background: theme.palette.button.secondary }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            )}
          </Grid>
        </FormControl>
        
      </form>
    </Box>
  );
}

export default EmployeeAdd;
