import React, { useState, useEffect } from 'react';
import { Container, Box, List, ListItem, ListItemText, Divider, FormControl,Toolbar, Typography, TextField,Button } from '@mui/material';
import useGlass from '../../theme/glassEffect';

import { useAuth } from '../../redux/authContext';
import CallForm from './callDetails';
import BookingForm from './bookingDetails';
import Documentation from './Documentation';
import SessionForm from './SessionForm';
import PaymentForm from './PaymentForm';


const ActivityNav = ({saved}) => {
  const [currentForm, setCurrentForm] = useState('call'); // Initialize with 'call'

  useEffect(() => {
    // Set the current form to 'call' when component mounts
    setCurrentForm('call');
  }, []);

  const handleFormChange = (formName) => {
    setCurrentForm(formName);
  };
  const { employeeData } = useAuth(); 

const handlesave = ()=>{

    saved(true)
}

  

 






  return (
    <Container sx={{ height: '110%' ,width:'100%',   borderRadius:'10px', overflow:'scroll'
    }}>
      <Box sx={{
        
        display: 'flex',
        background:'none',
        overflow:'hidden',
        height: '100%',
        width:'100%'
      }}>
        <Box sx={{ width: '150px', borderRight: '1px solid #ccc',background:'white',borderTopLeftRadius:'5px', }}>
          
          
        
          <List sx={{background:'white',width:'100%',borderRadius:'10px',}}>
            {['Call', 'Booking' ,'Session',  'Documentation', 'Payment'].map((text) => (
              <ListItem
                button
                key={text}
                onClick={() => handleFormChange(text.toLowerCase())}
                selected={text.toLowerCase() === currentForm}
                sx={{
                  pl: '30px',
                  width:'100%',
                  backgroundColor: text.toLowerCase() === currentForm ? '#fff' : '#fff',
                  '&:hover': { backgroundColor: '#fff' } // Ensure hover effect shows white background
                }}
              >
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
         
        </Box>

        <Box sx={{ flexGrow: 1, bgcolor: 'transparent', p: 0, borderLeft: '1px solid #ccc',overflow:'scroll'}}>
          <Toolbar />
          {currentForm === 'call' && <CallForm saved={handlesave}/>} 
          {currentForm === 'session' && <BookingForm saved={handlesave}/>}{/* Render CallForm if currentForm is 'call' */}
          {currentForm === 'booking' && <SessionForm saved={handlesave}/>} {/* Render SessionForm if currentForm is 'session' */}
           {/* Render BookingForm if currentForm is 'booking' */}
          {currentForm === 'documentation' && <Documentation saved={handlesave}/>} {/* Render DocumentationForm if currentForm is 'documentation' */}
          {currentForm === 'payment' && <PaymentForm saved={handlesave}/>} {/* Render PaymentForm if currentForm is 'payment' */}
        </Box>
      </Box>
    </Container>
  );
};

export default ActivityNav;
