import React, { useEffect, useState } from "react";
import LeadData from "../../subcomponent/DataTable";
import Table, { createData } from "../../subcomponent/DataTable";
import { Box, Button, Drawer, Grid, TextField, Typography } from "@mui/material";
import useGlass from "../../theme/glassEffect";
import Search from "../../subcomponent/Search";
import { IconContext } from "react-icons";
import { FaSearch } from "react-icons/fa";
import theme from "../../theme/theme";
import DataLists from "../../subcomponent/DataTable";
import EmployeeAdd from "./SaveData/Employee";
import { leadSchema } from "./schemaInput/leadSchema";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getAuthHeaders } from "../../utils/token";
import { url } from "../../utils/server";
import { getLeadAction } from "../../redux/action/admin/leads/getLead";
import { useAuth } from "../../redux/authContext";
import CustomerTable from "../../subcomponent/CustomerTable";
import UserProfileNav from "../leads/UserProfileNav";

// import { leadFields } from "./inputFields/addLeads";




const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "phoneNumber",
    numeric: true,
    disablePadding: false,
    label: "Phone Number",
  },
  {
    id: "projectName",
    numeric: true,
    disablePadding: false,
    label: "Project Name",
  },
  {
    id: "totalAmount",
    numeric: true,
    disablePadding: false,
    label: "Total Amount",
  },
  {
    id: "pendingAmount",
    numeric: true,
    disablePadding: false,
    label: "Pending Amount",
  },
  {
    id: "projectDone",
    numeric: true,
    disablePadding: false,
    label: "Module Complete",
  },
  

 
];
function CoustomerTable() {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const {leadsData} = useSelector((state)=>state.getLeadData)
  const [coustomer,setCoustomer] = useState([])
  const [filteredRows, setFilteredRows] = useState([]);
  const [leadDetail,setLeadDetail] = useState()
  const [module, setModule] = useState()
  const axiosConfig  = getAuthHeaders()
  const dispatch = useDispatch()
  const {employeeData} = useAuth()
  const getCoustomer = async()=>{
    try{
      const coustomer = await axios.get(`${url}/admin/coustomer/get`,axiosConfig)
      const coustomerData = coustomer.data; // Assuming the response contains the relevant data
       
       setCoustomer(coustomerData.leads)
       setLeadDetail(coustomerData.leadDetail)
       setModule(coustomerData.modules)
      console.log(leadDetail,'hh2')
    }
    catch(err){
      console.log(err)
    }
    
  } 
  useEffect(()=>{
    const employeeIds = [employeeData._id]
    employeeData.junior?.map((doc)=>{
        employeeIds.push(doc)
    })
    getCoustomer()
    dispatch(getLeadAction(employeeIds))

  },[employeeData])
  function createShell(id, name, email, phone, owner, company, workAddress, leadType) {
    return { id, name, email, phone, owner, company, workAddress, leadType };
}
console.log(coustomer)
const rows = coustomer.map((doc) => {
  console.log(doc,'hwloo')
  const completedModulesCount = doc.modules.filter(item => item.moduleStage === 'completed').length;
 
    let data = doc.leads[0]
     
      
      const matchingLeadDetail = leadDetail.find(detail => detail.lead === doc._id);
      const name = data.name || '';
      const email = data.email || '';
      const phone = data.phoneNumber || '';
      const owner = data.projectName || '';
      const company = doc.totalPayment || '';
      const workAddress = doc.pendingPayment || '';
      const leadType = completedModulesCount || 0; // Set to 0 if no completed modules

      return createShell(doc._id, name, email, phone, owner, company, workAddress, leadType);
  
});




  const handleSave = ()=>{
     setOpen(!open)
  }
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const handleSearch = ()=>{
    setSearch(!search)
  }
  const handleSearchData = (value) => {
    console.log(value)
    if (value.trim() === "") {
      setFilteredRows(rows);
    } else {
      const filtered = rows.filter(row => {
        // Modify this condition according to your search requirements
        return row.name.toLowerCase().includes(value.toLowerCase());
      });
      console.log(filtered, 'dfgs')
      setFilteredRows(filtered);
    }
  };
  return (
    <>
      <Box   sx={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      minHeight:'800px'
     
    }}>
      <Grid sx={{ width: "100%", padding: "50px" }}>
          <UserProfileNav />
        </Grid>
      <Box sx={{
         display:'flex',
         justifyContent:'center',
         flexDirection:'column',
         alignItems:'center',
         gap:2,
         marginLeft:'-20px',
         minHeight:'800px',
         maxWidth:'100%',
         minWidth:'100%',
      }}>
      {search && <Search onSearch={handleSearchData}/>}
        <Grid flex={1}>
          <Grid sx={{
            width:'100%',
             height:'50px',
             display:'flex',
             justifyContent:'space-between',
             alignContent:'center',
             gap:1,
             padding:2,
             marginTop:'3%'
          }}>
            <Grid >
          
                <Button onClick={handleSearch} sx={{
                   background:theme.palette.button.secondary,
                   textAlign:'center',
                   color:theme.palette.text.primary,
                   minWidth:'50%',
                  
                   
                }}>
                <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                    
                    <FaSearch/>
                     Search
                    </IconContext.Provider>
                </Button>
            </Grid>
            
          </Grid>
          <Grid
           sx={{
            maxWidth:'100%',
            minWidth:'100%',
           }}
          >
           <CustomerTable rows={filteredRows.length > 0 ? filteredRows : rows} headCells={headCells} refrence={'Customer'} data={coustomer}/>
          </Grid>
        </Grid>
      </Box>
      </Box>
    </>
  );
}

export default CoustomerTable;
