import React, { useState } from "react";
import { Grid, Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Tooltip } from "@mui/material";
import { MdHolidayVillage, MdLogout, MdMenu, MdPayment, MdSupervisorAccount, MdTimeToLeave } from "react-icons/md";
import { FaHandHolding, FaMoneyCheck } from "react-icons/fa";
import { BiHeading, BiNavigation, BiSolidDashboard } from "react-icons/bi";
import { IconContext } from "react-icons";
import { GiOpenFolder, GiTeamDowngrade, GiTeapotLeaves } from "react-icons/gi";
import { GrDrawer, GrUserWorker } from "react-icons/gr";
import { MdVideoCameraFront } from "react-icons/md";
import AdminProfile from "../../subcomponent/AdminProfile";
import CustomTreeItem from "../../subcomponent/CustomTree";
import { BsMicrosoftTeams, BsOpencollective, BsToggle2Off } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Label from "@mui/icons-material/Label";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { styled, useTheme, alpha } from "@mui/material/styles";
import theme from "../../theme/theme";
import useGlass from "../../theme/glassEffect";
import { useAuth } from "../../redux/authContext";
import { Logout } from "@mui/icons-material";
// Custom function icon for SimpleTreeView
function EndIcon() {
  return <div style={{ width: 24 }} />;
}



const Nav = () => {
  const {employeeData} = useAuth()
  console.log(employeeData)
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate()
  const handleLogOut = ()=>{
    localStorage.removeItem('employeeData')
    navigate('/login')
}
  const handleDrawerOpen = () => {
    setIsOpen(true);
  };
  const location = useLocation();
  const handleDrawerClose = () => {
    setIsOpen(false);
  };
  const isActive = (path) => {
    return location.pathname === path;
  };
  return (
    <IconContext.Provider
      value={{ color: theme.palette.text.primary, className: "global-class-name" }}
    >
      <Grid container maxWidth={250} width={25}>
        
        {!isOpen && (
          <Grid
            item
            sx={{
              width: "80px",
              backgroundColor: useGlass.glass.background,
              boxShadow: useGlass.glass.boxShadow,
              backdropFilter: useGlass.glass.backdropFilter,
              WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
              borderRadius: useGlass.glass.borderRadius,
              border: useGlass.glass.border,
              textAlign: "center",

              borderRight: `1px solid ${theme.palette.divider}`,
              zIndex: 1000,
              position: "fixed",
              top: 0,
              bottom: 0,
              left: -6,
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              paddingTop: "50px",
              // Adjust as needed based on your header height
            }}
          >
            {/* Icons for each route */}
            <List sx={{ padding: '0px', marginTop: '50px' }}>
      <ListItem component={Link} to="/" sx={{ padding: '10px', display: 'block', borderRadius: '10px', backgroundColor: isActive('/') ? 'white' : 'transparent' }}>
        <Tooltip title='Lead Dashboard'>
        <ListItemIcon sx={{ marginLeft: '25px', color: 'green' }}>
          <BiSolidDashboard color= {theme.palette.button.secondary}/>
        </ListItemIcon>
        </Tooltip>
       
      </ListItem>
      <ListItem component={Link} to="/leads" sx={{ padding: '10px', display: 'block', borderRadius: '10px', backgroundColor: isActive('/leads') ? 'white' : 'transparent' }}>
      <Tooltip title='Lead Data'><ListItemIcon sx={{ marginLeft: '25px' }}>
          <GiTeamDowngrade color= {theme.palette.button.secondary}/>
        </ListItemIcon></Tooltip>
      </ListItem>
      <ListItem component={Link} to="/crm/dashboard" sx={{ padding: '10px', display: 'block', borderRadius: '10px', backgroundColor: isActive('/crm/dashboard') ? 'white' : 'transparent' }}>
      <Tooltip title='Customer Dashboard'> <ListItemIcon sx={{ marginLeft: '25px' }}>
          <BiSolidDashboard color= {theme.palette.button.secondary}/>
        </ListItemIcon></Tooltip>
      </ListItem>
      <ListItem component={Link} to="/crm/customer" sx={{ padding: '10px', display: 'block', borderRadius: '10px', backgroundColor: isActive('/crm/customer') ? 'white' : 'transparent' }}>
      <Tooltip title='Customer Data'>  <ListItemIcon sx={{ marginLeft: '25px' }}>
          <MdSupervisorAccount color= {theme.palette.button.secondary}/>
        </ListItemIcon></Tooltip>
      </ListItem>
      <ListItem component={Link} to="/crm/transition" sx={{ padding: '10px', display: 'block', borderRadius: '10px', backgroundColor: isActive('/crm/transition') ? 'white' : 'transparent' }}>
      <Tooltip title='Customer Transition'>  <ListItemIcon sx={{ marginLeft: '25px' }}>
          <FaMoneyCheck color= {theme.palette.button.secondary}/>
        </ListItemIcon></Tooltip>
      </ListItem>
      {employeeData?.access === 'HRM' && (
        <>
          <ListItem component={Link} to="/hrm/dashboard" sx={{ padding: '10px', display: 'block', borderRadius: '10px', backgroundColor: isActive('/hrm/dashboard') ? 'white' : 'transparent' }}>
          <Tooltip title='Employee Manage Dashboard'> <ListItemIcon sx={{ marginLeft: '25px' }}>
              <BiSolidDashboard color= {theme.palette.button.secondary}/>
            </ListItemIcon></Tooltip>
          </ListItem>
          <ListItem component={Link} to="/hrm/team" sx={{ padding: '10px', display: 'block', borderRadius: '10px', backgroundColor: isActive('/hrm/team') ? 'white' : 'transparent' }}>
          <Tooltip title='Team'><ListItemIcon sx={{ marginLeft: '25px' }}>
              <BsMicrosoftTeams color= {theme.palette.button.secondary}/>
            </ListItemIcon></Tooltip>
          </ListItem>
          <ListItem component={Link} to="/hrm/employee" sx={{ padding: '10px', display: 'block', borderRadius: '10px', backgroundColor: isActive('/hrm/employee') ? 'white' : 'transparent' }}>
          <Tooltip title='Employee Manage Data'> <ListItemIcon sx={{ marginLeft: '25px' }}>
              <GrUserWorker color= {theme.palette.button.secondary}/>
            </ListItemIcon></Tooltip>
          </ListItem>
          <ListItem component={Link} to="/hrm/payrole" sx={{ padding: '10px', display: 'block', borderRadius: '10px', backgroundColor: isActive('/hrm/payrole') ? 'white' : 'transparent' }}>
          <Tooltip title='Employee Payrole'><ListItemIcon sx={{ marginLeft: '25px' }}>
              <MdPayment color= {theme.palette.button.secondary}/>
            </ListItemIcon></Tooltip>
          </ListItem>
          <ListItem component={Link} to="/hrm/leave-manage" sx={{ padding: '10px', display: 'block', borderRadius: '10px', backgroundColor: isActive('/hrm/leave-manage') ? 'white' : 'transparent' }}>
          <Tooltip title='Employee Leave'><ListItemIcon sx={{ marginLeft: '25px' }}>
              <MdHolidayVillage color= {theme.palette.button.secondary}/>
            </ListItemIcon></Tooltip>
          </ListItem>
             
              </>

              )  }
               <ListItem component={Link} to="/hrm/meetings" sx={{ padding: "10px", display: "block",borderRadius: '10px',backgroundColor: isActive('/hrm/meetings') ? 'white' : 'transparent' }}>
               <Tooltip title='Meetings'><ListItemIcon sx={{marginLeft:'25px'}}>
                  <MdVideoCameraFront color= {theme.palette.button.secondary}/>
                </ListItemIcon></Tooltip>
              </ListItem>
              <ListItem  sx={{ padding: "10px", display: "block",borderRadius: '10px',}}>
               <Tooltip title='logout'><ListItemIcon sx={{marginLeft:'25px'}}>
                <IconButton onClick={handleLogOut} sx={{marginLeft:'-10px',}}>
                <MdLogout color= {theme.palette.button.secondary} size={20}/>
                </IconButton>
                 
                </ListItemIcon></Tooltip>
              </ListItem>
              
            </List>
          </Grid>
        )}

        {/* Drawer Component */}
        <Drawer
          variant="temporary"
          open={isOpen}
          onClose={handleDrawerClose}
          sx={{
            width: "22%",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "22%",
              backgroundColor: useGlass.glass.background,
              boxShadow: useGlass.glass.boxShadow,
              backdropFilter: useGlass.glass.backdropFilter,
              WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
              borderRadius: useGlass.glass.borderRadius,
              border: useGlass.glass.border,
            },
          }}
        >
          <Grid
            sx={{
              padding: 3,
              display: "flex",
              flexDirection: "column",
              gap: 4,
              maxHeight: "650px",
              overflow: "scroll",
            }}
          >
            {/* Admin Profile */}
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 100,
                marginTop: 4,
              }}
            >
              <Link to={`/${employeeData?._id}`}><AdminProfile /></Link>
            </Grid>
            {/* Navigation TreeView */}
            <Grid item>
              <SimpleTreeView
                aria-label="gmail"
                defaultExpandedItems={["1"]}
                defaultSelectedItems="5"
                slots={{
                  expandIcon: ArrowRightIcon,
                  collapseIcon: ArrowDropDownIcon,
                  endIcon: EndIcon,
                }}
                sx={{ flexGrow: 1, maxWidth: 400, color: theme.palette.text.primary }}
              >
                {/* LMS Section */}
                <CustomTreeItem itemId="1" label="LMS" labelIcon={Label}>
                  <Link to={"/"}>
                    <CustomTreeItem
                      itemId="2"
                      label="Dashboard"
                      labelIcon={() => <BiSolidDashboard />}
                      color={theme.palette.text.primary}
                      bgColor={theme.palette.button.secondary}
                      colorForDarkMode="#B8E7FB"
                      bgColorForDarkMode={alpha("#00b4ff", 0.2)}
                    />
                  </Link>
                  <Link to={"/leads"}>
                    <CustomTreeItem
                      itemId="3"
                      label="Leads"
                      labelIcon={() => <GiTeamDowngrade />}
                      color={theme.palette.text.primary}
                      bgColor={theme.palette.button.secondary}
                      colorForDarkMode="#B8E7FB"
                      bgColorForDarkMode={alpha("#00b4ff", 0.2)}
                    />
                  </Link>
                </CustomTreeItem>

                {/* CRM Section */}
                <CustomTreeItem itemId="4" label="CRM" labelIcon={Label}>
                  <Link to={"/crm/dashboard"}>
                    <CustomTreeItem
                      itemId="5"
                      label="Dashboard"
                      labelIcon={() => <BiSolidDashboard />}
                      color={theme.palette.text.primary}
                      bgColor={theme.palette.button.secondary}
                      colorForDarkMode="#B8E7FB"
                      bgColorForDarkMode={alpha("#00b4ff", 0.2)}
                    />
                  </Link>
                  <Link to={"/crm/customer"}>
                    <CustomTreeItem
                      itemId="6"
                      label="Customer"
                      labelIcon={() => <MdSupervisorAccount />}
                      color={theme.palette.text.primary}
                      bgColor={theme.palette.button.secondary}
                      colorForDarkMode="#B8E7FB"
                      bgColorForDarkMode={alpha("#00b4ff", 0.2)}
                    />
                  </Link>
                  <Link to={"/crm/transition"}>
                    <CustomTreeItem
                      itemId="7"
                      label="Transitions"
                      labelIcon={() => <FaMoneyCheck />}
                      color={theme.palette.text.primary}
                      bgColor={theme.palette.button.secondary}
                      colorForDarkMode="#B8E7FB"
                      bgColorForDarkMode={alpha("#00b4ff", 0.2)}
                    />
                  </Link>
                </CustomTreeItem>

                {/* HRM Section */}
                {employeeData?.access ==='HRM' && 
                <>
                <CustomTreeItem itemId="8" label="HRM" labelIcon={Label}>
                  <Link to={"/hrm/dashboard"}>
                    <CustomTreeItem
                      itemId="9"
                      label="Dashboard"
                      labelIcon={() => <BiSolidDashboard />}
                      color={theme.palette.text.primary}
                      bgColor={theme.palette.button.secondary}
                      colorForDarkMode="#B8E7FB"
                      bgColorForDarkMode={alpha("#00b4ff", 0.2)}
                    />
                  </Link>
                  <Link to={"/hrm/team"}>
                    <CustomTreeItem
                      itemId="10"
                      label="Teams"
                      labelIcon={() => <BsMicrosoftTeams />}
                      color={theme.palette.text.primary}
                      bgColor={theme.palette.button.secondary}
                      colorForDarkMode="#B8E7FB"
                      bgColorForDarkMode={alpha("#00b4ff", 0.2)}
                    />
                  </Link>
                  <Link to={"/hrm/employee"}>
                    <CustomTreeItem
                      itemId="11"
                      label="Employee"
                      labelIcon={() => <GrUserWorker />}
                      color={theme.palette.text.primary}
                      bgColor={theme.palette.button.secondary}
                      colorForDarkMode="#B8E7FB"
                      bgColorForDarkMode={alpha("#00b4ff", 0.2)}
                    />
                  </Link>
                  <Link to={"/hrm/payrole"}>
                    <CustomTreeItem
                      itemId="15"
                      label="Payrole"
                      labelIcon={() => <MdPayment />}
                      color={theme.palette.text.primary}
                      bgColor={theme.palette.button.secondary}
                      colorForDarkMode="#B8E7FB"
                      bgColorForDarkMode={alpha("#00b4ff", 0.2)}
                    />
                  </Link>
                  <Link to={"/hrm/leave-manage"}>
                    <CustomTreeItem
                      itemId="16"
                      label="Leave Management"
                      labelIcon={() => <MdHolidayVillage />}
                      color={theme.palette.text.primary}
                      bgColor={theme.palette.button.secondary}
                      colorForDarkMode="#B8E7FB"
                      bgColorForDarkMode={alpha("#00b4ff", 0.2)}
                    />
                  </Link>
                 
                </CustomTreeItem>
              
                <Link to={"/hrm/meetings"}>
                    <CustomTreeItem
                      itemId="13"
                      label="Meetings"
                      labelIcon={() => <MdVideoCameraFront />}
                      color={theme.palette.text.primary}
                      bgColor={theme.palette.button.secondary}
                      colorForDarkMode="#B8E7FB"
                      bgColorForDarkMode={alpha("#00b4ff", 0.2)}
                    />
                  </Link>
                
                </>}
                <CustomTreeItem
                
                      itemId="20"
                      label="Logout"
                      labelIcon={() => <MdLogout onClick={handleLogOut}/>}
                      color={theme.palette.text.primary}
                      bgColor={theme.palette.button.secondary}
                      colorForDarkMode="#B8E7FB"
                      bgColorForDarkMode={alpha("#00b4ff", 0.2)}
                    />
              </SimpleTreeView>
            </Grid>
          </Grid>
        </Drawer>

        {/* Main Content */}
        <Grid item xs sx={{zIndex:999999,maxWidth:'20%',position:'sticky',top:'20px'}}>
        <IconButton sx={{marginLeft:'20px',position:'sticky',top:'0px'} } onClick={handleDrawerOpen}>
                  <MdMenu color="black"/>
              </IconButton >
          {/* <button >Toggle Drawer</button> */}
          
        </Grid>
      </Grid>
    </IconContext.Provider>
  );
};

export default Nav;
