export const ADD_LEAD_REQUEST = 'ADD_LEAD_REQUEST'
export const ADD_LEAD_SUCCESS = 'ADD_LEAD_SUCCESS'
export const ADD_LEAD_FAIL= 'ADD_LEAD_FAIL'

export const GET_LEAD_REQUEST = 'GET_LEAD_REQUEST'
export const GET_LEAD_SUCCESS = 'GET_LEAD_SUCCESS'
export const GET_LEAD_FAIL= 'GET_LEAD_FAIL'

export const DELETE_LEAD_REQUEST = 'DELETE_LEAD_REQUEST'
export const DELETE_LEAD_SUCCESS = 'DELETE_LEAD_SUCCESS'
export const DELETE_LEAD_FAIL= 'DELETE_LEAD_FAIL'

export const SELECT_DATA_REQUEST = 'SELECT_DATA_REQUEST'
export const SELECT_DATA_SUCCESS = 'SELECT_DATA_SUCCESS'
export const SELECT_DATA_FAIL= 'SELECT_DATA_FAIL'

export const UPDATE_DATA_REQUEST = 'UPDATE_DATA_REQUEST'
export const UPDATE_DATA_SUCCESS = 'UPDATE_DATA_SUCCESS'
export const UPDATE_DATA_FAIL= 'UPDATE_DATA_FAIL'


export const CATCH_ERROR = 'CATCH_ERROR';