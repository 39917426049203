import React from 'react'
import TransitionTable from '../../../components/navbar/Transition'


function Transitions() {
  return (
    <>
     <TransitionTable/>
    </>
  )
}

export default Transitions