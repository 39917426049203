import { Box, Grid, Typography, IconButton, TextField, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { BiEdit, BiSave, BiPlus, BiTrash } from 'react-icons/bi';
import React, { useState } from 'react';
import useGlass from '../theme/glassEffect';

function Deduction({ id, data,saved }) {
    const filterData = data.find(item => item._id === id);
    const deductions = filterData?.salery?.deduction || [];
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState(deductions);

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedDeductions = formData.map((deduction, i) => (
            i === index ? { ...deduction, [name]: value } : deduction
        ));
        setFormData(updatedDeductions);
    };

    const handleSave = () => {
        localStorage.setItem('deduction', JSON.stringify(formData));
        saved(true)
        setIsEditing(false);
    };

    const handleAddRow = () => {
        setFormData([...formData, { deductionName: '', deductionType: '', deductionAmount: '' }]);
    };

    const handleDeleteRow = (index) => {
        const updatedDeductions = formData.filter((_, i) => i !== index);
        setFormData(updatedDeductions);
    };

    return (
        <Box>
            <Grid sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                width: '100%',
                gap: '10px',
                backgroundColor: useGlass.glass.background,
                boxShadow: useGlass.glass.boxShadow,
                backdropFilter: useGlass.glass.backdropFilter,
                WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
                borderRadius: useGlass.glass.borderRadius,
                border: useGlass.glass.border,
            }}>
                <h2 style={{ marginLeft: '20px' }}>Deduction</h2>
                <Tooltip title={isEditing ? 'Save' : 'Edit'}>
                        <IconButton onClick={isEditing ? handleSave : () => setIsEditing(true)} sx={{
                            position: 'absolute',
                            right: '50px'
                        }}>
                            {isEditing ? <BiSave color='blue' /> : <BiEdit color='green' />}
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Add Row">
                        <IconButton onClick={handleAddRow} sx={{
                            position: 'absolute',
                            right: '0'
                        }}>
                            <BiPlus color='blue' />
                        </IconButton>
                    </Tooltip>
                <Grid padding={'30px'} position={'relative'}>


                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Deduction Name</TableCell>
                                    <TableCell>Deduction Type</TableCell>
                                    <TableCell>Deduction Amount</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formData.map((deduction, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {isEditing ? (
                                                <TextField
                                                    name="deductionName"
                                                    value={deduction.deductionName || ''}
                                                    onChange={(e) => handleChange(e, index)}
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: { padding: '0', fontSize: 'inherit', color: 'inherit' }
                                                    }}
                                                />
                                            ) : (
                                                deduction.deductionName
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {isEditing ? (
                                                <TextField
                                                    name="deductionType"
                                                    value={deduction.deductionType || ''}
                                                    onChange={(e) => handleChange(e, index)}
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: { padding: '0', fontSize: 'inherit', color: 'inherit' }
                                                    }}
                                                />
                                            ) : (
                                                deduction.deductionType
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {isEditing ? (
                                                <TextField
                                                    name="deductionAmount"
                                                    value={deduction.deductionAmount || ''}
                                                    onChange={(e) => handleChange(e, index)}
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: { padding: '0', fontSize: 'inherit', color: 'inherit' }
                                                    }}
                                                />
                                            ) : (
                                                deduction.deductionAmount
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {isEditing && (
                                                <IconButton onClick={() => handleDeleteRow(index)}>
                                                    <BiTrash color='red' />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Deduction;
