import React, { useEffect, useState } from "react";
import LeadData from "../../subcomponent/DataTable";
import Table, { createData } from "../../subcomponent/DataTable";
import { Box, Button, CircularProgress, Drawer, Grid, IconButton, Paper, Slide, Snackbar, TextField, Typography } from "@mui/material";
import useGlass from "../../theme/glassEffect";
import Search from "../../subcomponent/Search";
import { IconContext } from "react-icons";
import { FaSearch } from "react-icons/fa";
import MuiAlert from '@mui/material/Alert';
import theme from "../../theme/theme";
import DataLists from "../../subcomponent/DataTable";
import EmployeeAdd from "./SaveData/Employee";
import { leadSchema } from "./schemaInput/leadSchema";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { addLeadAction } from "../../redux/action/admin/leads/addLead";

import { getLeadAction } from "../../redux/action/admin/leads/getLead";
import { createEmployee } from "../../redux/action/admin/employee/create";
import { employeeSchema } from "./schemaInput/employeeSchema";
import { getEmployee } from "../../redux/action/admin/employee/get";
import EmployeeDataLists from "../../subcomponent/EmployeeDataTable";
import { editSelectData } from "../../redux/action/admin/leads/editLead";
import { useAuth } from "../../redux/authContext";
import axios from "axios";
import { url } from "../../utils/server";
import { getAuthHeaders } from "../../utils/token";
import UserProfileNav from "../leads/UserProfileNav";




const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "phoneNumber",
    numeric: true,
    disablePadding: false,
    label: "Phone Number",
  },
  {
    id: "role",
    numeric: true,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "subRole",
    numeric: true,
    disablePadding: false,
    label: "Sub Role",
  },
  {
    id: "senior",
    numeric: true,
    disablePadding: false,
    label: "Senior",
  },
  {
    id: "access",
    numeric: true,
    disablePadding: false,
    label: "HRM Access",
  },
  

 
];
function LeadTable() {
  const {employeeData} = useAuth()
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const [selectItem, setSelectItem] = useState([])
  const [filteredRows, setFilteredRows] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const dispatch = useDispatch()
  const { error, loading ,leadsData} = useSelector(state => state.getLeadData);

  const {data} = useSelector(state => state.getEmployee);
  const [snackbarMessage, setSnackbarMessage] = useState('');
const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // or 'error', 'warning', 'info'
const handleSnackbar = (message, severity) => {
  setSnackbarMessage(message);
  setSnackbarSeverity(severity);
  setSnackbarOpen(true);
};

  
   function createShell(id, name, email, phone, role, subRole,senior,HRM) {
    return { id, name, email, phone, role, subRole ,senior,HRM};
  }

  const rows = data.map((docs) => {
    // Assuming 'senior' should be found based on some condition related to 'docs'
    const seniorData = data.find(item => item._id === docs.senior); // Adjust this condition based on your data structure
    
    const name = docs.name || '';
    const email = docs.email || '';
    const phone = docs.phoneNumber || '';
    const role = docs.role || '';
    const subRole = docs.subRole || '';
    const senior = seniorData ? seniorData.name : ''; // Adjust this based on your data structure
    const HRM = docs.access =='HRM' ? 'Access Granted' : 'Access Denied'; // Assuming 'projectName' is a property of 'docs'
   
    return createShell(docs._id, name, email, phone, role, subRole, senior, HRM);
  });
  const fetchData = async () => {
    try {
        await dispatch(getEmployee());    
    } catch (error) {
        console.log(error);
    }
};

  useEffect(() => {
  
    fetchData();
}, [dispatch]);
  const handleSave = async (shouldClose, data) => {
    try {
      console.log("hello",data)
      const saveData =await dispatch((createEmployee(data)));;
      handleSnackbar(saveData.msg,'success')
      console.log(saveData)
      const axiosConfig = getAuthHeaders();
      const response = await axios.get(`${url}/admin/employee/departments`,axiosConfig);
      
     
      
      if(saveData.msg === 'Employee created successfully'){
        if (shouldClose) {
          setOpen(false); 

        }
      }
     fetchData()
    } catch (error) {
      console.error("Error while saving:", error);
      
    }
  };
  
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const handleCancle = ()=>{
    setOpen(!open)
    dispatch(editSelectData())
  }

  const handleSearch = ()=>{
    setSearch(!search)
  }
  const handleClose = () => {
    setSearch(false);
  };

  const handleSearchData = (value) => {
    console.log(value)
    if (value.trim() === "") {
      setFilteredRows(rows);
    } else {
      const filtered = rows.filter(row => {
        // Modify this condition according to your search requirements
        return row.name.toLowerCase().includes(value.toLowerCase());
      });
      console.log(filtered, 'dfgs')
      setFilteredRows(filtered);
    }
  };
 
  return (
    <>
     <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} sx={{zIndex:99999999999}}>
  <MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
    {snackbarMessage}
  </MuiAlert>
</Snackbar>
      {loading &&   <CircularProgress />}
      <Box   sx={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
    }}>
      <Grid sx={{ width: "100%", padding: "50px" }}>
          <UserProfileNav />
        </Grid>
      <Box sx={{
         display:'flex',
         justifyContent:'center',
         flexDirection:'column',
         alignItems:'center',
         gap:2,
         Height:'650px',
         maxWidth:'100%',
         minWidth:'100%',
      }}>
      {search &&   <Drawer
      anchor="top"
      open={search}
      sx={{
        background:'transparent',
        maxWidth:'200px'
      }}
      onClose={handleClose}
      elevation={4}
      TransitionComponent={Slide}
      transitionDuration={100} 
    >
      
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: '10px', top: '10px' }}
        >
          <MdClose />
        </IconButton>
        
        <Search  onSearch={handleSearchData} />
        

        
      
    </Drawer>}
        <Grid flex={1}>
          <Grid sx={{
            width:'100%',
             height:'50px',
             display:'flex',
             justifyContent:'space-between',
             alignContent:'center',
             gap:1,
             padding:2,
             marginTop:'3%'
          }}>
            <Grid >
              
                <Button onClick={handleSearch} sx={{
                   background:theme.palette.button.secondary,
                   textAlign:'center',
                   color:theme.palette.text.primary,
                   minWidth:'50%',
                  
                   
                }}>
                <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                    
                    <FaSearch/>
                     Search
                    </IconContext.Provider>
                </Button>
            </Grid>
            {employeeData?.role ==='Admin' && <>
            <Grid  sx={{height:'650px'}}>
              <Button onClick={toggleDrawer(true)} sx={{
                   background:theme.palette.button.secondary,
                   textAlign:'center',
                   color:theme.palette.text.primary,
                   minWidth:'50%',
                   marginLeft:13
                   
                }}>Add Employee</Button>
              <Drawer
                open={open}
                onClose={toggleDrawer(false)}
                anchor="top"
                sx={{
                  "& .css-1160xiw-MuiPaper-root-MuiDrawer-paper": {
                     }, padding:'50px',
                  maxWidth:'80%',  
                }}
              >
                <Grid
                  
                >
                  <EmployeeAdd schema={employeeSchema} saved={handleSave} cancel={handleCancle}/>
                </Grid>
              </Drawer>
            </Grid>
              </>}
            
          </Grid>
          <Grid
           sx={{
            maxWidth:'100%',
            Width:'100%',
            marginTop:'100',
           }}
          >
            <EmployeeDataLists rows={filteredRows.length > 0 ? filteredRows : rows} headCells={headCells} refrence={'Employees'} data={data} />
          </Grid>
        </Grid>
      </Box>
      </Box>
    </>
  );
}

export default LeadTable;
