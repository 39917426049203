import React from 'react'
import TotalEMp from './TotalEMp'
import { Box, Grid } from '@mui/material'
import PunchIn from './PuchInEmployee'
import Leave from './LeaveDash'
import FilterDepartment from './FilterDepartment'
import DateTimePickerDemo from './Chart'
import UserProfileNav from '../../../components/leads/UserProfileNav'


function DashboardHRM() {
  return (
    <>
      <Box   sx={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
    }}>
      <Grid sx={{ width: "100%", padding: "50px" }}>
          <UserProfileNav />
        </Grid>
    <Box>
       <Box width={'100%'} sx={{
        display:'flex'
       }}>
          <Grid sx={{
            width:'70%',
            display:'flex',
            height:'700px',
            marginTop:'40px',
            gap:'20px',
            marginLeft:'30px'
          }}>
             <Grid width={'33%'}>
             <TotalEMp/>
             </Grid>
             <Grid width={'33%'}>
             <PunchIn/>
             </Grid>
             <Grid width={'33%'}>
             <Leave/>
             </Grid>
            
              
          </Grid>
          <Grid marginTop={'40px'} marginLeft={'10px'} marginRight={'10px'} width={'27%'}>
             <FilterDepartment/>
             </Grid>
             
       </Box>  
           <Grid sx={{
            height:'400px',
            width:'67%',
            marginLeft:'45px',
            marginTop:'-520px',
            borderRadius:'20px'
           }}>
           <DateTimePickerDemo/>
           </Grid>
      
       </Box>
       </Box>
    </>
  )
}

export default DashboardHRM