import * as React from 'react';
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from '@mui/x-charts/Gauge';

function GaugePointer() {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();

  if (valueAngle === null) {
    // No value to display
    return null;
  }

  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  };

  return (
    <g>
      <circle cx={cx} cy={cy} r={10} fill="red" />
      <path
        d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
        stroke="red"
        strokeWidth={5}
      />
    </g>
  );
}

export default function ProgressChart({ value }) {
    const data = (value.Leads/value.target)*100
    const key  = Object.keys(value)
  return (
    <GaugeContainer
      width={200}
      height={200}
      startAngle={-110}
      endAngle={110}
      value={data} // Replace with the actual value you want to display
    >
      <GaugeReferenceArc />
      <GaugeValueArc />
      <GaugePointer />
      {/* Additional elements like labels or text can be added here */}
      <text x="50%" y="90%" textAnchor="middle" fontSize="16">
        {`${key[1]}${data}%`}
      </text>
    </GaugeContainer>
  );
}
