import { Box, FormControl, Grid, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { url } from '../../utils/server';
import { getAuthHeaders } from '../../utils/token';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../redux/authContext';

function EmployeeLogin() {
    const [employees, setEmployees] = useState({});
    const axiosConfig = getAuthHeaders();
    const { id } = useParams();
    const [name, setName] = useState('');
    const { employeeData } = useAuth();

    const fetchDepartments = async () => {
        try {
            const response = await axios.get(`${url}/admin/employee/departments`, axiosConfig);
            const { employee } = response.data;
            console.log(employee, 'got it');
            const currentEmployee = employee.find(emp => emp._id === id);
            setEmployees(currentEmployee);
            setName(currentEmployee?.name)
        } catch (error) {
            console.error('Error fetching departments:', error);
        }
    };

    useEffect(() => {
        fetchDepartments();
    }, []);

    console.log(employeeData.role !=='Admin');

    return (
        <Box>
            <Grid>
                <FormControl>
                    <TextField
                        name='name'
                        id='name'
                        value={name}
                        contentEditable={employeeData?.role === 'Admin'}
                        onChange={(e) => setName(e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: 'none',
                                },
                                '&:hover fieldset': {
                                    border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                            },
                            '& input': {
                                border: 'none',
                                outline: 'none',
                                boxShadow: 'none',
                            },
                        }}
                    />
                </FormControl>
            </Grid>
        </Box>
    );
}

export default EmployeeLogin;
