import { Avatar, Box, Grid, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BiPhone, BiSolidChevronDownSquare, BiUpArrow, BiUpArrowCircle } from 'react-icons/bi'
import { MdCallMissed, MdControlPoint, MdDewPoint, MdEmail, MdMeetingRoom, MdNoMeetingRoom, MdOutlineLineAxis, MdOutlineNumbers, MdSwapCalls } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { GrLocation } from 'react-icons/gr'
import useGlass from '../../theme/glassEffect'
import { useAuth } from '../../redux/authContext'
import { getLeadAction } from '../../redux/action/admin/leads/getLead'
import axios from 'axios'
import { url } from '../../utils/server'
import { getAuthHeaders } from '../../utils/token'
import { GiPointySword, GiTeapotLeaves } from 'react-icons/gi'
import { BsPhoneVibrate } from 'react-icons/bs'

function LeadProfile({save}) {
    const { leadsData ,totalPoint} = useSelector((state) => state.getLeadData)
    const [employees, setEmployees] = useState([]); // Corrected variable name to setEmployees
    const { id } = useParams()
    const [assignLead,setAssignedLead ] = useState()
     const filteredLead = leadsData?.filter(data => data._id === id)
    const data = filteredLead[0]?.leads[0][0]
    const dispatch = useDispatch()
    const { employeeData } = useAuth()
    const axiosConfig = getAuthHeaders();
    const handleEmailClick = () => {
        window.location.href = `mailto:${data?.email}`;
    };

    const assign = async () => {
        const assign = await axios.get(
          `${url}/admin/lead/get-details/${id}`,
          axiosConfig
        );
        setAssignedLead(assign?.data);
      };

    const handlePhoneClick = () => {
        window.location.href = `tel:${data?.phoneNumber}`;
    };

    const handleLocationClick = () => {
        // Encode the address for the URL
        const encodedAddress = encodeURIComponent(data?.companyAddress);
        window.open(`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`, '_blank');
    };

    const fetchDepartments = async () => {
        
        try {
            const response = await axios.get(`${url}/admin/employee/departments`, axiosConfig);
            const { data } = response; // Destructure data directly
            setEmployees(data.employee); // Set employees state correctly
        } catch (error) {
            console.error('Error fetching departments:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const employeeIds = [employeeData?._id]; // Safeguard against undefined employeeData
            if (employeeData?.junior) {
                employeeData.junior.forEach(doc => {
                    employeeIds.push(doc)
                });
            }
            try {
                await dispatch(getLeadAction(employeeIds));
            } catch (error) {
                console.log(error);
            }
        };
        if (employeeData) {
            fetchData();
            fetchDepartments();
            assign()
        }
    }, [dispatch, employeeData,save]); // Add dispatch as a dependency

    // Find owner information
    const owner = employees.find(emp => emp._id === data?.owner[data?.owner?.length - 1]);

    console.log(owner,'sdfdf')
    const points = totalPoint?.find(lead=>lead._id==id)?.totalPoint
    const totalCalls = assignLead?.call?.details?.length
    const totalSession = assignLead?.session?.details?.length
    return (
        <Box bgcolor={'white'} sx={{
            boxShadow: useGlass.glass.boxShadow,
            backdropFilter: useGlass.glass.backdropFilter,
            WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
            borderRadius: useGlass.glass.borderRadius,
            border: useGlass.glass.border,
            width: '90%',
            display: 'flex',
            overflow: 'hidden'
        }}>
            <Grid width={'40%'} borderRight={'1px solid grey'} bgcolor={'#07011c'} color={'white'}>
              
                
                <Typography sx={{
                    borderBottom: '1px solid grey',
                    padding: '10px 20px',
                    display:'flex',
                    gap:'10px',
                    alignItems:'center'
                }}>
                   <Grid>
                <Avatar src="https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg" alt="profile" style={{ height: '30px', width: '30px', borderRadius: '50%' }} />
                </Grid>
                    {data?.name}</Typography>

                <Grid container alignItems={'center'}>
                    <IconButton onClick={handleEmailClick} >
                        <MdEmail color='white'/>
                    </IconButton>
                    <Typography>{data?.email}</Typography> . 
                </Grid>
                <Grid container alignItems={'center'}>
                    <IconButton onClick={handlePhoneClick}>
                        <BiPhone color='white' />
                    </IconButton>
                    <Typography>{data?.phoneNumber}</Typography>
                </Grid>
                <Grid container alignItems={'center'}>
                    <IconButton onClick={handleLocationClick}>
                        <GrLocation color='white'/>
                    </IconButton>
                    <Typography>{data?.companyAddress}</Typography>
                </Grid>
                <Grid container alignItems={'center'} gap={1}>
                    <Grid sx={{display:'flex', alignItems:'center',flexDirection:'column', borderRight:'1px solid grey'}}>
                    <IconButton sx={{paddingLeft:'5px'}}>
                        <BiUpArrowCircle color='white'/>
                        <Typography color={'white'} fontSize={12}>Leads score</Typography>
                    </IconButton>
                    <Typography>{points ? points : 0}</Typography>
                   
                    </Grid>
                    <Grid sx={{display:'flex', alignItems:'center',flexDirection:'column',borderRight:'1px solid grey'}}>
                    <IconButton>
                        <BsPhoneVibrate color='white'/>
                        <Typography color={'white'} fontSize={12}>Total Calls</Typography>
                    </IconButton>
                    <Typography> {totalCalls && totalCalls}</Typography>
                    </Grid>
                    <Grid sx={{display:'flex', alignItems:'center',flexDirection:'column',borderRight:'1px solid grey'}}>
                    <IconButton>
                        <MdMeetingRoom color='white'/>
                        <Typography color={'white'} fontSize={12}>Total Meetings</Typography>
                    </IconButton>
                    <Typography> {totalSession && totalSession}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid width={'60%'}> {/* Adjusted width here */}
                <Typography sx={{
                    borderBottom: '1px solid grey',
                    padding: '10px 20px'
                }}>Lead Information</Typography>
                <Grid>
                    <Typography paddingLeft={'10px'}>Project Name : {data?.projectName}</Typography>
                </Grid>
                <Grid>
                    <Typography paddingLeft={'10px'}>Source : {data?.source}</Typography>
                </Grid>
                <Grid>
                    <Typography paddingLeft={'10px'}>Project Language : {data?.projectLanguage}</Typography>
                </Grid>
                <Grid>
                    <Typography paddingLeft={'10px'}>Lead Owner : {owner?.name}</Typography> {/* Display owner's name */}
                </Grid>
            </Grid>
        </Box>
    );
}

export default LeadProfile;
