import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Button, FormControl, Grid, Snackbar, TextField, Typography, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import theme from "../theme/theme";
import { url } from "../utils/server";
import { useAuth } from "../redux/authContext";

function Login() {
  const { login } = useAuth();

  const loginSchema = z.object({
    email: z.string().email().min(1),
    password: z.string().min(1),
  });

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: zodResolver(loginSchema),
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false); // Loading state

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const onSubmit = async (data) => {
    setLoading(true); // Start loading
    try {
      const response = await axios.post(`${url}/admin/employee/login`, data, { withCredentials: true });

      if (response.data.success) {
        const { employeeData, token } = response.data;
        login(employeeData, token); // Set employeeData and token in AuthProvider

        setSnackbarSeverity(response.data.severity || "success");
        setSnackbarMessage(response.data.message);
        setSnackbarOpen(true);

        setTimeout(() => {
          navigate("/");
        }, 1500);
      } else {
        setSnackbarSeverity(response.data.severity || "error");
        setSnackbarMessage(response.data.message);
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("An error occurred. Please try again later.");
      setSnackbarOpen(true);
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <>
      <Box
        sx={{
          background: "white",
          width: "120%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          marginLeft:'-50px'
        }}
      >
        <Grid container direction="column" alignItems="center" gap={3}>
          <Grid item>
            <img src="" alt="Logo" />
          </Grid>
          <Grid item>
            <Typography variant="h5">Welcome! 👋🏻</Typography>
            <Typography>Please sign in to your account to get access.</Typography>
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Grid container direction="column" gap={2} sx={{ width: "300px" }}>
                  <Grid item>
                    <TextField
                      id="email"
                      name="email"
                      label="Email *"
                      placeholder="Enter email"
                      type="email"
                      fullWidth
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: "Invalid email address",
                        },
                      })}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="password"
                      name="password"
                      label="Password *"
                      placeholder="Enter password"
                      type="password"
                      fullWidth
                      {...register("password", {
                        required: "Password is required",
                      })}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                    />
                  </Grid>
                  <Grid item>
                    <Button type="submit" disabled={loading} sx={{ background: theme.palette.button.secondary, color: "white", width: "100%" }}>
                      {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
                    </Button>
                    <Link to={'/forgot-password'} style={{marginTop:'10px'}}>Reset password</Link>
                  </Grid>
                </Grid>
              </FormControl>
            </form>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity} // This is a custom attribute for severity
      />
    </>
  );
}

export default Login;
