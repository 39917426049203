import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import adminRoutes from "./routes/admin/routes";
import Nav from "./components/navbar/Nav";

import { useLocation } from "react-router-dom";
import PrivateRoute from "./redux/privetRoute";
import Login from "./pages/login";
import { useAuth } from "./redux/authContext";
import UserProfileNav from "./components/leads/UserProfileNav";
import ForgotPass from "./pages/ForgotPass";
import ResetPass from "./pages/ResetPass";




function AppContent() {
  const { employeeData } = useAuth();
  const location = useLocation();


  
  const isLoginPage = () => {
    const currentPath = location.pathname;
    return currentPath === "/login" || currentPath === '/forgot-password' || currentPath==='/reset-password' || currentPath==='/reset-password/:id'
  };

  const currentPath = location.pathname;
  if (
    currentPath === "/hrm/dashboard" ||
    currentPath === "/hrm/teams" ||
    currentPath === "/hrm/employee" 
    
  ) {
    if (employeeData?.role === 'Employee') {
      return <Navigate to="/" />;
    }
  }

  return (
    <>
      {!isLoginPage() && <Nav />}
      <Routes>
        
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPass/>}/>
        <Route path="/reset-password/:id" element={<ResetPass/>}/>
        {adminRoutes.map((item, index) => (
          <Route
            key={index}
            path={item.path}
            element={
              <PrivateRoute>
                <item.component />
              </PrivateRoute>
            }
          />
        ))}
      </Routes>
    </>
  );
}

export default AppContent;
