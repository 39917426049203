import React, { useState, useEffect } from "react";
import UserProfileNav from "../../../components/leads/UserProfileNav";
import {
  Box,
  Grid,
  LinearProgress,
  Typography,
  Modal,
  TextField,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import theme from "../../../theme/theme";
import { BiAddToQueue } from "react-icons/bi";
import useGlass from "../../../theme/glassEffect";
import axios from "axios";
import { url } from "../../../utils/server";
import { useParams } from "react-router-dom";
import { GrAdd } from "react-icons/gr";
import LeadWorker from "../../../components/leads/LeadWorker";
import LeadAssiment from "../../../components/leads/LeadAssiment";
import LeadProfile from "../../../components/leads/LeadProfile";
import { getAuthHeaders } from "../../../utils/token";

function LeadDetails() {
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [isAddDataOpen, setIsAddDataOpen] = useState(false);
  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [leadDetails, setLeadDetails] = useState();
  const [assignteams, setAssignTeams] = useState();
  const [assignLead, setAssignedLead] = useState();
  const axiosConfig = getAuthHeaders();
  const [save,setSave] = useState(false)
  const fetchModule = async () => {
    try {
      const response = await axios.get(
        `${url}/admin/lead/get/${id}`,
        axiosConfig
      );

      setData(response.data.modules);
      setAssignTeams(response.data);
      const totalModules = response.data.modules;
      let completedModuleCount = 0;
      const selectedSet = totalModules.filter((module) => {
        if (module.moduleStage === "complete") {
          completedModuleCount++;
          return false;
        }
        return true;
      });

      setValue((completedModuleCount / totalModules.length) * 100);
      setSelectedItem(selectedSet.length > 0 ? selectedSet[0] : null);
      setLeadDetails(response.data.lead.leads);
      setAssignTeams(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const assign = async () => {
    const assign = await axios.get(
      `${url}/admin/lead/get-details/${id}`,
      axiosConfig
    );
    setAssignedLead(assign?.data);
  };

  useEffect(() => {
    fetchModule();
    assign();
  }, []); // Fetch modules once on component mount

  const handleOpenAddData = () => {
    setIsAddDataOpen(true);
  };

  const handleCloseAddData = () => {
    setIsAddDataOpen(false);
  };

  const handleAddData = async () => {
    try {
      const newData = {
        leadId: id,
        moduleName: newName,
        moduledesc: newDescription,
        stage: "ongoing",
      };
      await axios.post(`${url}/admin/lead/addModule`, newData, axiosConfig);
      fetchModule(); // Refresh modules after adding new module
    } catch (err) {
      console.log(err);
    }
    setNewName("");
    setNewDescription("");
    setIsAddDataOpen(false);
  };

  const handleItemClick = (index) => {
    setSelectedItem(index === selectedItem ? null : data[index]);
  };
  console.log(newDescription);
  const handleComplete = async () => {
    if (!selectedItem) return;

    try {
      const leadId = id;
      await axios.put(
        `${url}/admin/lead/updateModule/${selectedItem._id}`,
        { leadId },
        axiosConfig
      );
      fetchModule();
    } catch (err) {
      console.error(err);
    }
  };
  const createPoint = assignLead?.created ? 1 : 0;
  const callPoint = assignLead?.call?.status ? 1 : 0;
  const bookingPoint = assignLead?.booking?.finalStatus ? 1 : 0;
  const sessionPoint = assignLead?.session?.finalStatus ? 1 : 0;
  const documentpoint = assignLead?.documentation?.finalStatus ? 1 : 0;
  const paymentPoint = assignLead?.paymentHistory?.finalStatus ? 1 : 0;
  const success = assignLead?.success ? 1 : 0;

  const Process =
    callPoint +
    bookingPoint +
    createPoint +
    sessionPoint +
    documentpoint +
    paymentPoint +
    success;

  const   totalLeadProcess = Math.floor((Process/7)*100).toFixed(2) 
 
  const handlesave = ()=>{
      setSave(true)
  }
  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Grid sx={{ width: "100%", padding: "50px" }}>
          <UserProfileNav />
        </Grid>

        <Box
          p={2}
          display={"flex"}
          gap={"20px"}
          flexDirection={"column"}
          maxWidth={"100%"}
        >
          <LinearProgress
            variant="determinate"
            value={totalLeadProcess}
            
            sx={{
              height: "25px",
              maxWidth: "98%",
              minWidth: "98%",

              borderRadius: 5,
              background: theme.palette.background.default,
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#4CAF50",
                borderRadius: 2,
              },
            }}
          />
          <Typography
            sx={{
              position: "absolute",
              top: "69px",
              left: "50%",
              color: "white",
              fontSize: "12px",
            }}
          >
            Lead Progress {totalLeadProcess}%
          </Typography>

          <Grid container>
            <Grid
              item
              marginLeft={"10px"}
              sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}
            >
              {data &&
                data.map((item, index) => (
                  <Tooltip title={item.moduleName} arrow placement="top">
                    <Typography
                      key={item._id} // Use data._id as key
                      onClick={() => handleItemClick(index)} // Handle click on each item
                      sx={{
                        background:
                          item.moduleStage === "complete"
                            ? "#4CAF50"
                            : "#eb3434",
                        color:
                          item.moduleStage === "completed" ? "white" : "white",
                        padding: "10px 30px",
                        height: "20px",
                        width: "60px",
                        borderBottom: `1px solid #0005`,
                        borderRight: `1px solid #0000`,
                        textAlign: "center",
                        clipPath:
                          "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)",
                        boxShadow:
                          selectedItem && item._id === selectedItem._id
                            ? "0 0 10px rgba(0,0,0,0.5)"
                            : "none", // Apply shadow when selected
                        cursor: "pointer", // Change cursor to pointer
                      }}
                    >
                      {`Module${index + 1}`}
                    </Typography>
                  </Tooltip>
                ))}
            </Grid>
          </Grid>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LeadProfile save = {save}/>
          </Grid>
        </Box>

        <Grid
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LeadAssiment data={data} lead={assignteams} saved={handlesave}/>
        </Grid>

        <Modal
          open={isAddDataOpen}
          onClose={handleCloseAddData}
          aria-describedby="modal to add new data"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              backgroundColor: "rgba( 255, 255, 255, 0.45 )",
              boxShadow: useGlass.glass.boxShadow,
              backdropFilter: useGlass.glass.backdropFilter,
              WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
              borderRadius: useGlass.glass.borderRadius,
              border: useGlass.glass.border,
              p: 4,
            }}
          >
            <Typography variant="h6" component="h2" gutterBottom>
              Add New Module
            </Typography>
            <TextField
              label="Module Name"
              variant="outlined"
              fullWidth
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              type="textarea"
              label="Description"
              variant="outlined"
              fullWidth
              value={newDescription}
              onChange={(e) => setNewDescription(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              onClick={handleAddData}
              sx={{
                background: theme.palette.button.secondary,
                padding: "5px 40px",
              }}
            >
              Add
            </Button>
          </Box>
        </Modal>
      </Grid>
    </>
  );
}

export default LeadDetails;
