import axios from 'axios';
import {
    DELETE_LEAD_REQUEST,
    DELETE_LEAD_SUCCESS,
    DELETE_LEAD_FAIL,
    CATCH_ERROR
 } from '../../../constents/admin/lead.js'
import { url } from '../../../../utils/server.js';
import { getAuthHeaders } from '../../../../utils/token.js';

export const deleteLeadAction = (itemIds) => async (dispatch) => {
    const deleteData = {
        leadIds : itemIds
    }
    const axiosConfig = getAuthHeaders();
    try {
        dispatch({
            type: DELETE_LEAD_REQUEST
        });
        
        const apiurl = `${url}/admin/lead/delete`; 
        const { data } = await axios.post(apiurl,  deleteData,axiosConfig); 
        console.log(data);

        dispatch({
            type: DELETE_LEAD_SUCCESS,
            payload:data.data.success
        });
    } catch (error) {
        console.log(error)
        dispatch({
            
            type: DELETE_LEAD_FAIL,
            payload: error.message
        });
    }
};

export const clearError = () => async (dispatch) => {
    dispatch({
        type: CATCH_ERROR
    });
};
