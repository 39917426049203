import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, IconButton, Drawer, List, ListItem, ListItemText, Button } from '@mui/material';
import axios from 'axios';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useAuth } from '../../redux/authContext';
import useGlass from '../../theme/glassEffect';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployee } from '../../redux/action/admin/employee/get';
import { useParams } from 'react-router-dom';
import { url } from '../../utils/server';
import { getAuthHeaders } from '../../utils/token';
import { startTimer, stopTimer, formatTime } from './timer.js'; // Import timer functions

function UserProfileNav() {
  
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.getEmployee);
  const [employee, setEmployee] = useState(null);
  const [datas, setData] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const { employeeData } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { Countdata } = useSelector(state => state.notification);
  const [CountData, setCountData] = useState([]);
  const [punch, setPunch] = useState(false);
  const axiosConfig = getAuthHeaders();
  const [elapsedSeconds, setElapsedSeconds] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const id  = employeeData._id
  // Function to convert total seconds to HH:MM:SS format
  const formattedTime = formatTime(elapsedSeconds);

  // Fetch initial data and employee details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://geolocation-db.com/json');
        setData(response);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    dispatch(getEmployee());
  }, [dispatch]);

  // Effect to update employee details
  useEffect(() => {
    const current = data.find((emp) => emp?._id === id);
    if (current) {
      setEmployee(current);
      setPunch(current.status);
    }
  }, [data, id]);
  useEffect(()=>{
       let sec = localStorage.getItem('elapsedSeconds')
       setElapsedSeconds(JSON.parse(sec))
       if(elapsedSeconds > 0 && punch){
        startTimer(setIntervalId, setElapsedSeconds);
       }
  },[punch])

  // Effect to handle notifications count
  useEffect(() => {
    const getNotificationsFromLocalStorage = () => {
      const notifications = JSON.parse(localStorage.getItem('notifications')) || [];
      return notifications;
    };

    const notificationsToUse = Array.isArray(Countdata) ? Countdata : getNotificationsFromLocalStorage();
    const notificationsWithFalseStatus = notificationsToUse.filter(notification => !notification.status);

    setCountData(notificationsWithFalseStatus);
  }, [Countdata]);

  // Function to handle opening the drawer and updating notifications
  const handleOpenDrawer = () => {
    setDrawerOpen(true);

    const notificationsFromLocalStorage = JSON.parse(localStorage.getItem('notifications')) || [];
    const updatedNotifications = notificationsFromLocalStorage.map(notification => ({
      ...notification,
      status: true
    }));

    localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
    setNotifications(updatedNotifications.reverse());

    const notificationsWithFalseStatus = updatedNotifications.filter(notification => !notification.status);
    setCountData(notificationsWithFalseStatus);
  };

  // Function to handle closing the drawer
  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  // Function to handle Punch In action
  const handlePunchIn = async () => {
    try {
      // Punch In
      await axios.get(`${url}/employee/punch-in/${employeeData._id}`, axiosConfig);
      
      setElapsedSeconds(0); // Reset elapsed seconds
      startTimer(setIntervalId, setElapsedSeconds); // Start timer
      setPunch(true); // Set punch state to true
    } catch (error) {
      console.log(error);
    }
  };
console.log(datas)
  // Function to handle Punch Out action
  const handlePunchOut = async () => {
    try {
      // Punch Out
      await axios.get(`${url}/employee/punch-out/${employeeData._id}`, axiosConfig);
      stopTimer(intervalId); // Stop timer
      localStorage.removeItem('elapsedSeconds')
      setPunch(false); // Set punch state to false
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ width: '100%', height: '50px', paddingRight: '0px', background: 'white' }}>
      <Grid container alignItems="center" marginLeft={-2} width={'100%'}>
        {employee && (
          <Grid item sx={{ display: 'flex', alignItems: 'center', height: '50px', width: '100%', marginLeft: '0px', justifyContent: 'end', gap: 0 }}>
            <Typography color={'red'} sx={{ fontSize: '11px', marginTop: '3px' }}>{datas}</Typography>
            <IconButton onClick={handleOpenDrawer} style={{ color: 'green', position: 'relative' }}>
              <NotificationsIcon />
              {CountData.length > 0 && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'red',
                    color: 'white',
                    borderRadius: '50%',
                    width: '16px',
                    height: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '8px',
                    fontWeight: 'bold'
                  }}
                >
                  {CountData.length}
                </div>
              )}
            </IconButton>
            <Grid sx={{ display: 'flex', width: '250px', gap: 2, marginLeft: 0, color: 'white' }}>
              <Typography>
                <img src={employeeData?.avatar ? employeeData.avatar : "https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg"} alt="profile" style={{ height: '30px', width: '30px', borderRadius: '50%' }} />
              </Typography>
              
              <Grid>
                {punch ? (
                  <Button
                    variant="contained"
                    onClick={handlePunchOut}
                    style={{
                      background: 'red',
                      color: 'white',
                      cursor: 'pointer'
                    }}
                  >
                    Punch Out
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handlePunchIn}
                    style={{
                      background: 'green',
                      color: 'white',
                      cursor: 'pointer'
                    }}
                  >
                    Punch In
                  </Button>
                )}
                
              </Grid>
              <Typography color={'blue'}>{formattedTime}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      {/* Small drawer for notifications */}
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: {
            width: '300px',
            height: '300px',
            top: '30px',
            left: '520px'
          },
        }}
        ModalProps={{
          keepMounted: true,
        }}
        elevation={3}
      >
        <Box sx={{ height: '100%', overflowY: 'auto', backgroundColor: useGlass.glass.background, boxShadow: useGlass.glass.boxShadow, backdropFilter: useGlass.glass.backdropFilter, WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter, borderRadius: useGlass.glass.borderRadius, border: useGlass.glass.border }}>
          <List sx={{ display: 'flex', flexDirection: 'column', gap: '12px', overflow: 'scroll' }}>
            {notifications.map((notification, index) => (
              <ListItem key={index} sx={{ backgroundColor: 'white', marginTop: -1 }}>
                <ListItemText primary={notification.data} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}

export default UserProfileNav;
