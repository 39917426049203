import React from 'react'
import EmployeeTable from '../../../components/navbar/EmployeeTable'

function Employee() {
  return (
    <>
      <EmployeeTable/>
    </>
  )
}

export default Employee