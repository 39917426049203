import React, { useState } from "react";
import { Box, Grid, Stack, TextField } from "@mui/material";
import theme from "../theme/theme";

function Search({ onSearch }) {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    // Send the input value to the parent component
    onSearch(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 1,
      }}
    >
      <Grid>
        <Stack spacing={2} sx={{ width: 500 }}>
          <TextField
            onChange={handleInputChange}
            value={inputValue}
            variant="standard"
            label="Search"
            placeholder="Enter value to search"
            sx={{
              backgroundColor: theme.palette.background.primary,
              padding: 1,
              borderRadius: 1,
              border: "none",
            }}
          />
        </Stack>
      </Grid>
    </Box>
  );
}

export default Search;
