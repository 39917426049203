import { EmojiPeopleRounded, GroupAddRounded, Logout } from '@mui/icons-material'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { GiRamProfile } from 'react-icons/gi'
import { MdGroupWork, MdGroups, MdLogout } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { getEmployee } from '../../../redux/action/admin/employee/get'
import { BiLogIn } from 'react-icons/bi'

function PunchIn() {
    const dispatch = useDispatch()
    const [punchIn , setPunchIn] = useState([])
    const { data } = useSelector((state) => state.getEmployee);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(getEmployee());
                const punchInData = data.filter((emp=>emp.status === true))
                setPunchIn(punchInData)
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [dispatch]);
  return (
    <>
      <Box sx={{
        background:'white',
        width:'100%',
        height:'150px',
        borderRadius:'20px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        
      }}>
          <Typography mt={2}>Punch Status</Typography>
          <Grid sx={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
          }}>
          <IconButton>
          <span style={{fontSize:'13px'}}>PunchIn</span>  <BiLogIn color='green' size={30}/>
        </IconButton> 
          <Typography>{punchIn?.length}</Typography>
          </Grid>
          <Grid sx={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
          }}>
          <IconButton>
            <span style={{fontSize:'13px'}}>PunchOut</span> <MdLogout color='green' size={27}/>
        </IconButton> 
          <Typography>{data?.length - punchIn?.length}</Typography>
          </Grid>
           
      </Box>
    </>
  )
}

export default PunchIn