import { Box, Grid, IconButton, TextField, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { BiEdit, BiSave, BiPlus, BiTrash } from 'react-icons/bi';
import React, { useState } from 'react';
import useGlass from '../theme/glassEffect';
import { MdSavedSearch } from 'react-icons/md';

function Allowance({ id, data,saved }) {
    const filterData = data.find(item => item._id === id);
    const allowances = filterData?.salery?.allowance || [];
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState(allowances);

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedAllowances = formData.map((allowance, i) => (
            i === index ? { ...allowance, [name]: value } : allowance
        ));
        setFormData(updatedAllowances);
    };

    const handleSave = () => {
        localStorage.setItem('allowance', JSON.stringify(formData));
        saved(true)
        setIsEditing(false);
    };

    const handleAddRow = () => {
        setFormData([...formData, { allowanceName: '', allowanceType: '', allowanceAmount: '' }]);
    };

    const handleDeleteRow = (index) => {
        const updatedAllowances = formData.filter((_, i) => i !== index);
        setFormData(updatedAllowances);
    };

    return (
        <Box>
            <Grid sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                width: '100%',
                gap: '10px',
                backgroundColor: useGlass.glass.background,
                boxShadow: useGlass.glass.boxShadow,
                backdropFilter: useGlass.glass.backdropFilter,
                WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
                borderRadius: useGlass.glass.borderRadius,
                border: useGlass.glass.border,
            }}>
                <h2 style={{ marginLeft: '20px' }}>Allowance</h2>
                <Tooltip title={isEditing ? 'Save' : 'Edit'}>
                        <IconButton onClick={isEditing ? handleSave : () => setIsEditing(true)} sx={{
                            position: 'absolute',
                            right: '50px'
                        }}>
                            {isEditing ? <BiSave color='blue' /> : <BiEdit color='green' />}
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Add Row">
                        <IconButton onClick={handleAddRow} sx={{
                            position: 'absolute',
                            right: '0'
                        }}>
                            <BiPlus color='blue' />
                        </IconButton>
                    </Tooltip>
                <Grid padding={'30px'} position={'relative'}>
                    

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Allowance Name</TableCell>
                                    <TableCell>Allowance Type</TableCell>
                                    <TableCell>Allowance Amount</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formData.map((allowance, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {isEditing ? (
                                                <TextField
                                                    name="allowanceName"
                                                    value={allowance.allowanceName || ''}
                                                    onChange={(e) => handleChange(e, index)}
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: { padding: '0', fontSize: 'inherit', color: 'inherit' }
                                                    }}
                                                />
                                            ) : (
                                                allowance.allowanceName
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {isEditing ? (
                                                <TextField
                                                    name="allowanceType"
                                                    value={allowance.allowanceType || ''}
                                                    onChange={(e) => handleChange(e, index)}
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: { padding: '0', fontSize: 'inherit', color: 'inherit' }
                                                    }}
                                                />
                                            ) : (
                                                allowance.allowanceType
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {isEditing ? (
                                                <TextField
                                                    name="allowanceAmount"
                                                    value={allowance.allowanceAmount || ''}
                                                    onChange={(e) => handleChange(e, index)}
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: { padding: '0', fontSize: 'inherit', color: 'inherit' }
                                                    }}
                                                />
                                            ) : (
                                                allowance.allowanceAmount
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {isEditing && (
                                                <IconButton onClick={() => handleDeleteRow(index)}>
                                                    <BiTrash color='red' />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Allowance;
