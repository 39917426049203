
import Profile from "../../components/profile/profile";
import profile from "../../components/profile/profile";
import Coustmer from "../../pages/CRM/coustmers/Coustmer";
import CoustmerProfile from "../../pages/CRM/coustmers/CoustmerProfile";
import Transitions from "../../pages/CRM/coustmers/Transitions";
import DashboardCRM from "../../pages/CRM/dashboard/DashboardCRM";
import ForgotPass from "../../pages/ForgotPass";
import DashboardHRM from "../../pages/HRM/dashboard/DashboardHRM";
import Employee from "../../pages/HRM/employee/Employee";
import EmployeeDetails from "../../pages/HRM/employee/EmployeeDetails";
import LeaveManage from "../../pages/HRM/leave/LeaveManage";
import Meeting from "../../pages/HRM/meetings/Meeting";
import Payrole from "../../pages/HRM/payrole/Payrole";

import Teams from "../../pages/HRM/teams/Teams";
import Dashboard from "../../pages/LMS/dashboard/Dashboard";
import LeadDetais from "../../pages/LMS/leads/LeadDetais";
import Leads from "../../pages/LMS/leads/Leads";
import ResetPass from "../../pages/ResetPass";
import Login from "../../pages/login";


const adminRoutes = [
  {
    path: "/",
    component: Dashboard
  },
  {
    path: "/:id",
    component: Profile
  },

  {
    path: '/leads',
    component: Leads
  },
  {
    path: '/leads/:id',
    component: LeadDetais
  },
  {
    path: '/crm/dashboard',
    component: DashboardCRM
  },
  {
    path: '/crm/customer',
    component: Coustmer
  },
  {
    path: '/crm/customer/:id', 
    component: CoustmerProfile
  },
  {
    path: '/crm/transition',
    component: Transitions
  },
  {
    path: '/hrm/dashboard', 
    component: DashboardHRM
  },
  {
    path: '/hrm/employee',
    component: Employee
  },
  {
    path: '/hrm/payrole',
    component: Payrole
  },
  {
    path: '/hrm/leave-manage',
    component: LeaveManage
  },
  {
    path: '/employee/:id',
    component: EmployeeDetails
  },
  {
    path: '/hrm/team',
    component: Teams
  },
  
  {
    path: '/hrm/meetings',
    component: Meeting
  },
  {
    path:'/login',
    component : Login
  },
  {
    path:'/forgot-password',
    component : ForgotPass
  },
  {
    path:'/reset-password/:id',
    component : ResetPass
  }
];

export default adminRoutes;