import React, { useState } from 'react';
import { Box, FormControl, TextField, Button, FormControlLabel, Checkbox, Snackbar, Alert } from '@mui/material';
import { useAuth } from '../../redux/authContext';
import axios from 'axios';
import { url } from '../../utils/server';
import { useNavigate, useParams } from 'react-router-dom';
import { getAuthHeaders } from '../../utils/token';
import { ThreeDots } from 'react-loader-spinner'; // Import 3D spinner

const BookingForm = ({saved}) => {
  const [booking, setBooking] = useState('');
  const [date, setDate] = useState('');
  const [duration, setDuration] = useState('');
  const [isDone, setIsDone] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const { employeeData } = useAuth();
  const { id } = useParams();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (booking.trim() === '' || date.trim() === '' || duration.trim() === '') {
      alert('Please fill in all fields');
      return;
    }

    const data = {
      booking,
      date,
      isDone,
      duration,
      createBy: employeeData._id
    };

    setLoading(true); // Start loading
    try {
      const axiosConfig = getAuthHeaders();
      await axios.post(`${url}/admin/lead/booking/${id}`, data, axiosConfig);
      setSnackbarMessage('Booking created successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      // Reset form fields
      setBooking('');
      setDate('');
      setDuration('');
      setIsDone(false);
      saved(true)
      navigate(`/leads/${id}`)
    } catch (err) {
      console.error(err);
      setSnackbarMessage('Error creating booking.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
     
    } finally {        setLoading(false);
    
      // End loading
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ paddingLeft: 0, height: '100%', marginTop: '-70px', position: 'relative' }}>
      {loading && (
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        }}>
          <ThreeDots 
            height="80" 
            width="80" 
            radius="9"
            color="green" 
            ariaLabel="three-dots-loading" 
            wrapperStyle={{}} 
            visible={true} 
          />
        </Box>
      )}
      <FormControl sx={{ padding: '20px', width: '90%', display: 'flex', flexDirection: 'column', gap: '5px' }}>
        <TextField
          
          type='date'
          name='date'
          id='date'
          onChange={(e) => setDate(e.target.value)}
          sx={{
            width: '100%',
            '& .MuiInputBase-input': {
                color: 'white',
            },
            '& .MuiInputLabel-root': {
                color: 'white',
            },
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
        }}
        />
        <TextField
          
          type='text'
          name='duration'
          placeholder='Meeting duration'
          id='duration'
          onChange={(e) => setDuration(e.target.value)}
          sx={{
            width: '100%',
            '& .MuiInputBase-input': {
                color: 'white',
            },
            '& .MuiInputLabel-root': {
                color: 'white',
            },
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
        }}
        />
        <TextField
          
          type='text'
          name='meetingDesc'
          placeholder='Describe about meeting'
          multiline
          rows={5}
          id='meetingDesc'
          onChange={(e) => setBooking(e.target.value)}
          sx={{
            width: '100%',
            '& .MuiInputBase-input': {
                color: 'white',
            },
            '& .MuiInputLabel-root': {
                color: 'white',
            },
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
        }}
        />
        <FormControlLabel
          control={<Checkbox name="done" checked={isDone} onChange={(e) => setIsDone(e.target.checked)} />}
          label="Forward To Document"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ mt: 2 }}
        >
          Submit
        </Button>
      </FormControl>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BookingForm;
