import { Box, Grid, Typography, IconButton, TextField, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { BiEdit, BiSave } from 'react-icons/bi';
import React, { useEffect, useState } from 'react';
import useGlass from '../theme/glassEffect';

function PaymentDetails({ id, data ,saved}) {
    const filterData = data.find(item => item._id === id);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState(filterData || {});
   console.log(formData)
  

   const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
        ...prevData,
        [name]: value,
    }));
};

    const handleSave = () => {
        localStorage.setItem('paymentDetails', JSON.stringify(formData));
        saved(true)
        setIsEditing(false);
    };

    // Sort salaryHistory by createdAt date
    const sortedSalaryHistory = formData.salaryHistory?.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) || [];

    return (
        <Box>
            <h2>Details</h2>
            <Grid sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                width: '100%',
                gap: '10px',
                backgroundColor: useGlass.glass.background,
                boxShadow: useGlass.glass.boxShadow,
                backdropFilter: useGlass.glass.backdropFilter,
                WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
                borderRadius: useGlass.glass.borderRadius,
                border: useGlass.glass.border,
            }}>
                
                <Grid padding={'30px'} position={'relative'}>
                    <Tooltip title={isEditing ? 'Save' : 'Edit'}>
                        <IconButton onClick={isEditing ? handleSave : () => setIsEditing(true)} sx={{ position:'absolute', right:'0' }}>
                            {isEditing ? <BiSave color='blue' /> : <BiEdit color='green'/>}
                        </IconButton>
                    </Tooltip>
                   
                    <Typography sx={{ padding: '5px 20px' }}>
                        Name: {formData.name}
                    </Typography>
                    <Typography sx={{ padding: '5px 20px' }}>
                        Email: {formData.email}
                    </Typography>
                    <Typography sx={{ padding: '5px 20px' }}>
                        Phone: {formData.phoneNumber}
                    </Typography>

                    {['bankName', 'accountNumber', 'ifscCode', 'payRoleType','baseSalary','netSalary'].map(field => (
                        <Typography key={field} sx={{ padding: '5px 20px' }}>
                            {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}: 
                            {isEditing ? (
                                <TextField
                                    name={field}
                                    value={formData?.salery ? formData?.salery[field] : formData[field]}
                                    onChange={handleChange}
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        style: { padding: '0', marginTop:'-4px', width: 'auto', fontSize: 'inherit', color: 'inherit' },
                                    }}
                                    sx={{ marginLeft: '10px' }}
                                />
                            ) : (
                                formData[field]
                            )}
                        </Typography>
                    ))}
                </Grid>

                {/* Salary History Table */}
                
            </Grid>
            <Grid padding={'30px'} sx={{
                marginTop:'50px',
                backgroundColor: useGlass.glass.background,
                boxShadow: useGlass.glass.boxShadow,
                backdropFilter: useGlass.glass.backdropFilter,
                WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
                borderRadius: useGlass.glass.borderRadius,
                border: useGlass.glass.border,
            }}>
                    <Typography variant="h6" sx={{ marginBottom: '0px', marginLeft:'20px' }}>Salary History</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Base Salary</TableCell>
                                    <TableCell>Net Salary</TableCell>
                                    <TableCell>Allowances</TableCell>
                                    <TableCell>Commissions</TableCell>
                                    <TableCell>Deductions</TableCell>
                                    <TableCell>Created At</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedSalaryHistory.map((salary, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{salary.status ? 'Active' : 'Inactive'}</TableCell>
                                        <TableCell>{salary.baseSalary}</TableCell>
                                        <TableCell>{salary.netSalary}</TableCell>
                                        <TableCell>
                                            {salary.allowance.map((allow, idx) => (
                                                <div key={idx}>{`${allow.allowanceName} (${allow.allowanceType}): ${allow.allowanceAmount}`}</div>
                                            ))}
                                        </TableCell>
                                        <TableCell>
                                            {salary.commission.map((comm, idx) => (
                                                <div key={idx}>{`${comm.commissionName} (${comm.commissionType}): ${comm.commissionAmount}`}</div>
                                            ))}
                                        </TableCell>
                                        <TableCell>
                                            {salary.deduction.map((ded, idx) => (
                                                <div key={idx}>{`${ded.deductionName} (${ded.deductionType}): ${ded.deductionAmount}`}</div>
                                            ))}
                                        </TableCell>
                                        <TableCell>{new Date(salary.createdAt).toLocaleDateString()}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
        </Box>
    );
}

export default PaymentDetails;
