import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import theme from "../../../theme/theme";
import { Autocomplete, Dialog, DialogContent, DialogTitle, TextField, Button } from "@mui/material";
import useGlass from "../../../theme/glassEffect";
import { useSelector } from "react-redux";
import axios from "axios";
import { url } from "../../../utils/server";
import { getAuthHeaders } from "../../../utils/token";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const ReactBigCalendar = ({meetings}) => {
  const [eventsData, setEventsData] = useState([]);
  const [eventName, setEventName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const axiosConfig = getAuthHeaders();

  const handleSelect = ({ start, end }) => {
    setModalOpen(!modalOpen);
    setStart(start);
    setEnd(end);
  };

  const fetchMeetings = async () => {
    try {
     
      const response = await axios.get(`${url}/admin/meeting/get`, axiosConfig);
      const { data } = response;
      const events = data.map((meeting) => ({
        start: new Date(meeting.dateStart),
        end: new Date(meeting.dateEnd),
        title: meeting.eventName,
        selectedMember: meeting.attenders,
        link:meeting.meetingLink
      }));
      meetings(events)
      setEventsData(events);
    } catch (error) {
      console.error('Error fetching meetings:', error);
    }
  };

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        
        const response = await axios.get(`${url}/admin/employee/departments`, axiosConfig);
        const { employee } = response.data;
        setEmployee(employee);
      } catch (err) {
        console.error('Error fetching employees:', err);
      }
    };
    fetchMeetings();
    fetchEmployee();
  }, []);

  const handleSubmit = async () => {
    const token = localStorage.getItem('access_token');
    if (eventName && start && end) {
      try {
        const data = {
          eventName: eventName,
          dateStart: start,
          dateEnd: end,
          attenders: selectedMember,
          token:token
        };
       
        const response = await axios.post(`${url}/admin/meeting/add`, data, axiosConfig);
        console.log('Meeting added successfully:', response.data);
        fetchMeetings()
        setEventsData([
          ...eventsData,
          {
            start: new Date(start),
            end: new Date(end),
            title: eventName,
            selectedMember: selectedMember
          }
        ]);
        setModalOpen(!modalOpen);
      } catch (err) {
        console.error('Error adding meeting:', err);
      }
    } else {
      console.error('Missing required fields for adding meeting');
    }
  };

  return (
    <div className="App" style={{width:'100%'}}>
      <Calendar
        views={["day", "agenda", "work_week", "month"]}
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={eventsData}
        style={{ minHeight: "80vh", maxHeight: "80vh", minWidth: '83%', maxWidth: '83%', color: theme.palette.text.primary, overflow: 'scroll' }}
        onSelectEvent={(event) => alert(event.title)}
        onSelectSlot={handleSelect}
      />
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        sx={{
          backgroundColor: "transparent",
          "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
            backgroundColor: useGlass.glass.background,
            boxShadow: useGlass.glass.boxShadow,
            backdropFilter: useGlass.glass.backdropFilter,
            WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
            borderRadius: useGlass.glass.borderRadius,
            border: useGlass.glass.border,
            color: "black",
          },
        }}
      >
        <DialogTitle id="form-dialog-title" sx={{ background: "transparent" }}>
          Add New Event
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "transparent" }}>
          <TextField
            autoFocus
            margin="dense"
            id="event-name"
            label="Event Name"
            type="text"
            fullWidth
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
            variant="outlined"
            style={{ marginBottom: 10 }}
          />
          <Autocomplete
            multiple
            options={employee.map((employee) => ({
              label: `${employee.name} (${employee.subRole})`,
              _id: employee._id
            }))}
            getOptionLabel={(option) => option?.label || ''}
            value={selectedMember}
            onChange={(event, values) => setSelectedMember(values)}
            renderInput={(params) => (
              <TextField {...params} label="Search and Select" variant="outlined" />
            )}
            style={{ marginBottom: 10 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ background: theme.palette.button.secondary }}
          >
            Submit
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ReactBigCalendar;
