import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { CSVLink } from "react-csv";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Grid } from "@mui/material";
import { BiEditAlt, BiTransfer } from "react-icons/bi";
import DeleteIcon from "@mui/icons-material/Delete";
import theme from "../theme/theme";
import { visuallyHidden } from "@mui/utils";
import useGlass from "../theme/glassEffect";
import { useDispatch, useSelector } from "react-redux";
import { deleteLeadAction } from "../redux/action/admin/leads/deleteLead";
import { getLeadAction } from "../redux/action/admin/leads/getLead";
import { Button } from "@mui/base";
import { Link, useNavigate } from "react-router-dom";
import { editSelectData, updateLeadAction } from "../redux/action/admin/leads/editLead";
import LeadsAdd from "../components/navbar/SaveData/Leads";
import { leadSchema } from "../components/navbar/schemaInput/leadSchema";
import { useAuth } from "../redux/authContext";



export function createData(id, name, email, phone, owner, company, workAddress, leadType) {
  return { id, name, email, phone, owner, company, workAddress, leadType };
}
const ConfirmationDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the selected item(s)?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{  backgroundColor: theme.palette.button.secondary,
                padding: "10px 15px", border:'none',borderRadius:'10px',cursor:'pointer'}}>
          Cancel
        </Button>
        <Button onClick={onConfirm} color="error" style={{  backgroundColor: theme.palette.button.secondary,
                padding: "10px 15px",border:'none',borderRadius:'10px',cursor:'pointer'}}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {

  let { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="success"
            indeterminate={numSelected === rowCount && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  headCells: PropTypes.array.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  let {  refrence, data, selected ,fulldata } = props;
  const {employeeData} = useAuth()
  console.log(fulldata)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formatDataForCSV = (data) => {
    const formattedData = [...data];
    if (formattedData.length > 0) {
      formattedData[0] = formattedData[0].map(cell => ({ ...cell, style: { bold: true } }));
    }
  
    return formattedData;
  };
  const handleDeleteClick = () => {
    setOpenDialog(true);
  };

  const handleConfirmDelete = async() => {
    const employeeIds = [employeeData._id]
    employeeData.junior?.map((doc)=>{
        employeeIds.push(doc)
    })
    await dispatch(deleteLeadAction(selected));
    await dispatch(getLeadAction(employeeIds));
    selected = []
    navigate('/')
    setOpenDialog(false);
  };
  
  const handleCancelDelete = () => {
    setOpenDialog(false);
  };
  const handleEdit = ()=>{
   
    const data = fulldata
    const selectedLead = fulldata.find(item => item._id === selected[0]);
    console.log(selected[0].id,'fuck',fulldata[0]._id)
    if (selectedLead) {
      dispatch(editSelectData(selectedLead.leads[0])); // Assuming editSelectData is defined correctly
    }
    setOpen(!open)
  }
  const handelUpdate = async(shouldClose, data)=>{
    try {
      const employeeIds = [employeeData._id]
      employeeData.junior?.map((doc)=>{
          employeeIds.push(doc)
      })
      const updateData = await dispatch(updateLeadAction(data,selected[0]));
      await dispatch(getLeadAction(employeeIds));
      if(data){
        if (shouldClose) {
          setOpen(false); 
        }
      }
     
    } catch (error) {
      console.error("Error while saving:", error);
      
    }
  }
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <>
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(selected.length > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {selected.length > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {selected.length} selected
        </Typography>
      ) : (
        <Grid container justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
          <Grid item>
            <Typography variant="h6" id="tableTitle" component="div">
              {refrence}
            </Typography>
          </Grid>
          <Grid item>
            <CSVLink
              filename="Leads"
              
              data={data}
              style={{
                color: "white",
                backgroundColor: theme.palette.button.secondary,
                padding: "10px 15px",
                marginRight: "20px",
                fontWeight: "100",
                borderRadius: 5,
                textDecoration: "none",
              }}
            >
              Download
            </CSVLink>
          </Grid>
        </Grid>
      )}

      {selected.length > 0 && (
        <>
          <Tooltip title="Edit Customer">
          <IconButton disabled={selected.length > 1} onClick={handleEdit}>
        <BiEditAlt />
      </IconButton>
          
          </Tooltip>
        </>
      )}
       <ConfirmationDialog
        open={openDialog}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </Toolbar>
    <Drawer
    open={open}
    onClose={toggleDrawer(false)}
    anchor="right"
    sx={{
      "& .css-1160xiw-MuiPaper-root-MuiDrawer-paper": {
        minWidth: "30%",
        
      },
      backgroundColor: useGlass.glass.background,
      boxShadow: useGlass.glass.boxShadow,
      backdropFilter: useGlass.glass.backdropFilter,
      WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
      borderRadius: useGlass.glass.borderRadius,
      border: useGlass.glass.border,
    }}
  >
    <Grid
      
    >
      <LeadsAdd schema={leadSchema} updated={handelUpdate} />
    </Grid>
  </Drawer></>
  );
}


export default function CustomerTable({ rows, headCells, refrence, data,TransitionTable }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const { loading } = useSelector((state) => state.deleteLeade);
  const { totalLeads } = useSelector((state) => state.getLeadData);
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (!loading) {
      setSelected([]);
    }
  
  }, [loading]);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
 
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
    dispatch(editSelectData(null))
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    if (newSelected.length === 0) {
      dispatch(editSelectData(null)); // Reset selected data for editing
    }
    
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Slice the visible rows based on pagination
  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [rows, order, orderBy, page, rowsPerPage]
  );

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  return (
    <Box sx={{ maxWidth: "100%", minWidth: "100%", height:'100%'  }}>
      <Paper
        sx={{
          maxWidth: "100%",
          mb: 2,
          height:'100%',
          backgroundColor: theme.palette.background.primary,
        }}
      >
        <EnhancedTableToolbar
          fulldata={data}
          refrence={refrence}
          data={rows}
          selected={selected}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer", color: theme.palette.text.primary }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="success"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      align="left"
                      color="black"
                    >
                      <Link to={`/crm/customer/${row.id}`} style={{ color: 'black', textDecoration: 'none' }}>{row.name}</Link>
                    </TableCell>
                    <TableCell align="right">{row.email}</TableCell>
                    <TableCell align="right">{row.phone}</TableCell>
                    <TableCell align="right">{row.owner}</TableCell>
                    <TableCell align="right">{row.company}</TableCell>
                    <TableCell align="right">{row.workAddress}</TableCell>
                    <TableCell align="right" sx={{display: !TransitionTable ? 'block' : 'none'}}>{row.leadType}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          
        />
      </Paper>

     
    </Box>
  );
}
