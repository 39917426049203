import axios from 'axios';
import {
    SELECT_DATA_REQUEST,
    SELECT_DATA_SUCCESS,
    SELECT_DATA_FAIL,
    UPDATE_DATA_REQUEST,
    UPDATE_DATA_SUCCESS,
    UPDATE_DATA_FAIL,
    CATCH_ERROR
 } from '../../../constents/admin/lead.js'
import { url } from '../../../../utils/server.js';
import { getAuthHeaders } from '../../../../utils/token.js';

export const editSelectData = (editAbleData) => async (dispatch) => {
    
    try {
        dispatch({
            type: SELECT_DATA_REQUEST
        });
        
        dispatch({
            type: SELECT_DATA_SUCCESS,
            payload:editAbleData
        });
    } catch (error) {
        console.log(error)
        dispatch({
            
            type: SELECT_DATA_FAIL,
            payload: error.message
        });
    }
};

export const updateLeadAction = (inputdata,id) => async (dispatch) => {
    const validetData = {
           id:id,
           name:inputdata.name,
           email:inputdata.email,
           phoneNumber:inputdata.phoneNumber,
           source:inputdata.source,
           companyName:inputdata.companyName,
           city:inputdata.city,
           pinCode:inputdata.pinCode,
           companyAddress:inputdata.companyAddress,
           companyWebLink:inputdata.companyWebLink,
           projectName:inputdata.projectName,
           remark:inputdata.remark,
           projectLanguage:inputdata.projectLanguage
    }
    try {
        const axiosConfig = getAuthHeaders();
        dispatch({
            type: UPDATE_DATA_REQUEST
        });
        console.log("helloooooo", validetData)
        const apiurl = `${url}/admin/lead/update`; 
        const { data } = await axios.post(apiurl,  validetData,axiosConfig); 
        console.log(data);

        dispatch({
            type: UPDATE_DATA_SUCCESS,
            payload:data.data.success
        });
    } catch (error) {
        console.log(error)
        dispatch({
            
            type: UPDATE_DATA_FAIL,
            payload: error.message
        });
    }
};




export const clearError = () => async (dispatch) => {
    dispatch({
        type: CATCH_ERROR
    });
};
