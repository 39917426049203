import { Button } from '@mui/base';
import { Box, Grid, TextField, Autocomplete, FormControlLabel, Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import theme from '../../theme/theme';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import { useAuth } from '../../redux/authContext';
import { useNavigate, useParams } from 'react-router-dom';
import { url } from '../../utils/server';
import { getAuthHeaders } from '../../utils/token';
import { useSelector } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner'; // Import the ThreeDots spinner

function SessionForm({saved}) {
    const [meetingValue, setMeetingValue] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
  
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const { employeeData } = useAuth();
    const [employees, setEmployees] = useState([]);
    const axiosConfig = getAuthHeaders();
    const { leadsData } = useSelector((state) => state.getLeadData);
    const { id } = useParams();
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isDone, setIsDone] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const navigate = useNavigate()
    const filteredLead = leadsData?.filter(data => data._id === id);
    const client = filteredLead[0]?.leads[0][0];

    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                const response = await axios.get(`${url}/admin/employee/departments`, axiosConfig);
                const { employee } = response.data;
                setEmployees(employee);
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        };
        fetchDepartments();
    }, [employeeData]);

    const handleMeetingCreate = async () => {
        setLoading(true); // Set loading to true
        const token = localStorage.getItem('access_token');
        
        const clientData = {
            name: client?.name,
            phoneNumber: client?.phoneNumber,
            email: client?.email,
        };

        const data = {
            topic: meetingValue,
            token: token,
            startTime: `${date}T${time}:00Z`,
            
            sessionBy: employeeData._id,
            leadId: id,
            employees,
            done: isDone,
            clientData,
        };

        try {
            const createMeeting = await axios.post(`${url}/create-meeting`, data);
            console.log(createMeeting);
            setSnackbarMessage('Meeting created successfully!');
            setSnackbarOpen(true);
            setDate('');
            setTime('');
            setMeetingValue('');
            setSelectedEmployees([]);
            saved(true)
            navigate(`/leads/${id}`)
        } catch (error) {
            console.error('Error creating meeting:', error.response ? error.response.data : error.message);
            setSnackbarMessage('Failed to create meeting');
            setSnackbarOpen(true);
           
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity="success" // or 'error', 'warning', 'info'
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
            <Box sx={{ width: '100%', marginTop: '-50px', position: 'relative' }}>
                {loading && (
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent white
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 9999, // Ensure it's on top
                        }}
                    >
                        <ThreeDots color="green" height={80} width={80} />
                    </Box>
                )}
                <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' ,ml:'20px'}}>
                    <TextField
                        sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                                color: 'white',
                            },
                            '& .MuiInputLabel-root': {
                                color: 'white',
                            },
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                          }}
                        type='time'
                        name='time'
                        label='Meeting time'
                        id='time'
                        inputProps={{
                            step: 300,  // 5 minute increments
                            pattern: '[0-9]{2}:[0-9]{2}',  // Regex pattern for 24-hour format HH:mm
                        }}
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                       sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                            color: 'white',
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white',
                        },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                      }}
                        type='date'
                        name='date'
                        id='date'
                        onChange={(e) => setDate(e.target.value)}
                    />
                    
                    <TextField
                        sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                                color: 'white',
                            },
                            '& .MuiInputLabel-root': {
                                color: 'white',
                            },
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                          }}
                        type='text'
                        name='meetingDesc'
                        placeholder='Describe about meeting'
                        multiline
                        rows={5}
                        id='meetingDesc'
                        onChange={(e) => setMeetingValue(e.target.value)}
                    />
                    <Autocomplete
                        sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                                color: 'white',
                            },
                            '& .MuiInputLabel-root': {
                                color: 'white',
                            },
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                          }}
                        multiple
                        options={employees}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => setSelectedEmployees(value)}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Select Employees" placeholder="Employee Names" />
                        )}
                    />
                    <FormControlLabel
                        control={<Checkbox name="done" checked={isDone} onChange={(e) => setIsDone(e.target.checked)} />}
                        label="Forward To Session"
                    />
                    <Button style={{
                        padding: "10px 20px",
                        borderRadius: "5px",
                        border: "none",
                        background: theme.palette.button.secondary,
                        color: "white",
                        marginTop: '10px',
                        cursor: "pointer",
                    }} onClick={handleMeetingCreate} disabled={loading}>
                        Send Meeting Mail
                    </Button>
                </Grid>
            </Box>
        </>
    );
}

export default SessionForm;
