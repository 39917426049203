import { Box, Grid, Typography, Select, MenuItem, Button, InputLabel, Card, CardContent, Dialog } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import useGlass from '../../theme/glassEffect';
import { useAuth } from '../../redux/authContext';
import axios from 'axios'; // Don't forget to import axios if not already imported
import { url } from '../../utils/server';
import ReactPlayer from 'react-player'
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLeadAction } from '../../redux/action/admin/leads/getLead';
import theme from '../../theme/theme';
import { socket } from '../../AppContent';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { FormControl } from '@mui/base';
import ActivityNav from './AcitvityNav';
import { getAuthHeaders } from '../../utils/token';
import { ThreeDots } from 'react-loader-spinner';
import { BiLoaderCircle, BiPhoneCall } from 'react-icons/bi';
import { MdMeetingRoom, MdPowerSettingsNew } from 'react-icons/md';
import { GiExplosiveMeeting, GiTalk } from 'react-icons/gi';
import { GrDocument, GrDocumentCloud } from 'react-icons/gr';
import { BsFileSlides, BsFiles } from 'react-icons/bs';

function LeadAssiment({ data, lead, saved }) {
  const [employees, setEmployees] = useState([]);
  const { leadsData, totalPoint } = useSelector((state) => state.getLeadData)
  const { employeeData } = useAuth();
  const [selectedValue1, setSelectedValue1] = React.useState('');
  const axiosConfig = getAuthHeaders();
  const [assignedLead, setAssignedLead] = useState([]);
  const [openAddActivity, setopenAddActivity] = useState(false)
  const [leadCustomDetail, setleadCustomDetails] = useState()
  const [days, setDays] = useState(1)
  const { id } = useParams()
  const [leadDetails, setLeadDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const callRef = useRef(null);
  const [save, setSave] = useState(false)
  const bookingRef = useRef(null);
  const sessionRef = useRef(null);
  const documentationRef = useRef(null);
  const paymentRef = useRef(null);
  const successRef = useRef(null);


  const handlesave = () => {
    setSave(true)
    saved(true)
    setopenAddActivity(false)

  }
  const handleScrollToCard = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  const toggleDrawer = (open) => () => {
    setopenAddActivity(open);
  };
  useEffect(() => {

    const fetchLeadDetails = async () => {

      try {
        const response = await axios.post(`${url}/admin/lead-details/${id}`, { days }, axiosConfig);
        setLeadDetails([response.data.data]);
        setleadCustomDetails(response.data.result)
        setSave(false)
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLeadDetails();
  }, [save]);

  const filteredLead = leadsData?.filter(data => data._id === id)
  const clientData = filteredLead[0]?.leads[0][0]
  const assign = async () => {
    setLoading(true)
    const assign = await axios.get(
      `${url}/admin/lead/get-details/${id}`,
      axiosConfig
    );
    setAssignedLead(assign?.data)

  }

  useEffect(() => {

    const fetchDepartments = async () => {

      try {
        const response = await axios.get(`${url}/admin/employee/departments`, axiosConfig);

        const { employee } = response.data;
        setEmployees(employee);

      } catch (error) {
        console.error('Error fetching departments:', error);
      } finally {
        setLoading(false)
      }
    };


    assign()
    fetchDepartments();


  }, [employeeData, id]);

  console.log(leadCustomDetail)
  const matchFullDate = (dateString, isoString) => {
    // Convert the date string to a Date object
    const date = new Date(dateString);

    // Extract the year, month, and day from the ISO string
    const isoDate = new Date(isoString);

    // Check if the day, month, and year match
    const isMatch = (date.getFullYear() === isoDate.getUTCFullYear()) &&
      (date.getMonth() === isoDate.getUTCMonth()) &&
      (date.getDate() === isoDate.getUTCDate());

    return isMatch;
  };
  const currentEmployee = employees.find(emp => emp._id === employeeData._id);
  const filteredEmployees = employees.filter(emp =>
    currentEmployee.junior && currentEmployee.junior.includes(emp._id)
  )
  function formatDateToDDMMYYYY(isoDateString) {
    const date = new Date(isoDateString);

    // Extract day, month, and year components
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() returns 0 for January, 1 for February, etc.
    const year = date.getFullYear();

    // Create the formatted date string
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }
  const handleAssignLead = async () => {
    setLoading(true)
    const leadId = id


    const payload = {
      to: selectedValue1,
      from: employeeData._id,
      leadId: leadId
    };

    try {
      const response = await axios.post(`${url}/admin/lead/transfer-lead`, payload, axiosConfig);
      console.log('Lead assigned successfully:', response.data);
      assign()

    } catch (error) {
      console.error('Error assigning lead:', error);
    }
    finally {
      setLoading(false)
    }
  };

  const dateList = [];
  const date = new Date();
  // Generate a list of dates for the past week
  for (let i = 0; i < days; i++) {
    const pastDate = new Date(date || 2);
    pastDate.setDate(date.getDate() - i);
    dateList.push(pastDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric', day: '2-digit' }));
  }


  return (
    <>

      <Box width={'90%'} sx={{
        background: 'white',
        display: 'flex',
        width: '90%',
        minHeight: '370px',
        marginTop: '0px',
        backgroundColor: 'rgba( 255, 255, 255, 0.98 )',
        boxShadow: useGlass.glass.boxShadow,
        backdropFilter: useGlass.glass.backdropFilter,
        WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
        borderRadius: useGlass.glass.borderRadius,
        border: useGlass.glass.border,
        position: 'relative',
        overflow: 'hidden'
      }}>

        <Grid container minHeight={'100%'} width={'30%'} borderRight={'1px solid grey'}>
          <Box
            sx={{
              width: '100%',
              height: '450px',

              display: 'flex',
              flexDirection: 'column',

            }}
          >
            <Grid
              container
              alignItems="center"
              width={'100%'}
              sx={{
                height: '50px',
                borderBottom: '1px solid grey',
                backgroundColor: '#ffff',

              }}
            >
              <Typography variant="h6" sx={{ padding: '10px', height: "50px" }}>
                Lead Transfer History
              </Typography>
            </Grid>
            <Grid
              container
              flexDirection={'column'}
              gap={'1px'}
              alignItems={'center'}
              marginTop={'20px'}
              minHeight="calc(30% - 50px)"
              width={'300px'}
              maxHeight="calc(50% - 50px)"
              overflow={'scroll'} // Adjusted height to fit remaining space after header
              sx={{ padding: '20px', overflowY: 'auto' }}
            >
              {/* First Select field */}
              <Select
                value={selectedValue1}
                onChange={(event) => setSelectedValue1(event.target.value)}
                displayEmpty
                fullWidth
                sx={{ marginBottom: '10px', width: '270px', height: '40px' }}
              >
                <MenuItem value="">Select Team Member</MenuItem>
                {filteredEmployees.map(emp => (
                  <MenuItem key={emp._id} value={emp._id}>
                    {emp.name} {/* Adjust based on your employee object */}
                  </MenuItem>
                ))}
              </Select>



              {/* Button to Assign Lead */}
              <Button variant="contained" onClick={handleAssignLead} sx={{ height: '30px', width: '270px', background: theme.palette.button.secondary }}>
                {loading ? <BiLoaderCircle /> : 'Transfer Lead'}
              </Button>
            </Grid>
            <Grid overflow={'auto'} >
              {assignedLead.leadTransfer?.length > 0 ? (
                assignedLead.leadTransfer?.reverse().map((assign, index) => {
                  const fromEmployee = employees.find(emp => emp._id === assign.from);
                  const toEmplyoee = employees.find(emp => emp._id === assign.to);

                  return (
                    <Box key={index} margin={'20px'} sx={{
                      marginBottom: '10px', backgroundColor: 'rgba( 255, 255, 255, 0.98 )',
                      boxShadow: useGlass.glass.boxShadow,
                      backdropFilter: useGlass.glass.backdropFilter,
                      WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
                      borderRadius: useGlass.glass.borderRadius,
                      border: useGlass.glass.border, padding: '10px'
                    }}>
                      <Typography color={'black'}>
                        {`Lead Transfer from ${fromEmployee?.name} to ${toEmplyoee?.name}`}
                      </Typography>
                      <Typography color={'black'}>
                        {formatDateToDDMMYYYY(fromEmployee?.createdAt)}
                      </Typography>
                    </Box>
                  );
                })
              ) : (
                <Typography>No records yet</Typography>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid width={'70%'}>


          <Box minWidth={'300px'} overflow={'hidden'}>
            <Grid
              container
              alignItems="center"
              sx={{
                height: '60px',
                borderBottom: '1px solid grey',
                display: 'flex',
                flexDirection: "column",
                alignContent: 'start',
                paddingLeft: '10px',
                gap: '0px',
                justifyContent: 'center',
                overflow: 'hidden',
                backgroundColor: '#ffff'
              }}
            >

              <Typography variant="h6" sx={{ padding: '10px' }}>Activity History</Typography>
              <Grid sx={{
                display: 'flex',
                gap: '5px',

              }}>
                <Grid >
                  <Typography sx={{
                    padding: '5px 8px',
                    borderRadius: '10px',
                    background: assignedLead?.created === true ? '#00A550' : '#eb3434',
                    color: assignedLead?.created === true ? 'white' : 'white',
                    fontSize: '12px'
                  }}>
                    Created
                  </Typography>

                </Grid>
                <Grid >
                  <Typography
                    onClick={() => handleScrollToCard(callRef)}
                    sx={{
                      padding: '5px 8px',
                      borderRadius: '10px',
                      background: assignedLead?.call?.finalStatus ? '#00A550' : '#eb3434',
                      color: assignedLead?.call?.status ? 'white' : 'white',
                      fontSize: '12px',
                      cursor: 'pointer'

                    }}>
                    Call
                  </Typography>

                </Grid>
                <Grid >
                  <Typography
                    onClick={() => handleScrollToCard(bookingRef)}
                    sx={{
                      padding: '5px 8px',
                      borderRadius: '10px',
                      background: assignedLead?.session?.finalStatus ? '#00A550' : '#eb3434',
                      color: assignedLead?.booking === true ? 'white' : 'white',
                      fontSize: '12px',
                      cursor: 'pointer'
                    }}>
                    Booking
                  </Typography>

                </Grid>
                <Grid >
                  <Typography
                    onClick={() => handleScrollToCard(sessionRef)}
                    sx={{
                      padding: '5px 8px',
                      borderRadius: '10px',
                      background: assignedLead?.booking?.finalStatus ? '#00A550' : '#eb3434',
                      color: assignedLead?.session === true ? 'white' : 'white',
                      fontSize: '12px',
                      cursor: 'pointer'
                    }}>
                    Session
                  </Typography>

                </Grid>
                <Grid >
                  <Typography
                    onClick={() => handleScrollToCard(documentationRef)}
                    sx={{
                      padding: '5px 8px',
                      borderRadius: '10px',
                      background: assignedLead?.documentation?.finalStatus ? '#00A550' : '#eb3434',
                      color: assignedLead?.documentation === true ? 'white' : 'white',
                      fontSize: '12px',
                      cursor: 'pointer'
                    }}>
                    Documentation
                  </Typography>

                </Grid>
                <Grid >
                  <Typography
                    onClick={() => handleScrollToCard(paymentRef)}
                    sx={{
                      padding: '5px 8px',
                      borderRadius: '10px',
                      background: assignedLead?.paymentHistory?.finalStatus ? '#00A550' : '#eb3434',
                      color: assignedLead?.payment === true ? 'white' : 'white',
                      fontSize: '12px',
                      cursor: 'pointer'
                    }}>
                    Payment
                  </Typography>

                </Grid>
                <Grid >
                  <Typography sx={{
                    padding: '5px 8px',
                    borderRadius: '10px',
                    background: assignedLead?.success ? '#00A550' : '#eb3434',
                    color: assignedLead?.created === true ? 'white' : 'white',
                    fontSize: '12px'
                  }}>
                    Success
                  </Typography>

                </Grid>


              </Grid>
            </Grid>
            <Grid sx={{
              height: '50px',
              gap: '0px',
              overflow: 'hidden',
              borderBottom: '1px solid grey',
              display: 'flex',
              alignItems: 'center',

              marginBottom: '16px',
              background: 'white'
            }}>

              <Button onClick={toggleDrawer(true)} sx={{ padding: '5px 8px', borderRadius: '5px', background: '#00A550', color: 'white', marginLeft: '10px' }}>
                Add Activity
              </Button>

            </Grid>
            <Grid height={'300px'} overflow={'scroll'} container spacing={2}>
              <Grid>
                <Grid padding={'50px'}>
                  {dateList.map((dateStr, index) => (
                    <Grid sx={{ width: '100%', display: 'flex', gap: '20px' }}>
                      <Grid width={'20%'}>
                        <Typography key={index} padding={'40px'} width={'100px'}>
                          {dateStr}
                        </Typography>
                      </Grid>

                      <Grid>
                        {leadCustomDetail ? (
                          (() => {
                            const filteredData = leadCustomDetail.details?.filter((item) => {
                              return matchFullDate(dateStr, item.createdAt);
                            });

                            return filteredData && filteredData.length > 0 ? (
                              <Grid container direction="column" >
                                {filteredData.reverse().map((item, index) => {
                                  let content;
                                  if ('callTime' in item) {
                                    const findEmployee = employees.find(emp => emp._id === item.calledBy);
                                    content = findEmployee ? (
                                      <Typography sx={{ marginTop: '0px' }}>
                                        <BiPhoneCall />  {`${findEmployee.name} called ${clientData?.name} at ${item.callTime} to discuss ${item.callDetails}`}
                                      </Typography>
                                    ) : null;
                                  } else if ('sessionDate' in item) {
                                    const findEmployee = employees.find(emp => emp._id === item.sessionBy);
                                    const formattedTime = item.sessionDate ? new Date(item.createdAt).toUTCString().split(' ')[4].slice(0, 5) : null;
                                    content = (
                                      <Typography>
                                        <GiTalk /> {`${findEmployee?.name} held a session with ${clientData?.name} at ${formattedTime} to discuss ${item.sessionDes}`}
                                      </Typography>
                                    );
                                  } else if ('bookingDate' in item) {
                                    const findEmployees = employees.filter(emp => item.attenders.includes(emp._id));
                                    const formattedTime = item.bookingDate ? new Date(item.bookingDate).toISOString().split('T')[1].slice(0, 5) : null;
                                    content = (
                                      <Typography>
                                        <MdMeetingRoom /> {`${findEmployees.map(emp => emp.name).join(', ')} booked a meeting with ${clientData?.name} at ${formattedTime} in the meeting room.`}
                                        <Link to={item.bookingLink}>{item.bookingLink}</Link>
                                      </Typography>
                                    );
                                  } else if ('documentation' in item) {
                                    const filesContent = item.files?.map((file, fileIndex) => (
                                      <div key={fileIndex} style={{ marginBottom: '10px' }}>
                                        {file.format === 'pdf' ? (
                                          <a href={file.url} target="_blank" rel="noopener noreferrer">
                                            <img
                                              src="/path/to/pdf-icon.png" // Use a PDF icon here
                                              alt="PDF file"
                                              style={{ width: '100px', height: '100px', borderRadius: '4px', marginRight: '10px' }}
                                            />
                                          </a>
                                        ) : file.format === 'mp4' ? (
                                          <ReactPlayer url={file.url} controls={true} width={'90%'} />
                                        ) : file.format === 'mp3' ? (
                                          <AudioPlayer
                                            src={file.url}

                                          />
                                        ) : (
                                          <img
                                            src={file.url} // Default handling for images
                                            alt={'file'}
                                            style={{ width: '200px', height: '200px', borderRadius: '4px', marginRight: '10px' }}
                                          />
                                        )}
                                      </div>
                                    ));

                                    content = (
                                      <div>

                                        <Typography>
                                          <BsFileSlides />  {item.documentation}
                                        </Typography>
                                        {filesContent}
                                      </div>
                                    );
                                  }
                                  return <><Grid item key={index} sx={{
                                    width: '90%',
                                    marginTop: '70px'
                                  }}>{content}</Grid>

                                  </>
                                })}
                                <Grid sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <Button sx={{ cursor: 'pointer' }} onClick={() => setDays(days + 1)}>View more</Button>
                                </Grid>
                              </Grid>
                            ) : ''
                          })()
                        ) : (
                          <Typography>No data found</Typography>
                        )}
                      </Grid>
                    </Grid>

                  ))}
                </Grid>

              </Grid>

            </Grid>
          </Box>


        </Grid>

      </Box>

      <Dialog
        open={openAddActivity}
        onClose={toggleDrawer(false)}
        sx={{

          '& .MuiDialog-paper': {
            width: '100%',
            height: '100%',
            margin: 0, // Remove default margin
            backgroundColor: useGlass.glass.background,
            boxShadow: useGlass.glass.boxShadow,
            backdropFilter: useGlass.glass.backdropFilter,
            WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
            borderRadius: useGlass.glass.borderRadius,
            border: useGlass.glass.border,
          },
        }}
      >
        <Box sx={{
          width: '100%',
          height: '100%',
          marginLeft: -5,
          padding: 0,
        }}>
          <ActivityNav saved={handlesave} />
        </Box>
      </Dialog>

    </>
  );
}

export default LeadAssiment;
