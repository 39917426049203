import React from 'react';
import { Button, Container, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Divider, Box, Grid } from '@mui/material';
import jsPDF from 'jspdf';

const Payslip = ({ employee, open, closePay }) => {
    
    const calculateFinalSalary = () => {
        const baseSalary = employee?.salery?.baseSalary || 0;
        const allowances = employee?.salery?.allowance || [];
        const commissions = employee?.salery?.commission || [];
        const deductions = employee?.salery?.deduction || [];
        const totalDeductions = deductions.reduce((total, deduction) => total + Number(deduction.deductionAmount || 0), 0);
    
        const totalAllowances = allowances.reduce((total, allowance) => total + Number(allowance.allowanceAmount || 0), 0);
        const totalCommissions = commissions.reduce((total, commission) => total + Number(commission.commissionAmount || 0), 0);
    
        const netSalary = baseSalary + totalAllowances + totalCommissions;
        const totalSalary = netSalary - totalDeductions;
    
        return {
            netSalary: netSalary,
            totalSalary: totalSalary
        };
    };

  const generatePayslip = () => {
    const doc = new jsPDF();
  
    // Title
    doc.setFontSize(24);
    
    doc.setTextColor(0, 102, 204);
    doc.text("Payslip", 100, 10);
  
    // Employee Details
    doc.setFontSize(10);
    doc.setTextColor(0);
    doc.text(`Name: ${employee?.name}`, 20, 15);
    doc.text(`Email: ${employee?.email}`, 20, 20);
    doc.text(`Position: ${employee?.position}`, 20, 25);
    doc.text(`Department: ${employee?.department}`, 20, 30);
    
    doc.setFontSize(10);
    doc.setTextColor(0, 102, 204);
    doc.text("Account Details:", 20, 40);
  
    doc.setFontSize(10);
    doc.setTextColor(0);
    doc.text(`Account Number: ${employee?.salery?.accountNumber}`, 20, 45);
    doc.text(`Bank Name: ${employee?.salery?.bankName}`, 20, 50);
    doc.text(`IFSC Code: ${employee?.salery?.ifscCode}`, 20,55);
    // Salary Details
    doc.setFontSize(10);
    doc.setTextColor(0, 102, 204);
    doc.text("Salary Details", 20, 65);
  
    doc.setFontSize(10);
    doc.setTextColor(0);
    doc.text(`Base Salary: ₹${employee?.salery?.baseSalary}`, 20, 70);
    
  
    // Allowances
    let yOffset = 80;
    doc.setFontSize(10);
    doc.setTextColor(0, 102, 31);
    doc.text("Allowances:", 20, yOffset);
  
    doc.setFontSize(10);
    doc.setTextColor(0);
    let allowancesText = '';
    employee?.salery?.allowance.forEach((allowance, index) => {
      allowancesText += `${allowance.allowanceName}: ₹${allowance.allowanceAmount}\n`;
    });
    const splitAllowances = doc.splitTextToSize(allowancesText, 180);
    doc.text(splitAllowances, 20, yOffset + 5);
  
    // Commissions
    yOffset += splitAllowances.length * 7;
    doc.setFontSize(10);
    doc.setTextColor(0, 102, 204);
    doc.text("Commissions:", 20, yOffset);
  
    doc.setFontSize(10);
    doc.setTextColor(0);
    let commissionsText = '';
    employee?.salery?.commission.forEach((commission, index) => {
      commissionsText += `${commission.commissionName}: ₹${commission.commissionAmount}\n`;
    });
    const splitCommissions = doc.splitTextToSize(commissionsText, 180);
    doc.text(splitCommissions, 20, yOffset + 5);
    doc.text(`Net Salary: ₹${calculateFinalSalary().netSalary}`, 20, yOffset + 10);
    // Deductions
    yOffset += splitCommissions.length *  10;
    doc.setFontSize(10);
    doc.setTextColor(0, 102, 204);
    doc.text("Deductions:", 20, yOffset);
  
    doc.setFontSize(10);
    doc.setTextColor(0);
    let deductionsText = '';
    employee?.salery?.deduction.forEach((deduction, index) => {
      deductionsText += `${deduction.deductionName}: ₹${deduction.deductionAmount}\n`;
    });
    const splitDeductions = doc.splitTextToSize(deductionsText, 180);
    doc.text(splitDeductions, 20, yOffset + 5);
    doc.setFontSize(24);
    doc.setTextColor(0, 102, 204);
    doc.text(`Total Salary: ₹${calculateFinalSalary().totalSalary}`, 20, yOffset + 25);
    // Account Details
   
  
    // Footer
    yOffset += 30;
    doc.setFontSize(10);
    doc.setTextColor(150);
    doc.text("Generated on: " + new Date().toLocaleDateString(), 20, yOffset);
  
    doc.save(`${employee?.name}_payslip.pdf`);
    handleClose();
  };
  

  const handleClose = () => {
    closePay(false);
  };

  return (
    <Container>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle align="center" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', backdropFilter: 'blur(10px)', boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
          <Typography variant="h5" style={{ fontWeight: 'bold', color: '#333' }}>Download Payslip</Typography>
        </DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(255, 255, 255, 0.8)', backdropFilter: 'blur(10px)', padding: '16px', borderRadius: '8px' }}>
          <Typography variant="h6" align="center" gutterBottom>Payslip Details</Typography>
          <Divider style={{ marginBottom: '16px' }} />
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="body1"><strong>Name:</strong> {employee?.name}</Typography>
            <Typography variant="body1"><strong>Email:</strong> {employee?.email}</Typography>
            <Typography variant="body1"><strong>Position:</strong> {employee?.position}</Typography>
            <Typography variant="body1"><strong>Department:</strong> {employee?.department}</Typography>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              <strong>Base Salary:</strong> ₹{employee?.salery?.baseSalary}
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              <strong>Net Salary:</strong> ₹{calculateFinalSalary().netSalary}
            </Typography>
            <Grid sx={{
                display:'flex',
                justifyContent:'space-around'
            }}>
            <Grid>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Allowances:</Typography>
            {employee?.salery?.allowance.map((allowance, index) => (
              <Typography key={index}>{allowance.allowanceName}: ₹{allowance.allowanceAmount}</Typography>
            ))}
            </Grid>
            <Grid>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Commissions:</Typography>
            {employee?.salery?.commission.map((commission, index) => (
              <Typography key={index}>{commission.commissionName}: ₹{commission.commissionAmount}</Typography>
            ))}</Grid>
           <Grid>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Deductions:</Typography>
            {employee?.salery?.deduction.map((deduction, index) => (
              <Typography key={index}>{deduction.deductionName}: ₹{deduction.deductionAmount}</Typography>
            ))}
            </Grid>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              <strong>Total Salary:</strong> ₹{calculateFinalSalary().totalSalary}
            </Typography>
</Grid>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Account Details:</Typography>
            <Typography variant="body1">Account Number: {employee?.salery?.accountNumber}</Typography>
            <Typography variant="body1">Bank Name: {employee?.salery?.bankName}</Typography>
            <Typography variant="body1">IFSC Code: {employee?.salery?.ifscCode}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={generatePayslip} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Payslip;
