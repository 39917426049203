import { EmojiPeopleRounded, GroupAddRounded, PeopleAlt } from '@mui/icons-material'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { GiRamProfile } from 'react-icons/gi'
import { MdGroupWork, MdGroups, MdPeople } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { getEmployee } from '../../../redux/action/admin/employee/get'

function FilterDepartment() {
    const dispatch = useDispatch()
    const [IT, setIT] = useState([])
    const [sales, setSales] = useState([])
    const [design, setDesign] = useState([])
    const [managment, setMangement] = useState([])
    const { data } = useSelector((state) => state.getEmployee);
    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(getEmployee());
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
        const managmentFilter = data?.filter((emp) => emp.department === 'MANAGEMENT')
        setMangement(managmentFilter)
        const itFilter = data?.filter((emp) => emp.department === 'IT')
        setIT(itFilter)
        const designFilter = data?.filter((emp) => emp.department === 'DESIGNING')
        setDesign(designFilter)
        const salesFilter = data?.filter((emp) => emp.department === 'SALES')
        setSales(salesFilter)
    }, [dispatch]);
    return (
        <>

            <Box sx={{
                background: 'white',
                width: '100%',
                height: '575px',
                borderRadius: '20px',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                gap: '10px',
                alignItems: 'center',

            }}>
                <Typography mt={2} ml={2}>Department</Typography>
               
                    <Grid sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <IconButton>
                            <span style={{ fontSize: '13px', }}>IT</span> <MdPeople color='green' size={20} />
                        </IconButton>
                        <Typography>{IT?.length}</Typography>
                    </Grid>
                    <Grid sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                       
                    }}>
                        <IconButton>
                            <span style={{ fontSize: '13px', }}>Sales</span>    <MdPeople color='green' size={20} />
                        </IconButton>
                        <Typography >{sales?.length}</Typography>
                    </Grid>
                    <Grid sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <IconButton>
                            <span style={{ fontSize: '13px', }}>Designing</span>    <MdPeople color='green' size={20} />
                        </IconButton>
                        <Typography>{design?.length}</Typography>
                    </Grid>
                    <Grid sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <IconButton>
                            <span style={{ fontSize: '13px', }}>Management</span>    <MdPeople color='green' size={20} />
                        </IconButton>
                        <Typography>{managment?.length}</Typography>
                    </Grid>

                </Box>
           
        </>
    )
}

export default FilterDepartment