import axios from 'axios';
import {
    GET_LEAD_REQUEST,
    GET_LEAD_SUCCESS,
    GET_LEAD_FAIL,
    CATCH_ERROR
 } from '../../../constents/admin/lead.js'
import { url } from '../../../../utils/server.js';
import { getAuthHeaders } from '../../../../utils/token.js';

export const getLeadAction = (employeeIds) => async (dispatch) => {
    const axiosConfig = getAuthHeaders();
    console.log(employeeIds)
    try {
        dispatch({
            type: GET_LEAD_REQUEST
        });
       
        const apiurl = `${url}/admin/leads`; 
        const { data } = await axios.post(apiurl,{employeeIds},axiosConfig); 
        console.log(data.data,'hiiii')
       const Lead = data.data.leads.reverse()
       const totalLeads = data.data.total
       const totalPoint = data.data.leads
       
        dispatch({
            type: GET_LEAD_SUCCESS,
            payload:Lead,
            payloadCount:totalLeads,
            totalPoint
        });
    } catch (error) {
        console.log(error)
        dispatch({
            
            type: GET_LEAD_FAIL,
            payload: error.message
        });
    }
};

export const clearError = () => async (dispatch) => {
    dispatch({
        type: CATCH_ERROR
    });
};
