import axios from 'axios';
import { url } from '../../../../utils/server.js';
import {
    GET_EMPLOYEE_REQUEST,
    GET_EMPLOYEE_SUCCESS,
    GET_EMPLOYEE_FAIL,
    CATCH_ERROR
 } from '../../../constents/admin/employee.js'
import { getAuthHeaders } from '../../../../utils/token.js';


export const getEmployee = () => async (dispatch) => {
  
    try {
        dispatch({
            type: GET_EMPLOYEE_REQUEST
        });
        const axiosConfig = getAuthHeaders();
 
        const apiurl = `${url}/admin/employee/departments`; 
        const { data } = await axios.get(apiurl,axiosConfig); 
        console.log(data);

        dispatch({
            type: GET_EMPLOYEE_SUCCESS,
            payload:data.employee

        });
    } catch (error) {
        console.log(error)
        dispatch({
            
            type: GET_EMPLOYEE_FAIL,
            payload: error.message
        });
    }
};

export const clearError = () => async (dispatch) => {
    dispatch({
        type: CATCH_ERROR
    });
};
