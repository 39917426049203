import axios from 'axios';
import {
    ADD_LEAD_REQUEST,
    ADD_LEAD_SUCCESS,
    ADD_LEAD_FAIL,
    CATCH_ERROR
 } from '../../../constents/admin/lead.js'
import { url } from '../../../../utils/server.js';
import { useAuth } from '../../../authContext.jsx';
import { getAuthHeaders } from '../../../../utils/token.js';

export const addLeadAction = (inputdata,id) => async (dispatch) => {
    const axiosConfig = getAuthHeaders();
    const validetData = {
           name:inputdata.name,
           email:inputdata.email,
           phoneNumber:inputdata.phoneNumber,
           source:inputdata.source,
           owner:id,
           companyName:inputdata.companyName,
           city:inputdata.city,
           pinCode:inputdata.pinCode,
           companyAddress:inputdata.companyAddress,
           companyWebLink:inputdata.companyWebLink,
           projectName:inputdata.projectName,
           paymentAmount:inputdata.paymentAmount,
           remark:inputdata.remark,
           projectLanguage:inputdata.projectLanguage
    }
    try {
        dispatch({
            type: ADD_LEAD_REQUEST
        });
        console.log("helloooooo", validetData)
        const apiurl = `${url}/admin/lead/add`; 
        const { data } = await axios.post(apiurl,  validetData,axiosConfig); 
        console.log(data);

        dispatch({
            type: ADD_LEAD_SUCCESS,
            payload:data.data.success
        });
    } catch (error) {
        console.log(error)
        dispatch({
            
            type: ADD_LEAD_FAIL,
            payload: error.message
        });
    }
};

export const clearError = () => async (dispatch) => {
    dispatch({
        type: CATCH_ERROR
    });
};
