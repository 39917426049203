import { createStore, combineReducers, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk'; // Correct import

import { getLeadReducer } from './reducers/admin/leads/getLead';
import { deleteLeadReducer } from './reducers/admin/leads/deleteLead';
import { editAbleData, updateLeadData } from './reducers/admin/leads/editLead';
import { addLeadReducer } from './reducers/admin/leads/create';
import { createEmployeeReducer } from './reducers/admin/employee/create';
import { getEmployeeReducer } from './reducers/admin/employee/get';
import { notificationState } from './reducers/notification/notification';




const reducer = combineReducers({
       addLead:addLeadReducer,
       getLeadData:getLeadReducer,
       deleteLeade:deleteLeadReducer,
       editLeadData:editAbleData,
       updateLead:updateLeadData,
       createEmployee:createEmployeeReducer,
       getEmployee:getEmployeeReducer,
       notification:notificationState
});

const store = createStore(reducer, applyMiddleware(thunk));
export default store;
