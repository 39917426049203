import React, { useEffect, useState } from 'react';
import { Box, FormControl, TextField, Button, List, ListItem, ListItemText, LinearProgress, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { useAuth } from '../../redux/authContext';
import axios from 'axios';
import { url } from '../../utils/server';
import { useNavigate, useParams } from 'react-router-dom';
import { getAuthHeaders } from '../../utils/token';
import { ThreeDots } from 'react-loader-spinner'; // Import the 3D spinner

const Documentation = ({saved}) => {
    const [documentation, setDocumentation] = useState('');
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState([]);
    const { employeeData } = useAuth();
    const [isDone, setIsDone] = useState(false);
    const { id } = useParams();
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate()
    const handleChange = (event) => {
        const { value } = event.target;
        setDocumentation(value);
    };

    useEffect(() => {
        return () => {
            files.forEach((file) => URL.revokeObjectURL(file));
        };
    }, [files]);

    const handleFileChange = (event) => {
        setFiles(Array.from(event.target.files));
        setUploadProgress(new Array(event.target.files.length).fill(0));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (documentation.trim() === '' && files.length === 0) {
            alert('Please fill in all fields or upload a file');
            return;
        }

        setLoading(true); // Start loading
        try {
            const axiosConfig = getAuthHeaders();
            const data = new FormData();
            data.append('documentation', documentation);
            data.append('createBy', employeeData._id);
            data.append('isDone', isDone);
            files.forEach((file) => {
                data.append('files', file);
            });

            await axios.post(`${url}/admin/lead/documentation/${id}`, data, {
                ...axiosConfig,
                headers: {
                    ...axiosConfig.headers,
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    const percentCompleted = Math.round((loaded * 100) / total);
                    // Update progress for each file
                    setUploadProgress((prev) => {
                        const newProgress = [...prev];
                        newProgress[0] = percentCompleted; // Example for the first file
                        return newProgress;
                    });
                },
            });

            console.log('Upload successful');
            setDocumentation('');
            setFiles([]);
            setUploadProgress([]);
            saved(true)
            navigate(`/leads/${id}`)
        } catch (err) {
            console.error('Upload failed:', err);
        } finally {
            setLoading(false); // End loading
        }
    };

    const renderFilePreview = (file) => {
        const fileType = file.type.split('/')[0];

        if (fileType === 'image') {
            return <img src={URL.createObjectURL(file)} alt={file.name} style={{ width: '100px', height: 'auto', margin: '8px' }} />;
        } else if (fileType === 'video') {
            return (
                <video controls style={{ width: '100px', height: 'auto', margin: '8px' }}>
                    <source src={URL.createObjectURL(file)} type={file.type} />
                    Your browser does not support the video tag.
                </video>
            );
        } else if (fileType === 'audio') {
            return (
                <audio controls style={{ width: '100px', height: 'auto', margin: '8px' }}>
                    <source src={URL.createObjectURL(file)} type={file.type} />
                    Your browser does not support the audio tag.
                </audio>
            );
        } else if (fileType === 'application' && file.type.includes('pdf')) {
            return <iframe src={URL.createObjectURL(file)} title={file.name} style={{ width: '100px', height: 'auto', margin: '8px' }} />;
        } else {
            return <ListItemText primary={file.name} />;
        }
    };

    return (
        <Box sx={{ paddingLeft: 0, height: '100%', marginTop: '-70px', position: 'relative' }}>
            {loading && (
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000
                }}>
                    <ThreeDots 
                        height="80" 
                        width="80" 
                        radius="9"
                        color="green" 
                        ariaLabel="three-dots-loading" 
                        visible={true} 
                    />
                </Box>
            )}
            <FormControl sx={{ padding: '20px', width: '90%' }}>
                <TextField
                    label="Documentation"
                    name="documentation"
                    value={documentation}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    multiline
                    rows={10}
                    fullWidth
                    sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                            color: 'white',
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white',
                        },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        mb:2
                      }}
                />
                <FormControlLabel
                    control={<Checkbox checked={isDone} onChange={(e) => setIsDone(e.target.checked)} />}
                    label="Forward To Payment"
                />
                <Box
                    sx={{
                        border: '1px dashed rgba(255, 255, 255, 0.7)',
                        borderRadius: '4px',
                        padding: '16px',
                        textAlign: 'center',
                        marginBottom: '16px',
                        backgroundColor: 'transparent',
                        transition: 'background-color 0.3s ease',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                    }}
                >
                    <Typography variant="body1" color="white" gutterBottom>
                        Upload Files (Images, Audio, Video, PDF)
                    </Typography>
                    <input
                        type="file"
                        multiple
                        accept="audio/*,video/*,image/*,.pdf"
                        onChange={handleFileChange}
                        style={{ display: 'none', color:'white' }} // Hide the default input
                        id="file-upload"
                    />
                    <label htmlFor="file-upload">
                        <Button variant="outlined" component="span" sx={{ color: 'white', borderColor: 'white' }}>
                            Choose Files
                        </Button>
                    </label>
                </Box>

                <List sx={{ mb: 2 }}>
                    {files.map((file, index) => (
                        <ListItem key={index} sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                            {renderFilePreview(file)}
                            {uploadProgress[index] > 0 && (
                                <Box sx={{ width: '100%', marginTop: '8px', position: 'relative' ,zIndex:999999}}>
                                    <LinearProgress variant="determinate" value={uploadProgress[index]} sx={{ height: '10px', borderRadius: '5px' }} />
                                    <Box sx={{
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        right: '0',
                                        height: '10px',
                                        borderRadius: '5px',
                                        background: 'linear-gradient(90deg, rgba(255, 255, 0, 0.6) 0%, rgba(0, 255, 255, 0.6) 100%)',
                                        zIndex: 1,
                                        width: `${uploadProgress[index]}%`
                                    }} />
                                </Box>
                            )}
                        </ListItem>
                    ))}
                </List>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ mt: 2 }}
                >
                    Submit
                </Button>
            </FormControl>
        </Box>
    );
};

export default Documentation;
