import {
    ADD_EMPLOYEE_REQUEST,
    ADD_EMPLOYEE_SUCCESS,
    ADD_EMPLOYEE_FAIL,
    CATCH_ERROR
 } from '../../../constents/admin/employee.js'
import { url } from '../../../../utils/server.js';
import axios from 'axios';
import { getAuthHeaders } from '../../../../utils/token.js';

export const createEmployee = (inputdata) => async (dispatch) => {
  
    try {
        dispatch({
            type: ADD_EMPLOYEE_REQUEST
        });
        console.log("helloooooo", inputdata)
        const axiosConfig = getAuthHeaders();
        const apiurl = `${url}/admin/employee/add`; 
        const { data } = await axios.post(apiurl,  inputdata,axiosConfig); 
        console.log(data);

        dispatch({
            type: ADD_EMPLOYEE_SUCCESS,
            payload:data
        });
        return data
    } catch (error) {
        console.log(error)
        dispatch({
            
            type: ADD_EMPLOYEE_FAIL,
            payload: error.message
        });
    }
};

export const clearError = () => async (dispatch) => {
    dispatch({
        type: CATCH_ERROR
    });
};
