import React, { useEffect, useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip, Button, Grid } from '@mui/material';
import { MdArrowBack, MdArrowForward, MdCheckCircleOutline, MdHighlightOff } from 'react-icons/md';
import { useAuth } from '../../redux/authContext';
import { getAuthHeaders } from '../../utils/token';
import axios from 'axios';
import { url } from '../../utils/server';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployee } from '../../redux/action/admin/employee/get';
import { ArrowBack, ArrowForward, BackupSharp, NextPlanSharp } from '@mui/icons-material';
import UserProfileNav from '../leads/UserProfileNav';

function LeaveAll() {
    const { employeeData } = useAuth();
    const axiosConfig = getAuthHeaders();
    const [leaveList, setLeaveList] = useState([]);
    const { data } = useSelector((state) => state.getEmployee);
    const dispatch = useDispatch();
    
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(25);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(getEmployee());
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [dispatch]);

    useEffect(() => {
        fetchLeaveData();
    }, []);

    const fetchLeaveData = async () => {
        try {
            const response = await axios.get(`${url}/admin/employee/leave`, axiosConfig);
            const leave = response.data[0].leaveDetails.reverse();
            
            setLeaveList(leave);
        } catch (error) {
            console.error('Error fetching leave data:', error);
        }
    };

    const getEmployeeName = (employeeId) => {
        const employee = data.find(emp => emp._id === employeeId);
        return {
            name: employee ? `${employee.name}` : 'Unknown',
            number: employee ? `${employee.phoneNumber}` : 'Unknown'
        };
    };

    const handleAccept = async (leaveId) => {
        try {
            const data = {
                leaveStatus: 'accepted'
            };
            await axios.put(`${url}/admin/employee/leave/${leaveId}`, data, axiosConfig);
            fetchLeaveData();
        } catch (error) {
            console.error('Error accepting leave:', error);
        }
    };

    const handleReject = async (leaveId) => {
        try {
            const data = {
                leaveStatus: 'rejected'
            };
            await axios.put(`${url}/admin/employee/leave/${leaveId}`, data, axiosConfig);
            fetchLeaveData();
        } catch (error) {
            console.error('Error rejecting leave:', error);
        }
    };

    const getStatusBackgroundColor = (status) => {
        switch (status) {
            case 'pending':
                return '#ffc107'; // yellow
            case 'accepted':
                return '#28a745'; // green
            case 'rejected':
                return '#dc3545'; // red
            default:
                return 'transparent';
        }
    };

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = leaveList.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calculate total pages
    const totalPages = Math.ceil(leaveList.length / itemsPerPage);

    return (
        <Box   sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}>
            <Grid sx={{ width: "100%", padding: "50px" }}>
                <UserProfileNav />
              </Grid>
        <Box sx={{
            display:'flex',
            justifyContent:'center',
            width:'100%'
        }}>
            <Box mt={4} width={'90%'}>
                <Typography variant="h5" color={'white'}>Leave History</Typography>
                <TableContainer component={Paper}>
                    <Table aria-label="leave table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Employee</TableCell>
                                <TableCell>Employee Num.</TableCell>
                                <TableCell>Leave Date</TableCell>
                                <TableCell>Leave Type</TableCell>
                                <TableCell>Leave Reason</TableCell>
                                <TableCell>Apply Date</TableCell>
                                <TableCell>Leave Status</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentItems.map((leave) => (
                                <TableRow key={leave?._id}>
                                    <TableCell>{getEmployeeName(leave?.employee).name}</TableCell>
                                    <TableCell>{getEmployeeName(leave?.employee).number}</TableCell>
                                    <TableCell>{new Date(leave?.leaveDate).toLocaleDateString()}</TableCell>
                                    <TableCell>{leave?.leaveType}</TableCell>
                                    <TableCell>{leave?.leaveReason}</TableCell>
                                    <TableCell>{new Date(leave?.applyDate).toLocaleDateString()}</TableCell>
                                    <TableCell>
                                        <Typography sx={{ backgroundColor: getStatusBackgroundColor(leave?.leaveStatus), padding: '3px 10px', borderRadius: '10px', color: 'white' }}>{leave?.leaveStatus}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title='accept'>
                                            <IconButton onClick={() => handleAccept(leave?._id)}>
                                                <MdCheckCircleOutline color='green' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='reject'>
                                            <IconButton onClick={() => handleReject(leave?._id)}>
                                                <MdHighlightOff color='red' />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* Pagination buttons */}
                <Box mt={2} display="flex" justifyContent="center">
                    
                    <IconButton onClick={() => paginate(currentPage - 1)}>
                            <MdArrowBack color='white' />
                    </IconButton>
                    <Box mx={2}>
                        <Typography variant="body1" color={'white'}>
                            Page {currentPage} of {totalPages}
                        </Typography>
                    </Box>
                   
                    <IconButton onClick={() => paginate(currentPage + 1)}>
                            <MdArrowForward color='white'/>
                    </IconButton>
                </Box>
            </Box>
        </Box>
        </Box>
    );
}

export default LeaveAll;
