import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

const chartSettings = {
  yAxis: [
    {
      label: 'Leads',
    },
  ],
  width: 400,
  height: 250,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-20px, 0)',
    },
  },
};

const BarsDataset = ({ data }) => {
  // Validate and process data
  if (!Array.isArray(data) || data.length === 0) {
    console.error('Invalid data provided:', data);
    return <div style={{marginTop:'100px'}}>No data available</div>;
  }

  // Function to determine color based on 'Leads' value
  const getColor = (data) => {
    
  return  data.map((item)=>{
        console.log(item.Leads)
        return item.Leads <= 200 ? '#4caf50' : '#f44336';
    }
    )
   
  };

  // Series configuration for the BarChart component
  const seriesConfig = {
    dataKey: 'Leads', // Assuming your data object has a property 'Leads'
    label: '', // Placeholder for month label (adjust as needed)
    valueFormatter: (value) => value, // Extract 'Leads' from 'value'
    color:  getColor(data), // Dynamically determine color based on 'Leads' value
  };

  return (
    <BarChart
      width={chartSettings.width}
      height={chartSettings.height}
      dataset={data} // Use 'dataset' prop to pass data to BarChart
      xAxis={[{ scaleType: 'band', dataKey: 'month' }]} // Adjust 'dataKey' based on your data structure
      series={[seriesConfig]}
      {...chartSettings}
      slots={{
        axisLine: 'none',
        axisLabel: 'none',
      }}
    />
  );
};

export default BarsDataset;
