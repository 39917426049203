import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, FormControl, Grid, Snackbar, TextField, Typography, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import theme from "../theme/theme";
import { url } from "../utils/server";
import { useAuth } from "../redux/authContext";

function ResetPass() {
  const { login } = useAuth();
  const navigate = useNavigate();

  const loginSchema = z.object({
    password: z.string().min(1),
    rePassword: z.string().min(1),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(loginSchema),
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const {id} = useParams()
  const token = id
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const onSubmit = async (data) => {
    
    setLoading(true);
    try {
      const { password, rePassword } = data;
      
      // Check if passwords match
      if (password !== rePassword) {
        setSnackbarSeverity("error");
        setSnackbarMessage("Passwords do not match.");
        setSnackbarOpen(true);
        return;
      }

      const response = await axios.post(`${url}/admin/employee/reset-password/${token}`, data);

      if (response.data.success) {
        setSnackbarSeverity(response.data.severity || "success");
        setSnackbarMessage(response.data.message);
        setSnackbarOpen(true);

        setTimeout(() => {
          navigate("/login");
        }, 1500);
      } else {
        setSnackbarSeverity(response.data.severity || "error");
        setSnackbarMessage(response.data.message);
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("An error occurred. Please try again later.");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          background: "white",
          width: "120%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          marginLeft: "-50px",
        }}
      >
        <Grid container direction="column" alignItems="center" gap={3}>
          <Grid item>
            <img src="" alt="Logo" />
          </Grid>
          <Grid item>
            <Typography variant="h5">Welcome! 👋🏻</Typography>
            <Typography>Please Enter Password to Change</Typography>
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Grid container direction="column" gap={2} sx={{ width: "300px" }}>
                  <Grid item>
                    <TextField
                      id="password"
                      name="password"
                      label="Password *"
                      placeholder="Enter password"
                      type="password"
                      fullWidth
                      {...register("password", {
                        required: "Password is required",
                      })}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="re-password"
                      name="rePassword"
                      label="Re-Enter Password"
                      placeholder="Re-Enter Password"
                      type="password"
                      fullWidth
                      {...register("rePassword", {
                        required: "Re-Enter Password is required",
                      })}
                      error={!!errors.rePassword}
                      helperText={errors.rePassword?.message}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      disabled={loading}
                      sx={{ background: theme.palette.button.secondary, color: "white", width: "100%" }}
                    >
                      {loading ? <CircularProgress size={24} color="inherit" /> : 'Reset Password'}
                    </Button>
                    <Link to={'/login'} style={{ marginTop: '10px' }}>Login</Link>
                  </Grid>
                </Grid>
              </FormControl>
            </form>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default ResetPass;
