import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    text: {
      primary: '#F0F8FF', 
      secondary: '#000000' ,
     
    },
    payment:{
      primary:'#00A550',
      secondary:'#009E60'
    },
    size:{
      primary:'15px',
      secondary:'14px'
    },

    button: {
      primary: '#FF8F00', 
      secondary: '#00A550' 
    },
    background: {
      primary: '#F0F8FF', 
      secondary: '#00A550' ,
      default:'#636065'
    },
    input: {
      primary: '#FFFFF0', 
      secondary: '#fffff'
    },
    links: {
      primary: '#fffff', 
      secondary: '#00BFFF'
    },
    graph: {
      primary: '#29AB87', 
      secondary: '#F94D00' 
    },
    
   
  }
});

export default theme;
