import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Drawer, Grid, IconButton, Slide, Snackbar, Typography } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import theme from "../../theme/theme";
import Search from "../../subcomponent/Search";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { addLeadAction } from "../../redux/action/admin/leads/addLead";
import { getLeadAction } from "../../redux/action/admin/leads/getLead";
import { useAuth } from "../../redux/authContext";
import { getEmployee } from "../../redux/action/admin/employee/get";
import DataLists from "../../subcomponent/DataTable";
import LeadsAdd from "./SaveData/Leads";
import { leadSchema } from "./schemaInput/leadSchema";
import MuiAlert from '@mui/material/Alert';

import { Autocomplete, TextField } from "@mui/material";
import useGlass from "../../theme/glassEffect";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "phoneNumber",
    numeric: true,
    disablePadding: false,
    label: "Phone Number",
  },
  {
    id: "owner",
    numeric: true,
    disablePadding: false,
    label: "Owner",
  },
  {
    id: "company",
    numeric: true,
    disablePadding: false,
    label: "Company",
  },
  {
    id: "workAddress",
    numeric: true,
    disablePadding: false,
    label: "Work Address",
  },
  {
    id: "leadType",
    numeric: true,
    disablePadding: false,
    label: "Lead Type",
  },
];

function LeadTable() {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);


  const dispatch = useDispatch();
  const { employeeData } = useAuth();
  const { error, loading, leadsData } = useSelector((state) => state.getLeadData);
  const { data } = useSelector((state) => state.getEmployee);
  const { selectedId } = useSelector((state) => state.editLeadData);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

const [snackbarMessage, setSnackbarMessage] = useState('');
const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // or 'error', 'warning', 'info'
const handleSnackbar = (message, severity) => {
  setSnackbarMessage(message);
  setSnackbarSeverity(severity);
  setSnackbarOpen(true);
};

  const [filteredRows, setFilteredRows] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getEmployee());
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [dispatch]);
  const getEmployeeName = (employeeId) => {
    const employee = data.find((emp) => emp._id === employeeId[employeeId.length - 1]);
    return {
      name: employee ? `${employee.name}` : 'Unknown',
      number: employee ? `${employee.phoneNumber}` : 'Unknown'
    };
  };

  function createShell(id, name, email, phone, owner, company, workAddress, leadType) {
    return { id, name, email, phone, owner, company, workAddress, leadType };
  }

  const rows = leadsData.map((docs) => {
    console.log(docs)
    let leadDetails = docs.leads[0];
    if (!leadDetails) return null;
    const data = leadDetails[0];
    const name = data?.name || '';
    const email = data?.email || '';
    const phone = data?.phoneNumber || '';
    const owner = getEmployeeName(data?.owner).name || '';
    const company = data?.companyName || '';
    const workAddress = data?.companyAddress || '';
    const leadType = data?.projectName || '';

    return createShell(docs._id, name, email, phone, owner, company, workAddress, leadType);
  }).filter(Boolean); // Filter out null or undefined rows


  useEffect(() => {
    const fetchData = async () => {
      const employeeIds = [employeeData._id];
      employeeData.junior?.forEach((doc) => {
        employeeIds.push(doc);
      });
      try {
        await dispatch(getLeadAction(employeeIds));
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    
  }, [employeeData]);


  
  const handleSave = async (shouldClose, data) => {
    try {
      const employeeIds = [employeeData._id];
      employeeData.junior?.forEach((doc) => {
        employeeIds.push(doc);
      });
      const saveData = await dispatch(addLeadAction(data, employeeData._id));
      console.log(saveData)
      await dispatch(getLeadAction(employeeIds));
      
      if (shouldClose) {
        setOpen(false);
      }
  
      handleSnackbar('Saved successfully', 'success'); // Success message
  
    } catch (error) {
      console.error("Error while saving:", error);
      handleSnackbar('Error while saving', 'error'); // Error message
    }
  };
  

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleSearch = () => {
    setSearch(!search);
  };

  const handleClose = () => {
    setSearch(false);
  };

  const handleSearchData = (value) => {
    console.log(value)
    if (value.trim() === "") {
      setFilteredRows(rows);
    } else {
      const filtered = rows.filter(row => {
        // Modify this condition according to your search requirements
        return row.name.toLowerCase().includes(value.toLowerCase());
      });
      console.log(filtered, 'dfgs')
      setFilteredRows(filtered);
    }
  };

  return (
    <>
    <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
  <MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
    {snackbarMessage}
  </MuiAlert>
</Snackbar>

      {loading && <CircularProgress />}
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        maxWidth: '100%',
        minWidth: '100%',
      }}>
        {search && <Drawer
          anchor="top"
          open={search}
          sx={{
            background: 'transparent',
            maxWidth: '200px'
          }}
          onClose={handleClose}
          elevation={4}
          TransitionComponent={Slide}
          transitionDuration={100}
        >
          <IconButton
            onClick={handleClose}
            style={{ position: 'absolute', right: '10px', top: '10px' }}
          >
            <MdClose />
          </IconButton>
          <Search onSearch={handleSearchData} />
        </Drawer>}
        <Grid flex={1}>
          <Grid sx={{
            width: '100%',
            height: '50px',
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
            gap: 1,
            padding: 2,
            marginTop: '3%'
          }}>
            <Grid>
              <Button onClick={handleSearch} sx={{
                background: theme.palette.button.secondary,
                textAlign: 'center',
                color: theme.palette.text.primary,
                minWidth: '50%',
              }}>
                <FaSearch />
                Search
              </Button>
            </Grid>
            <Grid minWidth={"28%"}>
              <Button onClick={toggleDrawer(true)} sx={{
                background: theme.palette.button.secondary,
                textAlign: 'center',
                color: theme.palette.text.primary,
                minWidth: '50%',
                marginLeft: 13
              }}>Add Leads</Button>
              <Drawer
                open={open}
                onClose={toggleDrawer(false)}
                anchor="right"
                sx={{
                  "& .css-1160xiw-MuiPaper-root-MuiDrawer-paper": {
                    minWidth: "30%",
                  },
                  backgroundColor: useGlass.glass.background,
                  boxShadow: useGlass.glass.boxShadow,
                  backdropFilter: useGlass.glass.backdropFilter,
                  WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
                  borderRadius: useGlass.glass.borderRadius,
                  border: useGlass.glass.border,
                }}
              >
                <Grid>
                  <LeadsAdd schema={leadSchema} saved={handleSave} />
                </Grid>
              </Drawer>
            </Grid>
          </Grid>
          <Grid
            sx={{
              maxWidth: '100%',
              width: '100%',
              marginTop: '100',
            }}
          >
            <DataLists rows={filteredRows.length > 0 ? filteredRows : rows} headCells={headCells} reference={'Leads'} data={leadsData} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default LeadTable;
