import { MenuItem, Select, Snackbar } from '@mui/material';
import { Box, Grid, IconButton, Typography, Dialog, DialogActions, DialogContent, TextField, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MdAddBox, MdWorkHistory } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { getAuthHeaders } from '../../utils/token';
import axios from 'axios';
import { url } from '../../utils/server';
import { useAuth } from '../../redux/authContext';
import { ThreeDots } from 'react-loader-spinner'; // Import the Loader

function ProjectWorkHistory({ data }) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [module, setModule] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [employess, setEmployees] = useState()
  const { id } = useParams();
  const axiosConfig = getAuthHeaders();
  const [work, setWork] = useState([]);
  const { employeeData } = useAuth();

  const assign = async () => {
    const response = await axios.get(`${url}/admin/lead/getmodule/${id}`, axiosConfig);
    setModule(response.data.lead.assignTeam);
  };

  const getProjectWork = async () => {
    try {
      const response = await axios.get(`${url}/admin/project-work/get/${id}`, axiosConfig);
      setWork(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchDepartments = async () => {

      try {
        const response = await axios.get(`${url}/admin/employee/departments`, axiosConfig);

        const { employee } = response.data;
        console.log(employee, 'got it')
        setEmployees(employee);

      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };


    fetchDepartments();
    assign();
    getProjectWork();
  }, [employeeData]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const dataToSave = {
        projectDes: inputValue,
        modelName: module,
        modelDoneBy: employeeData._id,
      };
      console.log(dataToSave);
      await axios.post(`${url}/admin/project-work/add/${id}`, dataToSave, axiosConfig);
      setSnackbarMessage('Project work added successfully!');
      await getProjectWork();
    } catch (err) {
      console.error(err);
      setSnackbarMessage('Error adding project work.');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
      handleClose();
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('default', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const getEmployeeName = (modelDoneById) => {
    const employee = employess?.find(emp => emp._id === modelDoneById);
    return employee ? employee.name : 'Unknown Employee';
  };

  return (
    <>
      <Box sx={{
        background: 'white',
        flexWrap:'wrap',
        width: '100%',
        minHeight: '610px',
        maxHeight: '610px',
        borderRadius: '3px',
        marginTop: '10px',
        overflow: 'scroll'
      }}>
        <Grid sx={{
          height: '50px',
          borderBottom: '1px solid grey',
          padding: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography marginLeft={'20px'}>
            Project Work
          </Typography>
          <IconButton onClick={handleOpen}>
            <MdAddBox />
          </IconButton>
        </Grid>
        <Grid height={'80%'}>

          {work && work.projectWork && Array.isArray(work.projectWork) ? (
            work.projectWork.map((data) => (
              <Typography key={data._id} sx={{
                padding: '50px'
              }}>
                <MdWorkHistory />  {`On ${formatDate(data.createdAt)}, `}
                <strong>{getEmployeeName(data.modelDoneBy)}</strong>
                {` worked on the model `}
                <strong>{data.modelName}</strong>
                {` and completed the task: "${data.projectDes}".`}
              </Typography>

            ))
          ) : (
            <Typography>No project work available.</Typography>
          )}
        </Grid>
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          {loading && (
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
              <ThreeDots type="ThreeDots" color="green" height={80} width={80} />
            </Box>
          )}
          <Select
            value={module}
            onChange={(event) => setModule(event.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="">Select Value</MenuItem>
            {data && data.map(module => (
              <MenuItem key={module._id} value={module.moduleName}>
                {module.moduleName}
              </MenuItem>
            ))}
          </Select>
          <TextField
            label="Project Details"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            sx={{ marginTop: '16px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">Submit</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </>
  );
}

export default ProjectWorkHistory;
