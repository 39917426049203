import * as React from 'react';
import { LineChart, lineElementClasses } from '@mui/x-charts/LineChart';
import axios from 'axios';
import { getAuthHeaders } from '../../../utils/token';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { url } from '../../../utils/server';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import useGlass from '../../../theme/glassEffect';

const START_HOUR = 8; // Start hour (8 AM)
const END_HOUR = 19; // End hour (7 PM)

export default function DateTimePickerDemo() {
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const [hourlyData, setHourlyData] = React.useState(Array(24).fill(0)); // Initialize with 24 zeros for each hour

  React.useEffect(() => {
    fetchData();
  }, [selectedDate]);

  const fetchData = async () => {
    const axiosConfig = getAuthHeaders();

    try {
      const formattedDate = selectedDate.format('YYYY-MM-DD');
      const response = await axios.post(`${url}/employee/get-punch-data`, { date: formattedDate }, axiosConfig);
      
      const { data } = response;

      if (data && data.length === 24) { // Assuming data is an array of length 24 (0-23)
        setHourlyData(data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error fetching data
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div style={{
        background:'white',
        height:'400px',
        width:'100%',
        borderRadius:'20px',
        backgroundColor: 'rgba( 255, 255, 255, 0.55 )',
              boxShadow: useGlass.glass.boxShadow,
              backdropFilter: useGlass.glass.backdropFilter,
              WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
              borderRadius: useGlass.glass.borderRadius,
              border: useGlass.glass.border,
    }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={selectedDate}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} />}
          views={['year', 'month', 'day']}
        />
      </LocalizationProvider>

      <LineChart
        width={650}
        height={350}
        series={[{ data: hourlyData, label: 'Working Time (hours)', area: true, showMark: false }]}
        xAxis={[{ scaleType: 'point', data: Array.from({ length: 24 }, (_, i) => i) }]}
        sx={{
            color:'black'
        }}
      />
    </div>
  );
}
