import React from "react";
import { Navigate, Route,  useNavigate } from "react-router-dom";
import { useAuth } from "./authContext";


const PrivateRoute = ({ children  }) => {
  const { employeeData } = useAuth();
  
   
  return employeeData ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
