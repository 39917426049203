import { Box, Grid, IconButton, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField, RadioGroup, FormControlLabel, Button, Radio, Snackbar, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import useGlass from '../../theme/glassEffect';
import { MdAddComment, MdBookmarkAdded } from 'react-icons/md';
import axios from 'axios';
import { getAuthHeaders } from '../../utils/token';
import { useParams } from 'react-router-dom';
import { url } from '../../utils/server';
import { ThreeCircles, ThreeDots } from 'react-loader-spinner';
import { BiCheck, BiCheckCircle, BiCheckDouble, BiTrash } from 'react-icons/bi';
import { BsCheck2 } from 'react-icons/bs';

function ModuleWorker({ data, saved }) {
  const leadDetail = data;

  // State for modal, form data, loading, and snackbar
  const [open, setOpen] = useState(false);
  const [remark, setRemark] = useState('');
  const [addedToModule, setAddedToModule] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const axiosConfig = getAuthHeaders();
  const [confirmOpen, setConfirmOpen] = useState(false)
  const { id } = useParams();
  const [changeId, setChangeId] = useState()
  // Handle modal open/close


  const handleClose = () => {
    setOpen(false);
    setRemark('');
    setAddedToModule('');
  };
  const handleConfirmClose = ()=>{
    setOpen(false);
    setRemark('');
    setAddedToModule('');
  }

  const handleConfirm = async (e) => {
    e.preventDefault()
    setAddedToModule(true)
    setLoading(true)
    const data = {
      added:true,
      changeId
    }
    console.log(data)
    try {
      const response = await axios.post(`${url}/admin/lead/update-remark/confirm/${id}`, data, axiosConfig);
      // Show snackbar message on successful response
      setSnackbarMessage(response.data.message);
      setSnackbarOpen(true);
      saved(true)
      setConfirmOpen(false)
    } catch (err) {
      console.error(err);
      // Show error message on catch
      setSnackbarMessage('Failed to update remark');
      setSnackbarOpen(true);
    } finally {
      setAddedToModule(false)
      setLoading(false);
      handleClose();
    }
  }
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      remark,
      addedToModule
    };
    try {
      const response = await axios.post(`${url}/admin/lead/update-remark/${id}`, data, axiosConfig);
      // Show snackbar message on successful response
      setSnackbarMessage(response.data.message);
      setSnackbarOpen(true);
      saved(true)
    } catch (err) {
      console.error(err);
      // Show error message on catch
      setSnackbarMessage('Failed to update remark');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const handleSnackbarClose = () => {

    setSnackbarOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handelconfirmAdded = (_id) => {
    setConfirmOpen(true)
    setChangeId(_id)
  }

  return (
    <Box width={'100%'}>
      <Grid container>
        <Box
          sx={{
            background: 'white',
            width: '100%',
            minHeight: '370px',
            marginTop: '10px',
            overflow: 'scroll',
            backgroundColor: 'rgba( 255, 255, 255, 0.95 )',
            boxShadow: useGlass.glass.boxShadow,
            backdropFilter: useGlass.glass.backdropFilter,
            WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
            borderRadius: useGlass.glass.borderRadius,
            border: useGlass.glass.border,
            position: 'relative',
          }}
        >
          <Grid
            container
            alignItems="center"
            sx={{
              height: '50px',
              borderBottom: '1px solid grey',
              backgroundColor: '#ffff',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="h6" sx={{ padding: '10px' }}>
              Client Updates
            </Typography>
            <Typography variant="h6" sx={{ padding: '10px' }}>
              <IconButton onClick={handleClickOpen}>
                <MdAddComment />
              </IconButton>
            </Typography>
          </Grid>
          <Grid
            container
            flexDirection={'column'}
            gap={"1px"}
            minHeight="calc(100% - 50px)"
            maxHeight="calc(100% - 50px)"
            overflow={'scroll'}
            sx={{ padding: '20px', overflowY: 'auto' }}
          >
            {leadDetail && leadDetail.map((data) => (
              data.remark?.map((item) => (
                <Grid key={item._id} sx={{
                  height: '40px',
                  background: 'white',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',

                }}> {/* Assuming each item has a unique id */}
                  <Typography sx={{ padding: '20px' }}>
                    {item.requirement}
                  </Typography>
                  <Grid>
                    {item?.processed === true ? (<Tooltip title="already added to module">
                      <IconButton>
                        <MdBookmarkAdded color='green' />
                      </IconButton>
                    </Tooltip>) : (<Tooltip title="confirm added to module">
                      <IconButton onClick={() => handelconfirmAdded(item._id)}>
                        <BiCheckDouble color='green' />
                      </IconButton>
                    </Tooltip>)}

                  </Grid>


                </Grid>
              ))
            ))}
          </Grid>
        </Box>
      </Grid>

      {/* Modal for adding comment */}
      <Dialog
        open={confirmOpen}
        onClose={handleClose}
        PaperProps={{
          style: { width: '500px', maxWidth: '90%' },
        }}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent sx={{ width: '90%' }}>
          <Typography>
            Are you sure you added client changes to module
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose}>Cancel</Button>
          <Button onClick={handleConfirm} color="primary" disabled={loading}>
            {'Submit'}
          </Button>
        </DialogActions>
        {loading && (
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000
          }}>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="green"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              visible={true}
            />
          </Box>
        )}
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { width: '500px', maxWidth: '90%' },
        }}
      >
        <DialogTitle>Add Comment</DialogTitle>
        <DialogContent sx={{ width: '90%' }}>
          <TextField
            autoFocus
            margin="dense"
            label="Client Change Add"
            type="text"
            fullWidth
            variant="outlined"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
          <RadioGroup
            value={addedToModule}
            onChange={(e) => setAddedToModule(e.target.value)}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Added to Module" />
            <FormControlLabel value="no" control={<Radio />} label="Not Added to Module" />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary" disabled={loading}>
            {'Submit'}
          </Button>
        </DialogActions>
        {loading && (
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000
          }}>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="green"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              visible={true}
            />
          </Box>
        )}
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Box>
  );
}

export default ModuleWorker;
