import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import useGlass from '../../theme/glassEffect';
import dayjs from 'dayjs';
import { url } from '../../utils/server';
import { useAuth } from '../../redux/authContext';
import { getAuthHeaders } from '../../utils/token';
import { useParams } from 'react-router-dom';

function Leave() {
    const { employeeData } = useAuth();
    const axiosConfig = getAuthHeaders();
    const {id} = useParams()
    const [leaveDetails, setLeaveDetails] = useState({
        employee: employeeData._id,
        leaveDate: dayjs(),
        leaveType: '',
        leaveReason: ''
    });
    const [leaveList, setLeaveList] = useState([]);

    useEffect(() => {
        fetchLeaveData();
    }, []);

    const fetchLeaveData = async () => {
        try {
            const response = await axios.get(`${url}/admin/employee/leave/${employeeData._id}`, axiosConfig);
            const leave = response.data[0]?.leaveDetails?.reverse()
            const filterData = leave.filter((item)=>item.employee === employeeData._id)
            setLeaveList(filterData);
        } catch (error) {
            console.error('Error fetching leave data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLeaveDetails({ ...leaveDetails, [name]: value });
    };

    const handleDateChange = (date) => {
        setLeaveDetails({ ...leaveDetails, leaveDate: date });
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.post(`${url}/admin/employee/leave`, leaveDetails, axiosConfig);
            console.log('Leave submitted:', response.data);
            fetchLeaveData();
        } catch (error) {
            console.error('Error submitting leave:', error);
        }
    };

    const getStatusBackgroundColor = (status) => {
        switch (status) {
            case 'pending':
                return '#ffc107'; // yellow
            case 'accepted':
                return '#28a745'; // green
            case 'rejected':
                return '#dc3545'; // red
            default:
                return 'transparent';
        }
    };

    return (
        <>
        {(id===employeeData._id) ? (
        <Box sx={{
            backgroundColor: useGlass.glass.background,
            boxShadow: useGlass.glass.boxShadow,
            backdropFilter: useGlass.glass.backdropFilter,
            WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
            borderRadius: useGlass.glass.borderRadius,
            border: useGlass.glass.border,
            width: '90%',
            display: 'flex',
            marginLeft: '30px',
            gap: '20px'
        }}>
            <Grid width={'30%'} padding={'40px'} sx={{
                margin: '35px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px'
            }}>
                <Grid item>
                    <Typography variant="h5" color={'white'}>Apply for Leave</Typography>
                </Grid>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        value={leaveDetails.leaveDate}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                        views={['year', 'month', 'day']}
                        sx={{
                            '& .MuiInputBase-input': {
                                color: 'white',
                            },
                            '& .MuiInputLabel-root': {
                                color: 'white',
                            },
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                        }}
                    />
                </LocalizationProvider>

                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Leave Type"

                        name="leaveType"
                        value={leaveDetails.leaveType}
                        onChange={handleInputChange}
                        sx={{
                            '& .MuiInputBase-input': {
                                color: 'white',
                            },
                            '& .MuiInputLabel-root': {
                                color: 'white',
                            },
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Leave Reason"
                        name="leaveReason"
                        value={leaveDetails.leaveReason}
                        onChange={handleInputChange}
                        sx={{
                            '& .MuiInputBase-input': {
                                color: 'white',
                            },
                            '& .MuiInputLabel-root': {
                                color: 'white',
                            },
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={handleSubmit} style={{background:'#00A550'}}>
                        Submit Leave
                    </Button>
                </Grid>
            </Grid>

            <Box mt={4} mr={2}>
                <Typography variant="h5" color={'white'} mb={2}>Leave History</Typography>
                <TableContainer component={Paper}>
                    <Table aria-label="leave table">
                        <TableHead>
                            <TableRow >
                                
                                <TableCell>Leave Date</TableCell>
                                <TableCell>Leave Type</TableCell>
                                <TableCell>Leave Reason</TableCell>
                                <TableCell>Apply Date</TableCell>
                                <TableCell>Leave Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {leaveList?.map((leave) => (
                                <TableRow key={leave?._id}>
                                    <TableCell>{new Date(leave?.leaveDate).toLocaleDateString()}</TableCell>
                                    <TableCell>{leave?.leaveType}</TableCell>
                                    <TableCell>{leave?.leaveReason}</TableCell>
                                    <TableCell>{new Date(leave?.applyDate).toLocaleDateString()}</TableCell>
                                    <TableCell > <Typography sx={{ backgroundColor: getStatusBackgroundColor(leave?.leaveStatus) , padding:'3px 10px', borderRadius:'10px', color:'white'}}>{leave?.leaveStatus}</Typography></TableCell>
                                </TableRow>
                               
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>) : ''}
        </>
    );
}

export default Leave;
