import {
    SELECT_DATA_REQUEST,
    SELECT_DATA_SUCCESS,
    SELECT_DATA_FAIL,
    UPDATE_DATA_REQUEST,
    UPDATE_DATA_SUCCESS,
    UPDATE_DATA_FAIL,
    CATCH_ERROR
 } from '../../../constents/admin/lead.js'
   
   const initialState = {
       loading : false,
       error:null,
       selectedData:null
       
   };
   const leadinitialState = {
    loading : false,
    error:null,
    
    
};
   
   export const editAbleData = (state = initialState, action)=>{
        switch(action.type){
           case SELECT_DATA_REQUEST:
               return{
                   ...state,
                   loding:true
               };
           case SELECT_DATA_SUCCESS:
             console.log("ohh",action.payload)
               return{
                   ...state,
                   loding:false,
                   selectedData:action.payload
                   
               };
           case SELECT_DATA_FAIL:
               return{
                   ...state,
                   loding:false,
                   error: action.payload
               }
           case CATCH_ERROR:
               return{
                   ...state,
                   error:null
               }
             default : return state  
        }
   };

   export const updateLeadData = (state = leadinitialState, action)=>{
    switch(action.type){
       case UPDATE_DATA_REQUEST:
           return{
               ...state,
               loding:true
           };
       case UPDATE_DATA_SUCCESS:
         console.log(action.payload)
           return{
               ...state,
               loding:false,
               
               
           };
       case UPDATE_DATA_FAIL:
           return{
               ...state,
               loding:false,
               error: action.payload
           }
       case CATCH_ERROR:
           return{
               ...state,
               error:null
           }
         default : return state  
    }
};