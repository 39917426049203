import React, { useState } from 'react';
import {
    Box,
    FormControl,
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    Snackbar,
    Alert,
} from '@mui/material';
import { useAuth } from '../../redux/authContext';
import axios from 'axios';
import { url } from '../../utils/server';
import { useNavigate, useParams } from 'react-router-dom';
import { getAuthHeaders } from '../../utils/token';
import {  ThreeDots } from 'react-loader-spinner'; // Import the spinner component

const PaymentForm = ({saved}) => {
    const [totalPayment, setTotalPayment] = useState();
    const [receivedPayment, setReceivedPayment] = useState();
    const [date, setDate] = useState('');
    const [isDone, setIsDone] = useState(false);
    const [isReminder, setIsReminder] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(false); // Loading state
   const navigate = useNavigate()
    const { employeeData } = useAuth();
    const { id } = useParams();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!totalPayment || !receivedPayment || !date) {
            alert('Please fill in all fields');
            return;
        }

        const data = {
            totalPayment: Number(totalPayment),
            receivedPayment: Number(receivedPayment),
            date,
            isDone,
            isReminder,
            createdBy: employeeData._id,
        };

        try {
            setLoading(true); // Set loading to true before API call
            const axiosConfig = getAuthHeaders();
            await axios.post(`${url}/admin/payment-create/${id}`, data, axiosConfig);
            console.log(data)
            setSnackbarMessage('Payment created successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setTotalPayment('');
            setReceivedPayment('');
            setDate('');
            setIsDone(false);
            setIsReminder(false);
            saved(true)
            navigate(`/leads/${id}`)
            
        } catch (err) {
            console.error(err);
            setSnackbarMessage('Error creating payment.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false); // Reset loading state after API call
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box sx={{ paddingLeft: 0, height: '100%', marginTop: '-70px' }}>
            <FormControl sx={{ padding: '20px', width: '90%', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <TextField
                    sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                            color: 'white',
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white',
                        },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        
                      }}
                    type='date'
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                />
                <TextField
                    sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                            color: 'white',
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white',
                        },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                      }}
                    type='number'
                    placeholder='Total Payment'
                    value={totalPayment}
                    onChange={(e) => setTotalPayment(e.target.value)}
                />
                <TextField
                    sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                            color: 'white',
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white',
                        },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                      }}
                    type='number'
                    placeholder='Received Payment'
                    value={receivedPayment}
                    onChange={(e) => setReceivedPayment(e.target.value)}
                />
                <FormControlLabel
                    control={<Checkbox checked={isReminder} onChange={(e) => setIsReminder(e.target.checked)} />}
                    label="Mark as Reminder"
                />
                <FormControlLabel
                    control={<Checkbox checked={isDone} onChange={(e) => setIsDone(e.target.checked)} />}
                    label="Add To Customer"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ mt: 2 }}
                    disabled={loading} // Disable button while loading
                >
                    {loading ? <ThreeDots height={20} width={20} color="green" /> : 'Submit'}
                </Button>
            </FormControl>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default PaymentForm;
