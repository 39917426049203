import React, { useState } from 'react';
import { Button, Container, Typography, Snackbar, CircularProgress } from '@mui/material';
import axios from 'axios';
import { url } from '../../utils/server';
import { getAuthHeaders } from '../../utils/token';

const UploadImage = () => {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Get authentication token
  const token = getAuthHeaders()?.headers?.Authorization || '';

  // Axios configuration with authentication token
  const axiosConfig = {
    headers: {
      Authorization: token, // Ensure token is properly formatted
      'Content-Type': 'multipart/form-data'
    },
    withCredentials: true // Assuming you need credentials, adjust as needed
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    // Validate file type (allow only JPG and PNG)
    if (selectedFile && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png')) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      // Handle invalid file type (show message to user, reset form, etc.)
      alert('Please select a valid JPG or PNG image.');
      setFile(null);
      setPreviewUrl('');
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      alert('Please select a file.');
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${url}/admin/employee/profile-upload`, formData, {
        ...axiosConfig,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(progress);
        }
      });

      console.log('File uploaded successfully:', response.data);
      setSnackbarMessage('File uploaded successfully.');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error uploading file:', error);
      setSnackbarMessage('Error uploading file.');
      setSnackbarOpen(true);
    } finally {
      setUploading(false);
      setUploadProgress(0);
      setFile(null);
      setPreviewUrl('');
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" align="center" gutterBottom>
        Upload a File
      </Typography>
      <form onSubmit={handleSubmit}>
        <input
          accept="image/jpeg,image/png" // Accepts only JPG and PNG files
          style={{ display: 'none' }}
          id="file-upload"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="file-upload">
          <Button variant="contained" component="span">
            Choose File
          </Button>
        </label>
        {file && (
          <div style={{ marginTop: '1rem', textAlign: 'center' }}>
            <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
            <Typography variant="body1" component="p" gutterBottom>
              Selected File: {file.name}
            </Typography>
          </div>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!file || uploading}
          style={{ marginTop: '8rem' }}
        >
          Upload
        </Button>
        {uploading && (
          <div style={{ marginTop: '1rem', textAlign: 'center' }}>
            <CircularProgress variant="determinate" value={uploadProgress} />
          </div>
        )}
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      />
    </Container>
  );
};

export default UploadImage;
