import React from 'react'
import LeadTable from '../../../components/navbar/Leads'
import UserProfileNav from '../../../components/leads/UserProfileNav'
import { Box, Grid } from '@mui/material'



function Leads() {
  return (
    <>
    <Box  sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}>
    <Grid sx={{ width: "100%", padding: "50px" }}>
          <UserProfileNav />
        </Grid>
      <Grid width={'100%'}>
      <LeadTable/>
      </Grid>
    </Box>
    
      
      
    </>
  )
}

export default Leads