import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

export default function MyLineChart({ data }) {
  // Extract Leads and Conversions for chart
  const leadsData = data.map(item => item.Leads); // X-axis data
  const conversionData = data.map(item => item.Conversion); // Y-axis data

  console.log('Leads Data:', leadsData, 'Conversion Data:', conversionData);

  return (
    <LineChart
      xAxis={[{ data: leadsData }]} // Leads on X-axis
      yAxis={[{ data: conversionData }]} // Conversions on Y-axis
      series={[{ data: conversionData, name: 'Conversions' }]} // Show conversions in series
      width={350}
      height={250}
    />
  );
}
