import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import useGlass from "../../theme/glassEffect";
import { ImCheckboxChecked } from "react-icons/im";
import theme from "../../theme/theme";
import { IconContext } from "react-icons";


function TotalleadaCard( {data, reference,amount} ) {
  return (
    <>
      <Box
        sx={{
          minWidth: "300px",
          maxWidth: "300px",
          maxHeight: "250px",
          minHeight: "250px",
          backgroundColor: useGlass.glass.background,
          boxShadow: useGlass.glass.boxShadow,
          backdropFilter: useGlass.glass.backdropFilter,
          WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
          borderRadius: "20px",
          border: useGlass.glass.border,
          overflow: "hidden",
        }}
      >
        <Grid>
          <Typography
            sx={{
              background: theme.palette.background.secondary,
              padding: "7px",
              color: theme.palette.text.primary,
              paddingLeft: "20px",
            }}
          >
            {reference}
          </Typography>
        </Grid>
        <Grid
          sx={{
            color: theme.palette.text.primary,
            display: "flex",
            justifyContent: "space-between",
            margin: "10px",
            
          }}
        >
          <Grid>
            <Typography>Total</Typography>
          </Grid>
          <Grid>
            <Typography sx={{ color: theme.palette.payment.primary }}>
              {amount}
            </Typography>
          </Grid>
        </Grid>
        <Grid
  sx={{
    minWidth: "250px",
    maxWidth: "250px",
    maxHeight: "150px",
    minHeight: "150px",
    backgroundColor: useGlass.glass.background,
    boxShadow: useGlass.glass.boxShadow,
    backdropFilter: useGlass.glass.backdropFilter,
    WebkitBackdropFilter: useGlass.glass.WebkitBackdropFilter,
    borderRadius: "20px",
    border: useGlass.glass.border,
    overflow: "scroll",
    marginLeft: "25px",
    marginBottom: "10px",
  }}
>
  {data &&
    data.map((item) => (
      <Grid key={item.id} sx={{
         display:'flex',
         width:'100%',
         justifyContent:'space-around',
         marginTop:'10px'
      }} >
        <Grid sx={{display:'flex',alignItems:'center',gap:'5px'}}>
        <IconContext.Provider value={{ color: theme.palette.payment.primary, className: "global-class-name" }}>
          <ImCheckboxChecked /></IconContext.Provider>
          <Typography color={theme.palette.text.secondary}>
            {item !== '' ? item?.leadName : ''}
          </Typography>
        </Grid>
        <Grid>
          <Typography>{item.paymentAmount}</Typography>
        </Grid>
      </Grid>
    ))}
</Grid>

      </Box>
    </>
  );
}

export default TotalleadaCard;
